import * as React from "react";
import { connect } from "react-redux";
import { closeDialog } from "../actions/dialog";
import { merge } from "lodash";

/*

Generic dialog component that integrates with redux actions and adds state.

Example:

let name = "myDialog";

class MyDialog extends React.Component<any, any> {
  render() {
    <div className="dialog">
      <div className="dialog-header">

      </div>
      <div className="dialog-body">

      </div>
    </div>
  }
}

let ReduxMyDialog = reduxDialog({
  name: importDialog,
  startStyle: (dialogStyleEvent) => {
    let { nodes: { container }} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight}px`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let { nodes: { container }} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${window.innerHeight / 2 - container.offsetHeight / 2}px`,
    };
  },
})(MyDialog);

// somewhere on base layout page
<ReduxMyDialog>

// open/close
openDialog({ name });
closeDialog({ name });

*/

export let reduxDialog = (dialogProps: {
  name: string,
  dialogClassNames: string,
  hasOverlay?: boolean,
  dragHandleClass?: string,
  onDragHandle?: (e: React.MouseEvent, dialogComponent: React.Component<any, any>) => void|boolean,
  onAfterOpen?: () => void,
  onRequestClose?: () => void,
  startStyle?: (dialogStyleEvent: { dialogComponent: React.Component<any, any>, nodes: { container: HTMLElement, body: HTMLElement }}) => React.CSSProperties,
  endStyle?:   (dialogStyleEvent: { dialogComponent: React.Component<any, any>, nodes: { container: HTMLElement, body: HTMLElement }}) => React.CSSProperties,
}) => {
  let {
    name,
    dialogClassNames = "dialog",
    dragHandleClass = ".dialog-header",
    onDragHandle = (e, dialogComponent) => {
      let { draggedPos: { deltaX, deltaY }} = dialogComponent.state;
      deltaX = deltaX + e.movementX;
      deltaY = deltaY + e.movementY;
      dialogComponent.setState({
        draggedPos: {
          deltaX,
          deltaY,
        }
      });
      e.preventDefault();
      e.stopPropagation();
    },
    hasOverlay = true,
    onAfterOpen = () => {},
    onRequestClose = () => {},
    startStyle = (dialogStyleEvent) => {
      let { nodes: { container }} = dialogStyleEvent;

      return {
        left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
        top: `${0 - container.offsetHeight}px`,
        transition: `left 400ms, top 400ms`,
        transitionTimingFunction: `ease`,
      };
    },
    endStyle = (dialogStyleEvent) => {
      let { nodes: { container }} = dialogStyleEvent;

      return {
        left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
        top: `${window.innerHeight / 2 - container.offsetHeight / 2}px`,
        transition: `left 400ms, top 400ms`,
        transitionTimingFunction: `ease`,
      };

    },
  } = dialogProps;

  return ((WrappedComponent) => {
    class ReduxDialog extends React.Component<any, any> {

      state = {
        initialRenderComplete: false,
        startStyleApplied: false,
        dragging: false,
        draggedPos: { deltaX: 0, deltaY: 0 },
        wasDragged: false,
        inWindow: false,
      };

      _dialogNode = null;

      constructor(props) {
        super(props);
        this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
        this.handleOnMouseUp = this.handleOnMouseUp.bind(this);
        this.handleOnMouseMove = this.handleOnMouseMove.bind(this);
      }

      componentDidMount() {
        this.setState({ initialRenderComplete: false});

        // attach to mousedown of draghandle
        let dragHandleEl = this._dialogNode.querySelector(dragHandleClass);
        dragHandleEl.addEventListener("mousedown", this.handleOnMouseDown);
      }

      componentWillUnmount() {
        // unattach to mousedown of draghandle
        if (this._dialogNode && this._dialogNode.querySelector(dragHandleClass)) {
          let dragHandleEl = this._dialogNode.querySelector(dragHandleClass);
          dragHandleEl.removeEventListener("mousedown", this.handleOnMouseDown);
        }
      }

      componentDidUpdate(prevProps, prevState) {

        if (!this.state.initialRenderComplete) {
          this.setState({ initialRenderComplete: true });
        }

        if (!prevProps.isOpen && this.props.isOpen) {
          // opened
          this.setState({inWindow: false});
          this.setState({ startStyleApplied: true });
          onAfterOpen();
        } else if (prevProps.isOpen && !this.props.isOpen) {
          // closed
          this.setState({ draggedPos: { deltaX: 0, deltaY: 0 }, wasDragged: false});
          onRequestClose();
          // After dialog has transitioned out of window
          setTimeout(() => {
            this.setState({inWindow: true});
          }, 500);
        }

        if (this.state.dragging && !prevState.dragging) {
          document.addEventListener("mousemove", this.handleOnMouseMove);
          document.addEventListener("mouseup", this.handleOnMouseUp);
          // If dragging the first time, set wasDragged to true so overlay can disappear
          if (!this.state.wasDragged) {
            this.setState({wasDragged: true});
          }
        } else if (!this.state.dragging && prevState.dragging) {
          document.removeEventListener("mousemove", this.handleOnMouseMove);
          document.removeEventListener("mouseup", this.handleOnMouseUp);
        }
      }

      handleOnMouseDown(e: React.MouseEvent) {
        this.setState({
          dragging: true,
        });
        e.stopPropagation();
        e.preventDefault;
      }

      handleOnMouseUp(e: React.MouseEvent) {
        this.setState({
          dragging: false,
        });
        e.stopPropagation();
        e.preventDefault();
      }

      handleOnMouseMove(e: React.MouseEvent) {
        let { dragging } = this.state;
        if (dragging) {
          onDragHandle(e, this);
        }
      }

      render() {
        let { isOpen, onRequestClose } = this.props;
        let { initialRenderComplete, startStyleApplied, wasDragged } = this.state;
        let body = null;
        if (this._dialogNode !== null) {
          body = this._dialogNode.querySelector(".dialog-body");
        }

        let dialogStyle = { left: `-10000px`, top: `-10000px`, transition: `none`};
        
        if (!initialRenderComplete) {
          // do nothing
        } else if (initialRenderComplete && !startStyleApplied && isOpen) {
          dialogStyle = Object.assign({}, startStyle({ dialogComponent: this, nodes: { body, container: this._dialogNode }}), { transition: `none`});
        } else if (initialRenderComplete && startStyleApplied) {

          dialogStyle = isOpen ?
            endStyle({ dialogComponent: this, nodes: { body, container: this._dialogNode }}) :
            startStyle({ dialogComponent: this, nodes: { body, container: this._dialogNode }});

          // Resize body height if it's bigger than window height
          if (body) {
            const windowHeight = window.innerHeight;
            const offsetTop = Number(dialogStyle.top.replace(/px/g, ''));
            const header = this._dialogNode ? this._dialogNode.querySelector(".dialog-header") : null;
            const headerHeight = header ? this._dialogNode.querySelector(".dialog-header").offsetHeight : 0;
            const combinedHeight = body.offsetHeight + headerHeight + offsetTop;

            // If height is higher than window height:
            if (combinedHeight > windowHeight) {
              body.style.height = `${body.offsetHeight - (combinedHeight - windowHeight)}px`;
            //  dialogStyle.top = `${(window.innerHeight - body.style.height)/2}px`;
            dialogStyle.top = `${(window.innerHeight - (body.offsetHeight + headerHeight))/2}px`;
            }

            // If width is wider than window width:
            if (body.offsetWidth > window.innerWidth) {
              body.style.width = "100%";
              header.style.width = "100%";
              dialogStyle.left = "0px";
            }
          }


        }
        dialogStyle.left = `${parseInt(dialogStyle.left) + this.state.draggedPos.deltaX}px`;
        dialogStyle.top = `${parseInt(dialogStyle.top) + this.state.draggedPos.deltaY}px`;

        if (this.state.dragging) {
          dialogStyle.transition = `none`;
        }

        return (
          <span>
            { hasOverlay && isOpen && !wasDragged ? <div className="dialog-overlay" onClick={onRequestClose}/> : null }
            <div className={dialogClassNames} style={dialogStyle} ref={(dialogNode) => { if (dialogNode !== null) { this._dialogNode = dialogNode; }}}>
                <WrappedComponent {...this.props}/>
              </div>
          </span>
        );
      }
    }

    let mapStateToProps = (state, ownProps) => {
      return merge({}, state.dialogs[name], {
        isOpen: (state.dialogs[name] && state.dialogs[name].isOpen || false),
      });
    };

    let mapDispatchToProps = (dispatch, ownProps) => ({
      onAfterOpen: () => onAfterOpen(),
      onRequestClose: () => {
        onRequestClose();
        dispatch(closeDialog({ name }));
      }
    });

    return connect(mapStateToProps, mapDispatchToProps)(ReduxDialog);
  });
};

export default reduxDialog;
