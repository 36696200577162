import { v4 } from "uuid";
import { NormalizedLink } from "../../link.types";
import { NormalizedNode, NodeType } from "../../node.types";


export const activityDiagram = (viewNodes, viewLinks, v, viewNodeEntity) => {
    const viewName = "AD";
    const adNodesArray: [] = v[viewName][1];
    const adLinksArray: [] = v[viewName][2];

    let adNodes: { [key: string]: NormalizedNode } = {};
    let adLinks: NormalizedLink[] = [];
    let viewNodesByCompileId: { [key: string]: NormalizedNode } = {};


    if (adNodesArray && adNodesArray.length > 0) {

        // Convert Activity Diagram nodes
        adNodesArray.forEach((adNode, adindex) => {

            let adType: NodeType = "AD_START";

            switch (adNode[0]) {
                case "s": adType = "AD_START"; break;
                case "e": adType = "AD_END"; break;
                case "d": adType = "AD_DECISION"; break;
                case "a": adType = "AD_ACTION"; break;
                case "b": adType = "AD_BAR"; break;
            }

            // adNode: ["S|E|D|A|B|N", "ID", "COLUMN", "ROW", ["BAR_WIDTH","EVENT_NAME"]]
            let adNodeEntity: NormalizedNode = {
                guid: v4(),
                // Only action nodes have a name
                name: adType === "AD_ACTION" ? adNode[4] : (adType === "AD_BAR" ? adNode[5] : null),
                type: adType,
                x: 0,
                y: 0,
                column: adNode[2],
                row: adNode[3],
                collapsed: false,
                hidden: false,
                isView: false,
                isGroup: false,
                group: viewNodeEntity.guid,
                itemArray: [],
                barWidth: adType === "AD_BAR" ? adNode[4] * 120 : null,
                avoidNodes: false,
                spansRows: false
            };

            // Add to the same object - all nodes have to be at the same level
            adNodes[adNodeEntity.guid] = adNodeEntity;
            viewNodesByCompileId[adNode[1]] = adNodeEntity;

        });

    }

    // Convert this activity diagram's links
    if (adLinksArray && adLinksArray.length > 0) {
        adLinks = adLinksArray.map(sysmlLink => {
            const source = viewNodesByCompileId[sysmlLink[0]];
            const target = viewNodesByCompileId[sysmlLink[1]];

            const link: NormalizedLink = {
                guid: v4(),
                type: "AD_FOLLOWS",
                source: source.guid,
                target: target.guid,
                rowSpan: 0,
            };

            return link;
        });
    }

    let rowSpanSameCol = 1;
    let rowSpanDiffCol = 1;

    // Add avoid nodes to links that span more than 1 row
    adLinks.map(link => {
        const source = adNodes[link.source];
        const target = adNodes[link.target];
        const spansRows = (source && target) && Math.abs(source.row - target.row) > 1;
        const spansColumns = (source && target) && (source.column !== target.column) && (source.row !== target.row);

        // If nodes span multiple rows, or are in different columns & rows
        if ((spansRows || spansColumns) && source.type !== "AD_BAR" && target.type !== "AD_BAR") {
            source.avoidNodes = true;
            link.type = "FOLLOWS_AVOID_NODES";
            // If in the same column & spans rows:
            if (source.column === target.column && spansRows) {
                source.spansRows = true;
                link.rowSpan = rowSpanSameCol;
                rowSpanSameCol++;
            }
            // If not in the same column but spans rows:
            if (source.column !== target.column && spansRows) {
                link.rowSpan = rowSpanDiffCol;
                rowSpanDiffCol++;
            }
        }
        return link;
    });

    // Merge this activity diagram's nodes with all nodes
    viewNodes = { ...viewNodes, ...adNodes };
    // Merge this activity diagram's links with all links
    viewLinks = [...viewLinks, ...adLinks];

    return { viewNodes, viewLinks };

};
