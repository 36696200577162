import * as go from "gojs";

export interface ViewTemplateOptions {
  onShiftClick: {(guid: string): void};
}

export let graphNodeTemplate = (options: ViewTemplateOptions) => {
  let $ = go.GraphObject.make;
  return $(go.Node, "Auto",
    { cursor: "move"},
    new go.Binding("position", "position").makeTwoWay(),
    {
      click: (e, obj, prev) => {
        if (e.shift) {
          options.onShiftClick(obj.part.data.key);
        }
      },
    },
    $(go.Shape, "RoundedRectangle", {
      fill: "#e6e6e6",
      stroke: "black"
    }),
    $(go.TextBlock,
      {
        font: "normal 14pt sans-serif",
        overflow: go.TextBlock.OverflowClip,
        margin: 8,
        textAlign: "center",
        minSize: new go.Size(80, 20),
        maxSize: new go.Size(140, 40),
      },
      new go.Binding("text", "name"),
    ),
  );
};

