export const UI_UPDATE_SPLIT_SIZES = "UI_UPDATE_SPLIT_SIZES";
export const UI_UPDATE_CONSOLE_SPLIT_SIZE = "UI_UPDATE_CONSOLE_SPLIT_SIZE";
export const UI_TOGGLE_EXPAND_CONSOLE = "UI_TOGGLE_EXPAND_CONSOLE";
export const UI_TOGGLE_EXPAND_GLOBAL = "UI_TOGGLE_EXPAND_GLOBAL";
export const UI_UPDATE_VIEW_MODE = "UI_UPDATE_VIEW_MODE";
export const UI_TOGGLE_EXPAND_NAVIGATION = "UI_TOGGLE_EXPAND_NAVIGATION";
export const UI_UPDATE_SHOW_HIDDEN = "UI_UPDATE_SHOW_HIDDEN";
export const UI_UPDATE_SHOW_TOOLTIPS = "UI_UPDATE_SHOW_TOOLTIPS";
export const UI_TOGGLE_SPLIT_SCREEN = "UI_TOGGLE_SPLIT_SCREEN";
export const UI_UPDATE_SPLIT_SCREEN_SIZES = "UI_UPDATE_SPLIT_SCREEN_SIZES";
export const TOGGLE_FIND_AND_REPLACE = "TOGGLE_FIND_AND_REPLACE";
export const TOGGLE_WORD_WRAP = "TOGGLE_WORD_WRAP";
export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";
export const TOGGLE_GRAYSCALE_GRAPHS = "TOGGLE_GRAYSCALE_GRAPHS";
export const BEGIN_TOUR = "BEGIN_TOUR";
export const END_TOUR = "END_TOUR";
export const START_IMPORT = "START_IMPORT";
export const END_IMPORT = "END_IMPORT";