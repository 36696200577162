import * as go from "gojs";

let $ = go.GraphObject.make;

// Sets 4 resize handles - one at each corner
// so viewbox can only scale proportionately 
export let resizeAdornmentTemplate = 
  $(go.Adornment, "Spot",
    // takes size and position of adorned object
    $(go.Placeholder),
    // Top left handle
    $(go.Shape, "Circle",
      {
        alignment: go.Spot.TopLeft, 
        cursor: "col-resize",
        desiredSize: new go.Size(9, 9), 
        fill: "lightblue", 
        stroke: "dodgerblue" 
      }
    ),
    // Bottom left handle
    $(go.Shape, "Circle",
      { 
        alignment: go.Spot.BottomLeft, 
        cursor: "col-resize",
        desiredSize: new go.Size(9, 9), 
        fill: "lightblue", 
        stroke: "dodgerblue" 
      }
    ),
    // Top right handle
    $(go.Shape, "Circle",
      { 
        alignment: go.Spot.TopRight, 
        cursor: "col-resize",
        desiredSize: new go.Size(9, 9), 
        fill: "lightblue", 
        stroke: "dodgerblue" 
      }
    ),
    // Bottom right handle
    $(go.Shape, "Circle",
      { 
        alignment: go.Spot.BottomRight, 
        cursor: "col-resize",
        desiredSize: new go.Size(9, 9), 
        fill: "lightblue", 
        stroke: "dodgerblue" 
      }
    )
  );

  export default resizeAdornmentTemplate;