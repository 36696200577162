import * as React from "react";
import { connect } from "react-redux";
import { saveAs } from "file-saver";

import { exportWng } from "../../../actions/helpers/export";

export let name = "dialogSettingsExport";

export class SettingsExport extends React.Component<any, any> {


  state = {
    errorMessage: null,
  };

  constructor(props) {
    super(props);

    this.exportCode = this.exportCode.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.isOpen && !this.props.isOpen) {
      this.setState({ errorMessage: null });
    }
  }

  removeErrors() {
    if (this.state.errorMessage) {
      this.setState({errorMessage: false});
    }
  }

  exportCode() {
    this.removeErrors();
    let { project } = this.props;
    const { source } = this.props;
    const title = project.name.replace(/\s/g , "-");
    const contents = source;
    const contentsBlob = new Blob([contents], { type: "text/plain;charset=utf-8" });
    saveAs(contentsBlob, `${title}.mp`);
  }

  render() {
    let { exportError, state, project } = this.props;

    return (
      <div className="export-buttons">
        <h2>Export project</h2>
        <button 
          className="export_code_button" 
          onClick={e => this.exportCode()}>
          Export as .mp
          </button>
        <button 
          className="export_code_and_graph_button" 
          onClick={e => {
            this.removeErrors();
            exportWng(state, project.code, project.name)
          }}>
          Export as .wng
          </button>
        {
          exportError ?
            <span className="export-error">There was an error while exporting.</span>
            : null
        }
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  const {
    code: { source, title, schemaName },
    compiler: { scope },
    graph: { goJSDiagram, hidingGraph },
    entities: { traces },
    batchExport: { exporting, zip, markedOnly, error }
  } = state;
  const numMarkedTraces = Object.keys(traces).filter(t => traces[t].marked).length;
  return {
    source,
    title,
    schemaName,
    state,
    goJSDiagram,
    hidingGraph,
    zip,
    markedOnly,
    traces,
    exporting,
    numMarkedTraces,
    exportError: error,
    scope
  };
};

export default connect(mapStateToProps)(SettingsExport);
