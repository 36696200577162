import * as go from "gojs";
import { NodeType } from "../../../../actions/node.types";

export let groupTemplate = (options: { nodeType: NodeType }) => {
  const $ = go.GraphObject.make;
  const layout = options.nodeType === "GRAPH_GROUP" ? $(go.CircularLayout, { spacing: 100 }) : null;

  return $(go.Group, "Vertical",
    { layout, selectionObjectName: "GROUP_CONTAINER", },
    new go.Binding("position", "position").makeTwoWay(),
    $(go.TextBlock,
      {
        textAlign: "center",
        font: "bold 18px arial, sans-serif",
        isMultiline: false,
        wrap: go.TextBlock.WrapFit
      },
      new go.Binding("text", "name"),
      new go.Binding("stroke", "", (data) => {
        if (data.darkMode) {
          return "white";
        }
        else {
          return "black";
        }
      }),
    ),
    { cursor: "move" },
    $(go.Panel, "Auto",
      // Shape around entire group
      $(go.Shape, "RoundedRectangle",
        {
          stroke: "gray", strokeWidth: 1, name: "GROUP_CONTAINER",
          margin: new go.Margin(5, 0, 0, 0),
        }, 
        new go.Binding("fill", "", (data) => {
          if (data.darkMode) {
            return "#121212";
          }
          else {
            return "white";
          }
        }),
      ),
      $(go.Placeholder, { margin: new go.Margin(15, 3, 15, 3), background: "transparent" }),
    )
  );
};
