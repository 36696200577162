import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PageLayout from "./page/layout";
import Project from "./page/project";
import Dashboard from "./page/dashboard";
import DashboardNav from "./page/dashboard/dashboardNav";
import Header from "./page/project/header";
import ProjectByGUID from "./projectByGuid";
import { runTour } from "./tour";

class App extends React.Component<any, any> {

  componentDidMount() {
    // Run app tour
    if (!this.props.tourComplete) {
      runTour(this.props.dispatch);
    }
  }

  render() {
    return (
      <PageLayout>
        <Switch>

        <Route path="/project/:projectUUID" component={ProjectByGUID}/>
        
        <Route exact path="/" render={() => {
          if (this.props.user) {
            return (
              <div>
                <DashboardNav/>
                <Header/>
                <Dashboard/>
              </div>
            );
          }

          else {
            return (
              <div>
                <Header/>
                <Project/>
              </div>
            );
          }
        }}/>
        
        
        </Switch>
      </PageLayout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { user, ui: { tourComplete } } = state;
  return { user, tourComplete };
};

export default withRouter(connect(mapStateToProps)(App));