import * as go from "gojs";
import { getNodes, getMaxNodeDimensions, getNodeGroupType } from "./helpers/verticalNodePositioning";
import { getCoordinates } from "./helpers/verticalHelpers";

// Parameter hasViews = true if there will be any views in this diagram
export let generateLayoutSysmlTemplate = () => {

  let trace: Trace = null;

  let SysmlLayout: go.Constructor = function (...args: any[]): Object {
    go.TreeLayout.call(this);
    this.updateModel = updateModel.bind(this);
    return this;
  };
  go.Diagram.inherit(SysmlLayout, go.TreeLayout);

  SysmlLayout.prototype.doLayout = function(coll) {

    let nodes = getNodes(this.diagram);
    let dimensions = getMaxNodeDimensions({ nodes, trace, diagram: this.diagram, horizontal: false });
    let { 
      maxNodeWidth, 
      maxViewRowWidth,
      maxNodeHeight, 
      maxNodeHeightsByRow, 
      maxNodeWidthsByColumn,
      hasSayNodes 
    } = dimensions;
    const verticalPadding = 50;
    const horizontalPadding = 22;

    // Position nodes
    if (trace) {

      this.diagram.startTransaction(`${trace.guid}`);

      nodes.forEach(node => {

        let offsetX = maxViewRowWidth + 25;

        const isInSysmlGroup = getNodeGroupType(node, this.diagram) === "SYSML_GROUP";

        if (isInSysmlGroup || node.data.type === "SAY") {
          const isTable = node.category === "SYSML_TABLE";

          // Set custom coordinates/width for the table that contains the nodes
          if (isTable) {
            const values = Object.values(maxNodeHeightsByRow);
            const tableHeight = values.reduce((a, b) => a + b, 0) + (values.length * verticalPadding);
            this.diagram.model.setDataProperty(node.data, "height", tableHeight);
            // Add extra spacing between SAY nodes and table
            if (hasSayNodes) {
              offsetX = maxViewRowWidth + 25;
            } else {
              offsetX = maxViewRowWidth;
            }
          }
          
          let { x, y } = getCoordinates({
            node,
            maxNodeWidth: maxNodeWidth + 25,
            maxNodeHeight: maxNodeHeight,
            maxNodeHeightsByRow: isTable ? undefined : maxNodeHeightsByRow,
            maxNodeWidthsByColumn: isTable ? undefined : maxNodeWidthsByColumn,
            verticalPadding,
            horizontalPadding: isTable ? 0 : horizontalPadding + 30,
            offsetX,
            offsetY: 0,
          });
          
          // Set x,y coords of nodes
          this.diagram.model.setDataProperty(node, "locationSpot", go.Spot.TopLeft);
          this.diagram.model.setDataProperty(node, "location", new go.Point(x, y));
        }
    

      });

      this.diagram.commitTransaction(`${trace.guid}`);
    }

  };

  let updateModel = (options: { trace: Trace }) => {
    trace = options.trace;
  };

  let layout = new SysmlLayout();

  Object.assign(layout, {
    setsPortSpot: false,
    setsChildPortSpot: false,
    isOngoing: false,
    isValidLayout: true,
    treeStyle: go.TreeLayout.StyleLayered,
    layerStyle: go.TreeLayout.LayerSiblings,
    alignment: go.TreeLayout.AlignmentCenterChildren,
    arrangement: go.TreeLayout.ArrangementHorizontal,
  });

  return layout;
};
