import * as React from "react";
import { connect } from "react-redux";

import { updateSortFiter } from "../../../../actions/sort";

export class Filters extends React.Component<any, any> {

  _filtersEl;

  state = {
    loading: false
  }

  constructor(props) {
    super(props);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.clickAnywhereToClose = this.clickAnywhereToClose.bind(this);
  }

  componentDidMount() {
    if (this._filtersEl) {
      document.addEventListener("mousedown", this.clickAnywhereToClose);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickAnywhereToClose);
  }

  // Closes Filters if the user clicks anywhere but Filters or a child of Filters
  clickAnywhereToClose(e) {
    let children = this._filtersEl.querySelectorAll("div,span,input,select,option");
    // Convert NodeList to array
    children = Array.prototype.slice.call(children);
    const sortButton = document.getElementsByClassName("navigation-sort-button")[0];
    const targetInFilters = children.find((child) => {
      if (child === e.target) {
        return true;
      }
    });

    // If target is not the filter container and is not a child of filter container,
    // and is not the "open sort filters" button
    if (e.target !== this._filtersEl && !targetInFilters && e.target !== sortButton) {
      this.props.close();
    }
  }

  handleChangeFilter(filter, e) {
    this.setState({loading: true});
    let { dispatch } = this.props;
    // Convert from string to boolean for marked filter
    let choice = filter === "marked" ? !this.props.sort.marked.current : e.target.value;
    setTimeout(() => {
      dispatch(updateSortFiter({filter, choice}));
    }, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sort.sorting && !this.props.sort.sorting) {
      this.setState({loading: false});
    }
  }

  render() {
    let { sort, close } = this.props;
    let { marked, events, probability } = sort;

    return (
      <div className="navigation-sort-container" ref={(n) => this._filtersEl = n}>
        {this.state.loading ? <div className="traces-loading"/> : null}
        <div className="navigation-filters">
          <div className="filter marked" onClick={(e) => this.handleChangeFilter("marked", e)}>
            Marked
            <input
              type="checkbox"
              checked={marked.current}
              onChange={e => e.preventDefault()}/>
            <span className="custom-input"/>
          </div>
          <div className="filter event-size">
            <span>Events</span>
            <select value={events.current} onChange={(e) => this.handleChangeFilter("events", e)}>
              {events.options.map((option, i) => {
                return <option key={i} value={option}>{option}</option>
              })}
            </select>
          </div>
          <div className="filter probability">
            <span>Probability</span>
            <select value={probability.current} onChange={(e) => this.handleChangeFilter("probability", e)}>
              {probability.options.map((option, i) => {
                return <option key={i} value={option}>{option}</option>;
              })}
            </select>
          </div>
        </div>
        <span className="sort-close-button" onClick={() => close()}>Done</span>
      </div>
    );
  }
};


let mapStateToProps = (state, ownProps) => {
  let { sort } = state;
  return {
    sort,
  };
};

export default connect(mapStateToProps)(Filters);
