import * as React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { replace } from "lodash";

import { closeDialog } from "../actions/dialog";
import { importMP } from "../actions/import";
import { getAllProjects } from "../actions/projects";

export let name = "dialogImport";
const defaultFolderName = "MP_Manual_examples"

export class ExampleDropdown extends React.Component<any, any> {

  state = {
    mpExamples: {},
    folderOpenName: defaultFolderName,
    loading: false,
    loadingMessage: ""
  };

  examples = {};

  _fileInputEl: HTMLInputElement | null = null;
  _exampleSelectEl: HTMLSelectElement | null = null;

  constructor(params) {
    super(params);
  }

  componentDidMount(){
    this.getExamples(this.examples);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen && !prevProps.isOpen) {
      (this._exampleSelectEl as HTMLSelectElement).value = "default";
    }
  }

  async getExamples(examples) {

    const projectUrl = process.env.GITLAB_PROJECT_URL; 
    const files_per_page = 100;
    let totalPages = 1;

    // Make gitlab api call to get the total # of pages
    await axios({
      method: "GET",
      url: `${projectUrl}&per_page=${files_per_page}&`
    }).then((res) => {

      totalPages = res.headers["x-total-pages"];

    });

    for (let i = 1; i <= totalPages; i++) {
      try {
        await axios({
          method: "GET",
          url: `${projectUrl}&per_page=${files_per_page}&page=${i}`
        }).then((res) => {

          res.data.forEach(element => {

            let folderName = element.path.split('/')[1];

            if (!element.path.includes(".gitkeep")) {

              // Create sub folder
              if (element.type == "tree" && folderName) {
                this.createSubFolder(examples, folderName);
              } else {
                const file = {
                  name: element.name.substr(0, element.name.length - 3),  //remove file extension for dropdown list
                  blobId: element.id,
                  path: element.path
                }

                // If file is in a folder, add it to folder in list
                if (element.path.split('/').length > 2 && folderName) {
                  examples[folderName].files.push(file);
                }

                // If in the root directory, push to files array
                if (element.path.split('/').length == 2) {

                  // Add MP_Manual_examples root folder the first time
                  const rootFolderName = defaultFolderName;
                  if (!examples[rootFolderName]) {
                    this.createRootFolder(examples, rootFolderName);
                  } 
                    examples[rootFolderName].files.push(file);
                }
              }
            }
          });
        });
      } catch (err) {
        console.error(`An error occurred getting examples: ${err}`);
      }
    }
    this.setState({ mpExamples: examples });
  }

  private createSubFolder(examples: any, subFolderName: string) {
    examples[subFolderName] = {};
    examples[subFolderName].files = [];
  }

  private createRootFolder(examples: any, rootFolderName: string) {
    examples[rootFolderName] = {};
    examples[rootFolderName].files = [];
  }

  handleOpenFolder(folderName: string) {
    const { folderOpenName } = this.state;
    
    // If folder is already open, close it
    if (folderName === folderOpenName) {
      this.setState({folderOpenName: null});
    }
    else {
      this.setState({folderOpenName: folderName});
    }
  }

  async loadExample(path, blobId, title) {

    const { dispatch, creatingProject, openSnackbar, dialogName } = this.props;
    let source = "";

    // Turn on loading message
    this.setState({loading: true, loadingMessage: "Loading example..."});

    // Get example file content from gitlab file
    try {
      await axios({
        method: "GET",
        url:` ${process.env.GITLAB_FILE_URL}` + blobId + `?filepath=` + replace(path, ".", "%2E")
      }).then((res) => {
        
        source = decodeURIComponent(escape(window.atob(res.data.content)));

        // If creating a new project
        if (creatingProject) {
          // Extract the description from the source code if there is a comment at the top
          const comment = source.match(/\/\*([\0-\uffff]*?)\*\//);
          const description = comment && comment[1] ? comment[1] : "";

          axios({
            method: "POST",
            url: `/api/project/create`,
            data: {
              name: title,
              description,
              code: source
            }
          }).then((res) => {

            this.setState({ inputValue: "", textAreaValue: "", usingExample: false, error: null });
            dispatch(getAllProjects()); 
            dispatch(closeDialog({ name: dialogName }));

          }).catch((error) => {
            alert("An error occurred creating the project:" + error);
            console.error(`An error occurred creating the project:: ${error}`)
          });
        } else {
          
          // If not creating a new project, just load example
          dispatch(importMP({ source, title }));
          dispatch(closeDialog({ name: dialogName }));
        }
      });
    } catch (err) {
      alert("An error occurred loading example:" + err);
      console.error(`An error occurred loading example: ${err}`);
    }

    // Turn off loading message
    this.setState({loading: false, loadingMessage: "Loading example..."});

  }

  render() {
    const { mpExamples, loading, loadingMessage, folderOpenName } = this.state;
    
    // Place "MP_Manual_examples" folder at the top
    const exampleKeys = Object.keys(mpExamples);
    const defaultExampleIndex = exampleKeys.indexOf(defaultFolderName);
    const sortedExamples = [...exampleKeys.splice(defaultExampleIndex, 1), ...exampleKeys];

    return (
      <div>
        <div className="importContainer">
          <label>Load example:</label>
          <div className="example-list">
            {!loading ? sortedExamples.map((folderName) => {
              return (
                <div className="folder" onClick={() => this.handleOpenFolder(folderName)}>
                  <div className="folder-title">
                    <div className="icons">
                      <i className={`icon fa ${folderOpenName === folderName ? "fa fa-caret-down" : "fa-caret-right"}`}/>
                      <i className="icon fa fa-folder"/>                    
                    </div>
                    <span>{folderName}</span>
                  </div>
                  <div className={folderOpenName === folderName ? "examples" : "hidden"}>
                    {mpExamples[folderName].files ? mpExamples[folderName].files.map(example => {
                      return (
                        <div 
                          className="example"
                          data-tip={example.name}
                          data-place="top"
                          onClick={() => this.loadExample(example.path, example.blobId, example.name)}>
                          {example.name}
                        </div>
                      );
                    }) : null}
                  </div>
                </div>
              );
            }) : 
            <div className="loading">
              <img src="/images/ajax-loader.gif"/> {loadingMessage}
            </div>
            }
          </div>
        </div> 
      </div>
    );
  }
};

let mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(ExampleDropdown);