import { saveAs } from "file-saver";

export const exportWng = (state, projectSource, projectName) => {
  let title = projectName.replace(/\s/g , "-");

  const { dialogs, routing, ui, compiler, graph, ...stateToSave } = state;
  console.log("project source", projectSource)
  stateToSave.version = 3;
  stateToSave.code.title = projectName
  stateToSave.code.source = projectSource;

  // Remove data we don't want to save
  delete stateToSave.user;
  delete stateToSave.currentProject;
  delete stateToSave.projects;
  delete stateToSave.batchExport;

  console.log("STATE TO SAVE...", stateToSave)
  const contents = JSON.stringify(stateToSave, null, 2);
  const contentsBlob = new Blob([contents], { type: "text/plain;charset=utf-8" });
  saveAs(contentsBlob, `${title}.wng`);
}