import * as React from "react";
import { connect } from "react-redux";
import { flatten } from "lodash";

import { selectTraceByIndex } from "../../../actions/trace";
import { toggleExpandGlobal } from "../../../actions/ui";
import { addDiagramsForExport } from "../../../actions/batchExport";

import Filters from "./filters/";
import Draggable from "../../../component/draggable";
import GoJSMainGraphComponent from "./graph/gojs/index.browser";
import Pagination from "./pagination";

import { MIN_MIDDLE_WIDTH } from "../";

export class Navigation extends React.Component<any, any> {
  _sidebarContainer;
  _traceContainer;
  _globalContainer;
  _trace;
  usingIE: boolean = false;
  prevX;
  diagrams: Array<any> = [];

  prevDeltaX = 0;
  firstResize = true;
  state = {
    filtersVisible: false,
    selectedTraceIndex: 0,
    traceContainerHeight: "auto",
    diagramsLoaded: false
  }

  constructor(props) {
    super(props);
    this.handleUpdateSidebarSize = this.handleUpdateSidebarSize.bind(this);
    this.handleGetDiagram = this.handleGetDiagram.bind(this);
    this.calculateHeights = this.calculateHeights.bind(this);
    this.handleSortClose = this.handleSortClose.bind(this);
    this.handleOnToggleGlobalView = this.handleOnToggleGlobalView.bind(this);
  }

  componentDidMount() {
    this.calculateHeights();
    // Get browser type for sidebar resizing
    const browserType = window.navigator.userAgent;
    this.usingIE = browserType.includes("MSIE") || browserType.includes("Trident");
  }

  // For batch export: Gets diagram from each gojs component to make list of all diagrams
  async handleGetDiagram(diagram, order) {
    const { markedOnly, markedTraceCount, originalTraceCount } = this.props;
    // Add order number to diagram
    diagram = {diagram, order};
    this.diagrams.push(diagram);
    // const numTraces = markedOnly ? markedTraceCount : originalTraceCount;
    //if (numTraces === this.diagrams.length) {
      // Send diagrams to Redux so export component can use them
      await this.props.dispatch(addDiagramsForExport({diagrams: this.diagrams}));
      this.diagrams = [];
    //}
  }

  calculateHeights() {
    if (this._sidebarContainer && this._globalContainer) {
      const sidebarHeight = this._sidebarContainer.offsetHeight;
      const globalContainerHeight = this._globalContainer.offsetHeight;
      const traceContainerHeight = sidebarHeight - globalContainerHeight + 258;
      this.setState({traceContainerHeight});
    }
  }

  // Automatically scrolls sidebar to the selected trace, if the selected trace will be out-of-view
  componentDidUpdate(prevProps, prevState) {
    const {
      navigationExpanded,
      currentTraceGuid,
      currentPageTraceIds
    } = this.props;

    // if new trace is selected:
    if (this._traceContainer && this._trace) {

      // If a new trace is selected:
      // (also prevents global view from triggering this, since global view is in a separate div)
      if (prevProps.currentTraceGuid !== currentTraceGuid && currentTraceGuid !== "global") {
        const scrollbarLocation = this._traceContainer.scrollTop;
        // +2 to take account for the border
        const traceHeight = this._traceContainer.getElementsByClassName("trace")[0].clientHeight + 2;
        const containerHeight = this._traceContainer.clientHeight;

        const scrollHeight = this._traceContainer.scrollHeight;

        // Slice to prevent mutation
        let selectedTraceIndexReverse = currentPageTraceIds.slice().reverse().indexOf(currentTraceGuid);

        // +1 because the index starts at 0
        const selectedTraceIndex = selectedTraceIndexReverse + 1;
        const pixelDestination = scrollHeight - (selectedTraceIndex * traceHeight);
        const isAbove = pixelDestination < scrollbarLocation;
        const isBelow = pixelDestination + traceHeight > containerHeight + scrollbarLocation;

        if (isAbove) {
          this._traceContainer.scroll({
            top: pixelDestination,
            behavior: "smooth",
          });
        }

        if (isBelow) {
          // If below, scroll only down to the bottom, not top
          this._traceContainer.scroll({
            top: pixelDestination - (containerHeight - traceHeight),
            behavior: "smooth",
          });
        }

      }
    }

    // // After compiling, sort traces if a filter is set
    // if (!compiler.graphing && prevTraceCount < currTraceCount && currTraceCount === totalTraceCount) {
    //   if (find(sort, "current")) {
    //     dispatch(updateSortedTraces());
    //   }
    // }

    // Reset prevX if the sidebar just opened up
    if (!prevProps.navigationExpanded && navigationExpanded) {
      this.prevX = null;
    }

  }

  handleUpdateSidebarSize(e: MouseEvent) {
    const { onResize, splitSizes, widthPercentage } = this.props;
    e.preventDefault();
    e.stopPropagation();
    let newWidthPercentage: number = 0;
    // If using IE, handle sidebar update differently:
    if (this.usingIE) {
      newWidthPercentage = 100 - ((e.screenX / window.outerWidth) * 100);
      const newMiddleWidthPercentage = 100 - (splitSizes.left + newWidthPercentage);
      // Only allow resize if the middle width will stay above 20%
      if (newMiddleWidthPercentage > MIN_MIDDLE_WIDTH) {
        onResize("right", newWidthPercentage);
        this.calculateHeights();
      }
    }
    else {
      const deltaX = e.movementX;
      let codeSplitSizePx = (widthPercentage / 100) * window.innerWidth;
      const newWidthPercentage = (codeSplitSizePx - deltaX) / window.innerWidth * 100;
      onResize("right", newWidthPercentage);
      this.calculateHeights();
    }
  }

  // Closes sort box when "Done" button is clicked
  handleSortClose = () => {
    this.setState({filtersVisible: false});
  }

  handleOnToggleGlobalView = () => {
    const { globalExpanded, dispatch } = this.props;
    dispatch(toggleExpandGlobal({}));
    this.calculateHeights();
  }

  render() {
    let {
      dispatch,
      navigationExpanded,
      viewMode,
      traceCount,
      widthPercentage,
      onResizeStop,
      displayPlaceholder,
      hidingGraph,
      globalExpanded,
      currentTraceGuid,
      traceIds,
      globalTrace,
      traces,
      currentPageTraceIds,
      offsetHeight
    } = this.props;

    let { filtersVisible } = this.state;

    let selectedTraceIndex = traceIds.indexOf(currentTraceGuid);

    return (
      <div
        ref={(n) => this._sidebarContainer = n}
        className={`graph-sidebar ${displayPlaceholder ? "graph-sidebar-placeholder" : ""}`}
        style={{
          width: `${widthPercentage}%`,
          display: !navigationExpanded || viewMode === "CODE" ? "none" : "inline",
          top: `${offsetHeight}px`,
          height: `calc(100% - ${offsetHeight}px)`
        }}>

        {viewMode !== "CODE" ?
          <Draggable onDragEvent={this.handleUpdateSidebarSize} onStopEvent={() => onResizeStop()}>
            <span className="navigation-drag-area"/>
          </Draggable>
        : null }

        <div className="graph-sidebar-wrapper" style={{display: displayPlaceholder ? "none" : "inline"}}>
          <div className="navigation-container">
            <div className="navigation-trace-content">
              <div className="navigation-trace-container" style={{alignItems: "center"}}>

                <span className={`navigation-sort ${filtersVisible ? "expanded" : ""}`}>
                  <div
                    className="options-dropdown-button"
                    onClick={() => this.setState({filtersVisible: !filtersVisible})}
                    data-place="left"
                    data-tip="Adjust sort options">
                    Sort
                    <i className="icon fas fa-caret-down dropdown-icon"/>
                  </div>
                  {filtersVisible ? 
                    <Filters close={this.handleSortClose}/> : null}
                </span>

                <div className="prev-next-container">
                  <i
                    className={`icon fas fa-chevron-left prev-next-icon ${hidingGraph || (selectedTraceIndex <= 0) ? "disabled" : ""}`}
                    data-place="bottom"
                    data-tip="previous trace"
                    onClick={(e) => {
                      if (selectedTraceIndex > 0 && !hidingGraph) {
                        dispatch(selectTraceByIndex({index: selectedTraceIndex - 1}));
                      }
                      else {
                        e.preventDefault();
                      }
                    }}/>
                  <i
                    data-place="bottom"
                    data-tip="next trace"
                    className={`icon fas fa-chevron-right prev-next-icon ${hidingGraph || (selectedTraceIndex + 1 >= traceIds.length) ? "disabled" : ""}`}
                    onClick={(e) => {
                        if (selectedTraceIndex + 1 < traceIds.length && !hidingGraph) {
                          dispatch(selectTraceByIndex({index: selectedTraceIndex + 1}));
                        }
                        else {
                          e.preventDefault();
                        }
                    }}/>
                </div>
                <div className="navigation-trace-input-pagination">
                  <input
                    className="navigation-input" 
                    value={hidingGraph ? "0" : selectedTraceIndex + 1}
                    disabled={hidingGraph}
                    onChange={(e) => dispatch(selectTraceByIndex({index: parseInt((e.target as HTMLSelectElement).value) - 1}))}/>
                  <span className="navigation-trace-input-description">
                    <span>of </span>
                    <span className="navigation-trace-count">{hidingGraph ? "0" : traceCount}</span>
                  </span>
                </div>
              </div>

              {globalTrace && !hidingGraph ? 
                <div className={`global-container ${globalExpanded ? "expanded" : ""}`}
                  style={{maxHeight: globalExpanded ? "auto" : "30px"}} 
                  ref={(n) => this._globalContainer = n}>
                  <div
                    className={`toggle-global-button ${globalExpanded ? "expanded" : ""}`}
                    onClick={(e) => this.handleOnToggleGlobalView()}>
                    <h3>Global View</h3>
                  </div>
                  {globalExpanded ?
                    <div className="global-trace" style={{overflow: "auto", maxHeight: "220px"}}>
                      <GoJSMainGraphComponent traceGuid={"global"} onDiagramLoad={this.handleGetDiagram} />
                    </div> : null}
                </div> : null}

              <ul className="traces" ref={n => this._traceContainer = n}>
                {
                  hidingGraph ? null : 
                  currentPageTraceIds.map((traceGuid, i) => {
                    // If statement for compiling. Not all traces have been loaded yet
                    if (traces[traceGuid]) {
                      return (
                        <GoJSMainGraphComponent
                          isDuplicateOfMainGraph={traceGuid === currentTraceGuid}
                          traceGuid={traceGuid} 
                          key={traces[traceGuid].order} 
                          ref={n => this._trace = n}
                          onDiagramLoad={this.handleGetDiagram}
                        />
                      );
                    }
                  })
                }
              </ul>

              <div className="logos-container">
                <Pagination/>
                <div className="logo-divider" style={{marginTop: "10px"}}/>
                <a href="https://wiki.nps.edu/display/MP/Monterey+Phoenix+Home" target="_blank" title="Monterey Phoenix">
                  <span className="mpLogo"/>
                </a>
                <div className="logo-divider"/>
                <a href="http://nps.edu" target="_blank" title="Naval Postgraduate"><span className="npsLogo"/></a>
              </div>
              
            </div>
          </div>
        </div>

      </div>
    );
  }
};


let mapStateToProps = (state, ownProps) => {
  const {
    ui: { navigationExpanded, viewMode, splitSizes, globalExpanded },
    entities: { traces },
    currentTraceGuid,
    graph: { hidingGraph },
    sort,
    compiler,
    batchExport: { markedOnly },
    pagination: { pages, currentPage, tracesPerPage }
  } = state;
  const { sorting } = sort;

  let originalTraceKeys = Object.keys(traces);
  
  // Trace count before removing global
  const originalTraceCount = originalTraceKeys.length;
  let markedTraceCount = originalTraceKeys.filter(id => traces[id].marked).length;
  // If there are pages, get the current page
  let currentPageTraceIds = Object.keys(pages).length >= 1 ? pages[currentPage] : [];

  // Turn all pages into one big array of trace ids
  let traceIds = flatten(Object.keys(pages).map(p => pages[p]));

  let traceCount = traceIds.length;
  const globalTrace = traces["global"];

  return {
    navigationExpanded,
    viewMode,
    splitSizes,
    traceCount,
    markedTraceCount,
    traces,
    currentTraceGuid,
    hidingGraph,
    globalExpanded,
    compiler,
    sorting,
    sort,
    markedOnly,
    traceIds,
    globalTrace,
    originalTraceCount,
    currentPageTraceIds,
    tracesPerPage
  };
};

export default connect(mapStateToProps)(Navigation);
