import * as go from "gojs";

export const sysmlTableTemplate = () => {
  let $ = go.GraphObject.make;

  let barChartTemplate =
    $(go.Node, "Auto",
      { selectable: false, margin: 0, layerName: "first" },
      new go.Binding("visible", "", (data, node) => {
        return !data.hidden;
      }),
      new go.Binding("position", "position").makeTwoWay(),
      $(go.Shape, { fill: null, stroke: "#333333", strokeWidth: 3 }),
      $(go.Panel, "Table",
        new go.Binding("itemArray", "itemArray"),
        {defaultColumnSeparatorStroke: "#333333", defaultColumnSeparatorStrokeWidth: 3,
        defaultRowSeparatorStroke: "#333333", defaultRowSeparatorStrokeWidth: 3},
        $(go.RowColumnDefinition,
          { row: 0, background: "purple", separatorStrokeWidth: 1 },
          new go.Binding("background", "", (data) => {
            if (data.grayscaleGraphs) {
              return "black";
            }
            else return "#257c40";
          })
        ),
        $(go.RowColumnDefinition,
          { row: 1, separatorStroke: "black" }),
        // The table headers:
        $(go.Panel, "TableRow",
          { isPanelMain: true },
          new go.Binding("itemArray", "headers"),
          {
            itemTemplate:
              $(go.Panel,
                new go.Binding("column"),
                $(go.TextBlock,
                  {
                    margin: new go.Margin(18, 20, 16, 20),
                    width: 260,
                    font: "24px arial, sans-serif",
                    stroke: "white",
                    textAlign: "center",
                    verticalAlignment: go.Spot.Center,
                  },
                  new go.Binding("width"),
                  new go.Binding("text"))
              )
          }
        ),
        { // the rows for the nodes
          itemTemplate:
            $(go.Panel, "TableRow",
              new go.Binding("itemArray", "columns"),
              {
                itemTemplate:
                  $(go.Panel,
                    { stretch: go.GraphObject.Fill, alignment: go.Spot.Center },
                    new go.Binding("column")
                  )
              }
            )
        },
       new go.Binding("height")
      )
    );
  return barChartTemplate;

};

export const groupSysMLTemplate = () => {
  let $ = go.GraphObject.make;
  return $(go.Group, "Auto",
    { cursor: "move" },
    $(go.Panel, "Auto",
      // Shape around entire group
      $(go.Shape, "RoundedRectangle",
        {
          fill: null, stroke: null,
        }),
      $(go.Placeholder, { margin: 0, background: "transparent" })
    )
  );
};