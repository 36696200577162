import * as React from "react";
import { closeDialog } from "../../actions/dialog";
import reduxDialog from "..";
import { connect } from "react-redux";

import axios from "axios";
import { getAllProjects } from "../../actions/projects";
import ExampleDropdown from "../exampleDropdown";

export let name = "dialogCreateProject";

export class CreateProject extends React.Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      textAreaValue: "",
      user: props.user,
      usingExample: false,
      source: "",
      error: null,
      mpExamples: []
    };

    this.createProject = this.createProject.bind(this);
    this.updateCheckbox = this.updateCheckbox.bind(this);
  }

  componentDidMount() {
    this.getExamples();
  }

  async getExamples() {
    await axios({
      method: "GET",
      url: `/api/example/all/`
    }).then((response) => {
      this.setState({ mpExamples: response.data.examples });
    });
  }

  updateInputValue(evt) {
    this.setState({
      inputValue: evt.target.value
    });
  }

  updateTextAreaValue(evt) {
    this.setState({
      textAreaValue: evt.target.value
    });
  }

  async updateCheckbox(evt) {
    await this.setState({ usingExample: evt.target.checked, error: null });
  }

  async createProject() {
    const { dispatch, user } = this.props;
    const { source, usingExample } = this.state;

    if (usingExample && !source) {
      await this.setState({ error: `Please select an example to continue.`});
      return;
    }

    await axios({
      method: "POST",
      url: `/api/project/create`,
      data: {
        name: this.state.inputValue,
        description: this.state.textAreaValue,
        code: usingExample ? source : null
      }
    }).then(async (res) => {
      await this.setState({ inputValue: "", textAreaValue: "", usingExample: false, error: null });
      dispatch(getAllProjects());
      dispatch(closeDialog({name}));

    }).catch((error) => {
      alert("There was an error creating the project.");
      console.log("error", error);
    });
  }

  render() {
    let { dispatch } = this.props;
    let { usingExample, error } = this.state;

    return (
      <div>
        <div className="dialog-header">
          <h1>Create new project</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({ name }))}></span>
        </div>
        <div className="dialog-body">
          <div className="input-container">
            <label className="input-label">Project title</label>
            <input className="dialog-input create-project-input" type="text" value={this.state.inputValue} onChange={evt => this.updateInputValue(evt)}/>
          </div>
          <div className="input-container">
            <label className="input-label">Project description</label>
            <textarea rows={8} className="dialog-textarea dialog-input create-project-input" value={this.state.textAreaValue} onChange={evt => this.updateTextAreaValue(evt)}/>
          </div>
          <div className="template-container">
            <div className="checkbox-div">
              <label className="checkbox-label input-label" htmlFor="usingTemplate">Start with example:</label>
              <input
                name="usingTemplate"
                className="template-checkbox"
                type="checkbox"
                checked={usingExample}
                onChange={this.updateCheckbox}
              />
            </div>
            { usingExample ? <ExampleDropdown creatingProject={true} dialogName={ name }/> : null }
          </div>
          <span className="create_project_button add-permission-button" onClick={() => this.createProject()}>Create</span>
        </div>
      </div>
    );
  }
}

let ReduxImport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog",
  startStyle: (dialogStyleEvent) => {
    let { nodes: { container }} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 250ms, top 250ms`,
      transitionTimingFunction: `ease`
    };
  },
  endStyle: (dialogStyleEvent) => {
    let { nodes: { container }} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${window.innerHeight / 2 - container.offsetHeight / 2}px`,
      transition: `left 250ms, top 250ms`,
      transitionTimingFunction: `ease`
    };
  },
})(CreateProject);

let mapStateToProps = (state, ownProps) => {
  const { projects, user } = state;
  return {
    projects,
    user
  };
};

export default connect(mapStateToProps)(ReduxImport);
