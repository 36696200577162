import * as go from "gojs";
import resizeAdornmentTemplate from "./resizeAdornmentTemplate";

let generateNodeTemplate = () => {
  let $ = go.GraphObject.make;

  let tableTemplate =
    $(go.Node, go.Panel.Viewbox,
      {
        resizable: true,
        resizeAdornmentTemplate,
        cursor: "move",
      },
      new go.Binding("position", "position").makeTwoWay(),
      //new go.Binding("width", "width").makeTwoWay(),
      $(go.Panel, "Vertical",
        $(go.TextBlock,
          {
            textAlign: "center",
            stroke: "black",
            font: "bold 18px arial, sans-serif",
            isMultiline: false,
            width: 250,
            verticalAlignment: go.Spot.Top,
          },
          new go.Binding("text", "name"),
          new go.Binding("stroke", "", (data) => data.darkMode ? "#cccccc" : "black")
          ),

        $(go.Panel, "Auto",
          { margin: new go.Margin(10, 0, 0, 0)},
          $(go.Shape, "RoundedRectangle", {
            stroke: "gray",
            fill: "white",
            cursor: "move",
          }),
          $(go.Panel, "Table",
            new go.Binding("itemArray", "itemArray"),
            {
              defaultColumnSeparatorStroke: "#333333",
              defaultColumnSeparatorStrokeWidth: 1,
              defaultRowSeparatorStroke: "#333333",
              defaultRowSeparatorStrokeWidth: 1,
              columnSizing: go.RowColumnDefinition.None,
              defaultSeparatorPadding: 10,
            },

            // the table headers -- remains even if itemArray is empty
            $(go.Panel, "TableRow",
              { isPanelMain: true },
              new go.Binding("itemArray", "headers"),
              {
                itemTemplate:  // bound to a column definition object
                  $(go.Panel,
                    new go.Binding("column"),
                    $(go.TextBlock,
                      {  font: "normal 14pt sans-serif" },
                      new go.Binding("text"))
                  )
                }
              ),

              { // the rows for the events
                defaultAlignment: go.Spot.Left,
                itemTemplate:  // bound to an event/row data object
                  $(go.Panel, "TableRow",
                    new go.Binding("itemArray", "columns"),
                    { itemTemplate:
                      $(go.Panel,
                        new go.Binding("column", "column"),
                        $(go.TextBlock,
                          { font: "normal 12pt sans-serif" },
                          new go.Binding("text"))
                      )
                    }
                  )
              }
            )
        )

      )
    );

  return tableTemplate;

};

export let nodeTableTemplate = () => generateNodeTemplate();
