import * as TRACE from "../actions/trace.types";
import * as CP from "../actions/compiler.types";
import { CLEAR_GRAPHS } from "../actions/graph.types";

export let reducer = (state = null, action) => {
  switch (action.type) {
    case CP.CP_COMPILE_END: {
      return null;
    }
    case CLEAR_GRAPHS: {
      return null;
    }
    case TRACE.TRACE_SELECT: {
      return action.guid;
    }
    default:
      return state;
  }
};

export default reducer;
