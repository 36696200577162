import { merge, concat } from "lodash";
import * as uuid from "uuid";
import * as BlueBird from "bluebird";
import { Diagram } from "gojs";

import { saveStateToLocalStorage } from "../store";

import { selectTraceByIndex } from "./trace";
import * as GRAPH from "./graph.types";
import { CP_COMPILE_RESET } from "./compiler.types";
import { NormalizedTrace } from "./trace.types";
import { NormalizedNode } from "./node.types";
import { NormalizedLink } from "./link.types";
import { mapNodesFromCompiler } from "./graph/nodes";
import { mapLinksFromCompiler } from "./graph/links";
import { mapViewsFromCompiler } from "./graph/views";
import { setPages } from "./pagination";
import { stop } from "./compiler";
import { openDialog, closeDialog } from "./dialog";

// Sets the "hidingGraph" variable for hiding the graph when there's a new import
export let hideGraph = () => {
  return async (dispatch, getState) => {
    await dispatch({
      type: GRAPH.HIDE_GRAPH
    });
  };
};

// Sets "hidingGraph" variable to false
export let showGraph = () => {
  return async (dispatch, getState) => {
    await dispatch({
      type: GRAPH.SHOW_GRAPH
    });
  };
};

export let generateGraphsFromCompilerOutput = (options: {
  messageData: GRAPH.CompilerResponse,
  firstTraceGuid,
}) => {
  return async (dispatch, getState) => {
    let { messageData, firstTraceGuid } = options;

    let globals;
    const state = getState();

    // Looping through Globals, separately,
    // because need to generate separate traces to display at special location in nav bar
    if (messageData.GLOBAL) {
      let filteredNodes = messageData.GLOBAL.slice(1).filter(node => {
        let n = Object.keys(node);
        return n[0] === "ROOT" ||
          n[0] === "COMPOSITE" ||
          n[0] === "ATOM" ||
          n[0] === "SAY";
      });

      let parsedNodes = [];

      filteredNodes.forEach(node => {
        let rowCount = 0;
        switch (Object.keys(node)[0]) {
          case "ROOT":
            node["ROOT"].forEach(n => {
              parsedNodes = concat(parsedNodes, [[n, "R", undefined, 0, rowCount]]);
              rowCount += 1;
            });
            break;
          case "COMPOSITE":
            node["COMPOSITE"].forEach(n => {
              parsedNodes = concat(parsedNodes, [[n, "C", undefined, 0, rowCount]]);
              rowCount += 1;
            });
            break;
          case "ATOM":
            node["ATOM"].forEach(n => {
              parsedNodes = concat(parsedNodes, [[n, "A", undefined, 0, rowCount]]);
              rowCount += 1;
            });
            break;
          case "SAY":
            node["SAY"].forEach(n => {
              parsedNodes = concat(parsedNodes, [[n, "T", undefined, 0, rowCount]]);
              rowCount += 1;
            });
            break;
        }
      });

      let { nodes, nodesByCompileId } = mapNodesFromCompiler(parsedNodes);

      let { viewNodes, viewLinks } = mapViewsFromCompiler(
        messageData.GLOBAL.slice(1).filter(node => {
          let n = Object.keys(node);
          return n[0] === "REPORT" ||
            n[0] === "TABLE" ||
            n[0] === "BAR_CHART" ||
            n[0] === "GANTT_CHART" ||
            n[0] === "AD" ||
            n[0] === "GRAPH";
        })
      );

      let tempNodes = merge({}, nodes, viewNodes);
      let tempLinks = {};
      viewLinks.forEach(l => tempLinks[l.guid] = l);

      let tempTraces = {
        "global": {
          guid: "global",
          layout: "SEQUENCE",
          marked: false,
          nodes: Object.keys(tempNodes),
          links: viewLinks.map(l => l.guid),
        }
      };

      globals = {
        traces: tempTraces,
        nodes: tempNodes,
        links: tempLinks,
      };
    }

    // Looping through Traces
    let entitySets = messageData.traces.map((t: any[], i) => {
      /**
       * Looping through the Events.
       * 0 Name
       * 1 Type
       * 3 UID
       * 4 Position Column
       * 5 Position Row
       */

      // Get node and link data
      let { nodes, nodesByCompileId } = mapNodesFromCompiler(t[2]);
      let links = mapLinksFromCompiler(t, nodesByCompileId);

      // This part combines view nodes and links with regular nodes and links
      // t.length - 1 = the last position in the trace which is the views array.
      const views = t[t.length - 1].VIEWS;

      if (views && views.length > 0) {
        let { viewNodes, viewLinks } = mapViewsFromCompiler(views);
        // Combine view nodes and links with regular nodes and links
        nodes = { ...nodes, ...viewNodes };
        links = [...links, ...viewLinks];
      }

      // Set up trace and map node and link data to it
      let traces = [{
        guid: i === 0 ? firstTraceGuid : uuid.v4(),
        layout: "SEQUENCE",
        marked: t[0] === "M",
        nodes: Object.keys(nodes),
        probability: t[1],
        links: links.map(l => l.guid),
        order: i + 1
      }] as NormalizedTrace[];

      let traceEntities = {};
      let linkEntities = {};

      traces.forEach(t => traceEntities[t.guid] = t);
      links.forEach(l => linkEntities[l.guid] = l);

      return {
        traces: traceEntities,
        nodes,
        links: linkEntities,
      };
    });

    const dataSize = new Blob([JSON.stringify(entitySets)]).size;
    //Only save data to browser if there is enough space left
    //if (dataSize < 10000000) {
      
      // Send trace guids to be used for pagination
      dispatch(setPages({ traceGuids: entitySets.map(e => Object.keys(e.traces)[0]) }));

      // Add global trace first
      if (state.compiler.graphing && !state.compiler.compiling && globals !== undefined) {
        await new BlueBird(resolve =>
          setTimeout(() => {
            dispatch(merge({}, {
              type: GRAPH.GRAPH_ADD_TRACE,
              firstTraceGuid: "global",
              payload: globals
            }));
            resolve();
          }));
      }

      for (let i = 0; i < entitySets.length; i++) {
        let eS = entitySets[i];
        // See if graphing has been canceled by the user.
        let state = getState();
        if (!state.compiler.graphing) {
          // Stop Graphing
          saveStateToLocalStorage(getState());
          return;
        }
        await new BlueBird(resolve =>
          setTimeout(() => {
            dispatch(merge({}, {
              type: GRAPH.GRAPH_ADD_TRACE,
              firstTraceGuid,
              payload: eS,
            }));
            // On the first trace
            if (i === 0) {
              dispatch(selectTraceByIndex({ index: 0 }));
              // If graph was hidden beause of importing, unhide it
              if (state.graph.hidingGraph) {
                dispatch(showGraph());
              }
            }
            resolve();
          })
        );

      }

      dispatch({type: GRAPH.GRAPH_ALL_TRACES_ADDED});
      saveStateToLocalStorage(getState());
 //   }
    // else {
    //   // Reset compiler and display error message
    //   dispatch(stop());
    //   dispatch(openDialog({name: "data-warning"}))
    // }
  
    
    
   };
};

export let syncGraphToRedux = (options: {
  payload: {
    trace: NormalizedTrace,
    nodes: NormalizedNode[],
    links: NormalizedLink[],
  },
}) => {
  return async (dispatch, getState) => {
    let { nodes, trace, links } = options.payload;
    let updatedTrace = merge({}, trace);

    // Update trace node array
    updatedTrace.nodes = Object.keys(nodes);

    await dispatch(merge({}, {
      type: GRAPH.GRAPH_SYNC_TO_REDUX,
      trace: updatedTrace,
      nodes,
      links
    }));
    saveStateToLocalStorage(getState());
  };
};

export let setGoJSDiagram = (options: {
  goJSDiagram: Diagram | null,
}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: GRAPH.GRAPH_SET_GOJS_DIAGRAM,
    }));
  };
};

// Clears graph data (compiler output and entities) 
export let clearGraphs = () => {
  return async (dispatch, getState) => {
    await dispatch(hideGraph());
    await dispatch({type: GRAPH.CLEAR_GRAPHS});
    await dispatch({type: CP_COMPILE_RESET});
    await dispatch(closeDialog({name: "clear-graphs-warning"}));
    saveStateToLocalStorage(getState());
  };
};

