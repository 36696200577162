import * as go from "gojs";
import { positionView, positionActivityDiagramNodes } from "./verticalHelpers";

export const VIEW_MARGIN = 10;

export type TableNode = go.Node & {
  column: number,
  row: number
};

type RowDimensions = {[row: string]: {[column: string]: number}};
type groupDimensions = {[row: string]: number};

export const regularNodes = ["ROOT", "COMPOSITE", "ATOM", "SAY"];

// Get a node's group's category
export const getNodeGroupType = (node, diagram) => {
  if (node.data.group) {
    const nodeGroup = diagram.findNodeForKey(node.data.group);
    if (nodeGroup) {
      return nodeGroup.data.category;
    }
  }
  return false;
};

export const getNodes = (diagram) => {
  let nodes: TableNode[] = [];
  let iterator = diagram.nodes.iterator;
  iterator.reset();
  while (iterator.next()) {
    nodes.push(iterator.value);
  }
  return nodes;
};

// Returns max node width and heights to be used for positioning and positions views
export const getMaxNodeDimensions = (options: {
  nodes: any,
  trace: any,
  diagram: any,
  horizontal: boolean
}) => {
    let { nodes, trace, diagram, horizontal } = options;

    // Get max height and width for regular nodes (ROOT, COMPOSITE, etc.)
    let regularNodeWidths: number[] = [];
    let regularNodeHeights: number[] = [];
    let maxNodeHeightsByRow: Object = {};
    let maxNodeWidthsByColumn: Object = {};
    let maxNodeWidthsByGroup: groupDimensions = {};
    let maxNodeHeightsByGroup: groupDimensions = {};
    let ADMaxNodeHeightsByRow: Object = {};
    let ADMaxNodeWidthsByColumn: Object = {};
    let hasSayNodes: boolean = false;

    // Keeps track of the number of columns within each group
    let maxColByGroupCol: {
      [parentCol: string]: {
        column: number,
        rowSpanOffset: number
      }} = {};

    // Max row of regular diagram
    let maxRow = 0;

    // Keeps track of how many PX total in each row
    let viewRowTotalWidths: {[row: string]: number} = {};
    let viewRowTotalHeights: {[row: string]: number} = {};

    let viewRowWidths: RowDimensions = {};
    let viewRowHeights: RowDimensions = {};

    nodes.forEach(n => {

      const nodeGroupType = getNodeGroupType(n, diagram);

      // If there's an originalWidth, use this instead of the actual width
      let width = n.data.originalWidth ? n.data.originalWidth : n.actualBounds.width;
      let height = n.data.originalHeight ? n.data.originalHeight : n.actualBounds.height;

      // Get "ROOT", "COMPOSITE", and "ATOM" heights and widths
      if (regularNodes.indexOf(n.data.type) !== -1) {
        regularNodeHeights.push(height);
        regularNodeWidths.push(width);
        // +1 since rows start at 0
        if (n.data.row + 1 > maxRow) {
          maxRow = n.data.row + 1;
        }
      }

      // Store max node widths/heights by row/column
      if (regularNodes.indexOf(n.data.type) !== -1) {
        // Add height from first node in row
        if (!maxNodeHeightsByRow[n.data.row]) {
          maxNodeHeightsByRow[n.data.row] = height;
        }
        // Update if larger height is found
        else if (maxNodeHeightsByRow[n.data.row] < height) {
          maxNodeHeightsByRow[n.data.row] = height;
        }

        // Add height from first node in column
        if (!maxNodeWidthsByColumn[n.data.column]) {
          maxNodeWidthsByColumn[n.data.column] = width;
        }
        else if (maxNodeWidthsByColumn[n.data.column] < width) {
          maxNodeWidthsByColumn[n.data.column] = width;
        }
      }

      // Get view node heights and widths
      if (n.data.isView && n.data.type !== "AD_GROUP") {

        const currentWidth = viewRowTotalWidths[n.data.row];
        const currentHeight = viewRowTotalHeights[n.data.row];

        viewRowTotalWidths[n.data.row] = currentWidth ? currentWidth + width + VIEW_MARGIN : width;

        // Replace current row's total height if this node's height is higher than the previous nodes
        if ((currentHeight && (height > currentHeight)) || !currentHeight) {
          // Add margin*2 for both top and bottom margin
          viewRowTotalHeights[n.data.row] = height;
        }

        // Add + 10 to width so views will be padded & not touching
        if (!viewRowWidths[n.data.row]) {
          viewRowWidths[n.data.row] = {};
          viewRowWidths[n.data.row][n.data.column] = width + VIEW_MARGIN;
        } else {
          viewRowWidths[n.data.row][n.data.column] = width + VIEW_MARGIN;
        }

        if (!viewRowHeights[n.data.row]) {
          viewRowHeights[n.data.row] = {};
          viewRowHeights[n.data.row][n.data.column] = height ;
        } else {
          viewRowHeights[n.data.row][n.data.column] = height ;
        }

      }

      // Get AD node dimensions
      if (nodeGroupType === "AD_GROUP") {

        let parentNode = diagram.findNodeForKey(n.data.group);

        // Get max sysml width for each group
        let currentMaxWidth = maxNodeWidthsByGroup[n.data.group] ? maxNodeWidthsByGroup[n.data.group] : 0;

        if (n.data.type === "AD_BAR") {
          width = 100;
        }

        maxNodeWidthsByGroup[n.data.group] = width > currentMaxWidth ? width : currentMaxWidth;

        // Get max sysml height for each group
        let currentMaxHeight = maxNodeHeightsByGroup[n.data.group] ? maxNodeHeightsByGroup[n.data.group] : 0;
        maxNodeHeightsByGroup[n.data.group] = height > currentMaxHeight ? height : currentMaxHeight;

        if (!maxColByGroupCol[parentNode.data.column]) {
          maxColByGroupCol[parentNode.data.column] = {rowSpanOffset: 0, column: 0};
          maxColByGroupCol[parentNode.data.column ].rowSpanOffset = 0;
          maxColByGroupCol[parentNode.data.column].column = n.data.column;
        }

        if (maxColByGroupCol[parentNode.data.column].column < n.data.column) {
          maxColByGroupCol[parentNode.data.column].column = n.data.column;
        }

        // Add extra PX to column before and after in case nodes span rows
        if (n.data.spansRows) {
          if (maxColByGroupCol[parentNode.data.column - 1]) {
            maxColByGroupCol[parentNode.data.column - 1].rowSpanOffset += 25;
          }
        }

        // Store max AD heights (sorted by row)
        if (!ADMaxNodeHeightsByRow[n.data.row]) {
          ADMaxNodeHeightsByRow[n.data.row] = height;
        }
        // Update if larger height is found
        else if (ADMaxNodeHeightsByRow[n.data.row] < height) {
          ADMaxNodeHeightsByRow[n.data.row] = height;
        }

        // Store max AD widths (sorted by column)
        if (!ADMaxNodeWidthsByColumn[n.data.column]) {
          ADMaxNodeWidthsByColumn[n.data.column] = width;
        }
        // Update if larger height is found
        else if (ADMaxNodeWidthsByColumn[n.data.column] < width) {
          ADMaxNodeWidthsByColumn[n.data.column] = width;
        }
      }

      // Needed to tell Swim Lanes layout whether to add more space to the left so swim lanes table
      // and SAY nodes have spacing between
      if (n.data.type === "SAY") {
        hasSayNodes = true;
      }

    });

    // Loop through every node once, to determine the highest column, and max node width & height
    let maxNodeWidth = regularNodeWidths.reduce((a, b) => a > b ? a : b, 0);
    let maxNodeHeight = regularNodeHeights.reduce((a, b) => a > b ? a : b, 0);

    // The max view row width to be added to all regular nodes so they can be positioned next to views
    const maxViewRowWidth = Object.values(viewRowTotalWidths).reduce((a, b) => a > b ? a : b, 0) + (VIEW_MARGIN * 2);
    const maxViewRowHeight = Object.values(viewRowTotalHeights).reduce((a, b) => a > b ? a : b, 0);
    let maxADRowWidth = 0;

    if (trace) {
      // update graph
      diagram.startTransaction(`${trace.guid}`);

      nodes.forEach(node => {
        const groupName = getNodeGroupType(node, diagram);
        let width = node.data.originalWidth ? node.data.originalWidth : node.actualBounds.width;
        let height = node.data.originalHeight ? node.data.originalHeight : node.actualBounds.height;

        // Position Activity Diagram groups
        if (node.data.group && groupName === "AD_GROUP") {
          let parentGroup = diagram.findNodeForKey(node.data.group);
          let maxSysmlWidthThisGroup = maxNodeWidthsByGroup[node.data.group];
          let maxSysmlHeightThisGroup = maxNodeHeightsByGroup[node.data.group];
        
          let { x, y } = positionActivityDiagramNodes({
            node,
            width,
            parentGroup,
            maxSysmlWidthThisGroup: maxSysmlWidthThisGroup + (VIEW_MARGIN * 2),
            maxSysmlHeightThisGroup,
            viewRowHeights,
            maxColByGroupCol,
            horizontal,
            horizontalOffsetTop: horizontal ? maxRow * (maxNodeHeight) : null,
            ADMaxNodeHeightsByRow,
            ADMaxNodeWidthsByColumn
          });

          if (x + width > maxADRowWidth) {
            maxADRowWidth = x + width;
          }

          diagram.model.setDataProperty(node, "position", new go.Point(x, y));
        }

        // Set original widths for all views
        if ((node.data.isView || node.data.category === "GRAPH_GROUP") && !node.data.originalWidth) {
            diagram.model.setDataProperty(node.data, "originalWidth", width);
            diagram.model.setDataProperty(node.data, "originalHeight", height);
        }

        // Reset width & height if there is an original width and height
        if (
          typeof node.data.originalWidth === "number" &&
          typeof node.data.originalHeight === "number" &&
          !node.data.isGroup
          ) {
          diagram.model.setDataProperty(node, "width", node.data.originalWidth);
          diagram.model.setDataProperty(node, "height", node.data.originalHeight);
        }

        // Position views (BAR_CHART, TABLE, GANTT_CHART)
        if ((node.data.isView && !node.data.isGroup)) {
          let { x, y } = positionView(node, viewRowHeights, viewRowWidths);
          diagram.model.setDataProperty(node, "position", new go.Point(x, horizontal ? y + (maxRow * (maxNodeHeight + 17))  : y));
        }

        // Position graphs
        if (node.data.category === "GRAPH_GROUP") {
          let { x, y } = positionView(node, viewRowHeights, viewRowWidths);
          // Moves the group and all it's member parts together
          node.move(new go.Point(x, horizontal ? y + (maxRow * (maxNodeHeight + 17)) : y));
        }

        // Return graph nodes to original x and y if they have those values
        if (
            node.data.category.includes("GRAPH") &&
            node.data.category !== "GRAPH_GROUP" &&
            typeof node.data.originalX === "number" &&
            typeof node.data.originalY === "number"
          ) {
            diagram.model.setDataProperty(node, "location", new go.Point(
              node.data.originalX,
              horizontal ? node.data.originalY + (maxRow * (maxNodeHeight + 17)) : node.data.originalY
            ));
        }

      });

      diagram.commitTransaction(`${trace.guid}`);

    }

  return {
    maxNodeWidth,
    maxNodeHeight,
    maxViewRowWidth: maxViewRowWidth >= maxADRowWidth ? maxViewRowWidth : maxADRowWidth + 30,
    maxViewRowHeight,
    viewRowWidths,
    viewRowHeights,
    maxNodeWidthsByGroup,
    maxNodeHeightsByGroup,
    maxNodeHeightsByRow,
    maxNodeWidthsByColumn,
    hasSayNodes
  };
};