import { merge } from "lodash";

import { SET_PAGES, CHANGE_CURRENT_PAGE } from "../../actions/pagination.types";

export let reducer = (state: {
  pages: object,
  currentPage: number,
  totalPages: number,
  tracesPerPage: number
} = {
  pages: {},
  currentPage: 111,
  totalPages: 0,
  tracesPerPage: 20
}, action) => {
  switch (action.type) {
    case SET_PAGES: {
      let nextState = merge({}, state);
      nextState.pages = action.pages;
      nextState.totalPages = action.totalPages;
      return nextState;
    }
    case CHANGE_CURRENT_PAGE: {
      let nextState = merge({}, state);
      nextState.currentPage = action.nextPage;
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
