import * as React from "react";
import { closeDialog } from "../actions/dialog";
import reduxDialog from ".";
import { connect } from "react-redux";

import { importMP, importWNG, importJSON } from "../actions/import";
import { showGraph } from "../actions/graph";
import ExampleDropdown from "./exampleDropdown";

export let name = "dialogImport";
const validExtensions = [".mp", ".wng", ".json"];

export class Import extends React.Component<any, any> {

  state = {
    mpExamples: []
  };

  _fileInputEl: HTMLInputElement | null = null;
  _exampleSelectEl: HTMLSelectElement | null = null;

  constructor(params) {
    super(params);
    this.importMP = this.importMP.bind(this);
    this.importWNG = this.importWNG.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen && !prevProps.isOpen) {
      (this._fileInputEl as HTMLInputElement).value = "";
    }
  }

  async importMP({ source, title }) {
    const { dispatch } = this.props;
    await dispatch(importMP({ source, title }));
  }

  async importWNG({ state }) {
    const { dispatch, hidingGraph } = this.props;
    await dispatch(importWNG({ state }));
    if (hidingGraph) {
      await dispatch(showGraph());
    }
  }

  async importJSON({ json }) {
    debugger
    const { dispatch, hidingGraph } = this.props;
    await dispatch(importJSON({ json }));
    if (hidingGraph) {
      await dispatch(showGraph());
    }
  }

  render() {
    let { dispatch, user } = this.props;
    let i = 0;
    return (
      <div>
        <div className="dialog-header">
          <h1>Import</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({ name }))}></span>
        </div>
        {/* If logged in, prompt the user to go to the dashboard page to import a project*/}
        <div className="dialog-body" style={{ display: user === null ? "" : "none" }}>
          <ExampleDropdown creatingProject={false} dialogName={ name }/>
          <div className="importContainer">
            Or
          </div>
          <div className="importContainer">
            <label>Load .mp, .wng, or .json file:</label>
            <input type="file" className="import-upload-file" accept={validExtensions.join(",")} ref={e => this._fileInputEl = e} onChange={async e => {
              e.preventDefault();
              const file = e.target.files[0];
              const extension = `.${file.name.split(".").pop().toLowerCase()}`;
              if (!validExtensions.includes(extension)) {
                // this shouldn't be possible, but some browsers...
                // TODO: let user know they selected a bad filetype and try again
                console.log("error wrong file type - extension=", extension);
                return;
              }
              const title = file.name.replace(/\.+$/g, "");
              const source = await new Promise<string>(resolve => {
                const reader = new FileReader();
                reader.onload = (u) => resolve(reader.result);
                reader.readAsText(file);
              });
              if (extension === ".mp") {
                await this.importMP({ source, title });
              } else if (extension === ".wng") {
                await this.importWNG({ state: JSON.parse(source) });
              } else if (extension === ".json") {
                await this.importJSON({ json: JSON.parse(source) });
              }
              await dispatch(closeDialog({ name }));
            }} />
          </div>
        </div>
        <div className="dialog-body" style={{ display: user !== null ? "" : "none" }}>
          <div className="importContainer">
            <p>Visit the <a href="#" onClick={async () => {
              await dispatch(closeDialog({ name }));
              window.location.href = "/";
            }}>Projects</a> page to import a new or example project.</p>
          </div>
        </div>
      </div>
    );
  }
};

let ReduxImport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog",
  startStyle: (dialogStyleEvent) => {
    let { dialogComponent, nodes: { container } } = dialogStyleEvent;
    let { target } = dialogComponent.props;
    return {
      left: `${target.getBoundingClientRect().left}px`,
      top: `${0 - container.offsetHeight - 100}px`,
      transition: `left 250ms, top 250ms`,
      transitionTimingFunction: `ease`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let { dialogComponent } = dialogStyleEvent;
    let { target } = dialogComponent.props;
    return {
      left: `${target.getBoundingClientRect().left}px`,
      top: `100px`,
      transition: `left 250ms, top 250ms`,
      transitionTimingFunction: `ease`,
    };
  },
})(Import);

let mapStateToProps = (state, ownProps) => {
  const { user, graph: { hidingGraph } } = state;
  return {
    user,
    hidingGraph
  };
};

export default connect(mapStateToProps)(ReduxImport);
