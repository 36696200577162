import * as React from "react";
import { closeDialog } from "../actions/dialog";
import reduxDialog from ".";
import { connect } from "react-redux";
import { saveAs } from "file-saver";

import { exportStart, resetExport } from "../actions/batchExport";
import { exportWng } from "../actions/helpers/export";

export let name = "dialogExport";

export class Export extends React.Component<any, any> {

  noTracesMessage = "There are no traces to export.";
  noMarkedTracesMessage = "There are no marked traces in this file.";
  noSelectedPartsMessage = "You don't have any selected parts.";

  state = {
    fileName: `MontereyPhoenixSchema`,
    loadingAll: false,
    loadingMarked: false,
    errorMessage: null,
  };

  constructor(params) {
    super(params);
    this.exportCode = this.exportCode.bind(this);
    this.exportTrace = this.exportTrace.bind(this);
    this.startBatchExport = this.startBatchExport.bind(this);
    this.selectMemberParts = this.selectMemberParts.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.isOpen && !this.props.isOpen) {

      // Get schame name instead of file name
      const regex = /\s*SCHEMA\s+([A-Za-z0-9_-]*)/gm;
      const result = regex.exec(nextProps.projectSource);
      // If no schema name, use title
      const schemaName = result ? result[1] : nextProps.projectName;

      this.setState({
        fileName: schemaName,
        errorMessage: null
      });
    }
  }

  removeErrors() {
    if (this.state.errorMessage) {
      this.setState({errorMessage: false});
    }
  }

  exportCode() {
    this.removeErrors();
    const { projectSource } = this.props;
    const { fileName } = this.state;
    const contents = projectSource;
    const contentsBlob = new Blob([contents], { type: "text/plain;charset=utf-8" });
    saveAs(contentsBlob, `${fileName}.mp`);
  }

  async exportTrace({selectedPartsOnly}) {
    const { fileName } = this.state;
    const { traceNumber, goJSDiagram, scope } = this.props;

    if (this.props.hidingGraph) {
      this.setState({errorMessage: this.noTracesMessage})
      return;
    }

    this.removeErrors();
    const selectionCount = goJSDiagram.selection.count;

    // If exporting selected parts only, check for groups & select the memebers
    if (selectedPartsOnly) {
      if (selectionCount > 0) {
        await this.selectMemberParts(goJSDiagram);
      }
      else {
        this.setState({errorMessage: this.noSelectedPartsMessage});
        return;
      }
    }

    const blob = await new Promise<Blob>(resolve => {
      goJSDiagram.makeImageData({
        scale: 2,
        type: "image/png",
        /** we don't want to limit sizes, especially as this is client side */
        maxSize: new go.Size(100000, 100000),
        returnType: "blob",
        callback: resolve,
        parts: selectedPartsOnly && selectionCount > 0 ? goJSDiagram.selection : undefined
      });
    });

    saveAs(blob, `${fileName}_scope_${scope}_trace_${traceNumber}.png`);
  }

  startBatchExport(options: {markedOnly: boolean}) {
    const { numMarkedTraces, hidingGraph } = this.props;
    this.removeErrors();

    // If hiding graph diagrams can't be exported
    if (hidingGraph) {
      this.setState({errorMessage: this.noTracesMessage})
      return;
    }

    // If they try exporting marked only where there are none:
    if (options.markedOnly && numMarkedTraces === 0) {
      this.setState({errorMessage: this.noMarkedTracesMessage});
      return;
    }

    this.props.dispatch(exportStart({markedOnly: options.markedOnly}));
    this.setState(options.markedOnly ? {loadingMarked: true} : {loadingAll: true});
  }

  componentDidUpdate(prevProps, prevState) {
    const { exporting, zip, markedOnly, dispatch, scope, currentPage } = this.props;
    const { fileName } = this.state;
    if (prevProps.exporting && !exporting) {
      zip.generateAsync({type: "blob"}).then((blob) => {
        saveAs(blob, `${fileName}_scope_${scope}_${markedOnly ? "marked" : "all"}_traces_page_${currentPage}.zip`);
        this.setState({loadingAll: false, loadingMarked: false});
        dispatch(resetExport());
      });
    }
  }

  // If user is trying to export a selected group, select it's member parts too
  async selectMemberParts(diagram) {
    const iterator = diagram.selection.iterator;
    let memberParts: any = [];

    iterator.reset();
    while (iterator.next()) {
      if (iterator.value.data.isGroup) {
        let partsIterator = iterator.value.memberParts;
        partsIterator.reset();
        while (partsIterator.next()) {
          memberParts.push(partsIterator.value);
        }
      }
    }

    diagram.startTransaction(`${this.props.currentTraceGuid}`);
    memberParts.forEach(part => {
      diagram.model.setDataProperty(part, "isSelected", true);
    });
    diagram.commitTransaction(`${this.props.currentTraceGuid}`);
  }

  render() {
    let { dispatch, exportError, state, projectSource, projectName } = this.props;
    const { fileName, loadingAll, loadingMarked, errorMessage } = this.state;
    return (
      <div>
        <div className="dialog-header">
          <h1>Export</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({ name }))}></span>
        </div>
        <div className="dialog-body">
          <div className="exportContainer">
            <span>
              <label>File name:</label>
              <input
                type="text"
                className="image_file_name"
                value={fileName}
                onChange={e => this.setState({fileName: e.target.value})} />
            </span>
            <span>
              <label>Image Size:</label>
              <select className="image_size_select" defaultValue="1">
                <option value="0.5">Small</option>
                <option value={"1"}>Medium</option>
                <option value={"2"}>Large</option>
              </select>
            </span>

            <span className="export_code_button" onClick={e => this.exportCode()}>Export as .mp</span>
            <span className="export_code_and_graph_button" onClick={e => {
              this.removeErrors()
              exportWng(state, projectSource, projectName);
              }}>
                Export as .wng
              </span>
            {exportError ?
              <span className="export-error">There was an error while exporting.</span>
              : null}

          </div>
          <div className="exportContainer">
          <span
            className="save_image_full_button"
            onClick={e => this.exportTrace({selectedPartsOnly: false})}>
              Current Trace
            </span>
            <span
              className="save_image_crop_button"
              onClick={e => this.exportTrace({selectedPartsOnly: true})}>
              Selected Diagrams
            </span>
            <span
              className="save_image_full_button"
              onClick={e => this.startBatchExport({markedOnly: false})}>
              {loadingAll ?
                <span className="exporting-message">
                  Exporting traces
                  <div className="exporting-loader"/>
                </span>
                : "Traces on Current Page (.zip)"
              }
            </span>
            <span
              className="save_image_full_button"
              onClick={e => this.startBatchExport({markedOnly: true})}>
              {loadingMarked ?
                <span className="exporting-message">
                  Exporting marked traces
                  <div className="exporting-loader"/>
                </span>
                : "Marked Traces  on Current Page (.zip)"
              }
            </span>
            {errorMessage ? <span className="export-error">{this.state.errorMessage}</span> : null}
          </div>

        </div>
      </div>
    );
  }
};

let ReduxExport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog",
  startStyle: (dialogStyleEvent) => {
    let { dialogComponent, nodes: { container }} = dialogStyleEvent;
    let { target } = dialogComponent.props;
    return {
      left: `${target.getBoundingClientRect().left}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 250ms, top 250ms`,
      transitionTimingFunction: `ease`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let { dialogComponent, nodes: { container }} = dialogStyleEvent;
    let { target } = dialogComponent.props;
    return {
      left: `${target.getBoundingClientRect().left}px`,
      top: `76px`,
      transition: `left 250ms, top 250ms`,
      transitionTimingFunction: `ease`,
    };
  },
})(Export);

let mapStateToProps = (state, ownProps) => {
  const {
    code: { source, title },
    compiler: { scope },
    graph: { goJSDiagram, hidingGraph },
    entities: { traces },
    currentTraceGuid,
    batchExport: { exporting, zip, markedOnly, error },
    currentProject,
    pagination: { currentPage }
  } = state;

  let traceNumber: any = Object.keys(traces).indexOf(currentTraceGuid) + 1;

  if (traces["global"]) {
    if (currentTraceGuid === "global") {
      traceNumber = "global";
    } else {
      traceNumber = Object.keys(traces).indexOf(currentTraceGuid);
    }
  }

  const numMarkedTraces = Object.keys(traces).filter(t => traces[t].marked).length;
  
  // Use current project data if on a project
  const projectSource = currentProject !== null ? currentProject.code : source;
  const projectName = currentProject !== null ? currentProject.name : title;

  return {
    projectSource,
    projectName,
    state,
    goJSDiagram,
    hidingGraph,
    zip,
    markedOnly,
    traceNumber,
    traces,
    exporting,
    numMarkedTraces,
    exportError: error,
    scope,
    currentProject,
    currentPage
  };
};

export default connect(mapStateToProps)(ReduxExport);
