import * as go from "gojs";
import { Trace } from "../../../../../actions/trace.types";
import { getNodes, getMaxNodeDimensions, regularNodes } from "./helpers/verticalNodePositioning";
import { getCoordinates } from "./helpers/verticalHelpers";

export let generateLayoutSequenceTemplate = () => {

  let trace: Trace = null;

  let SequenceLayout: go.Constructor = function (...args: any[]): Object {
    go.TreeLayout.call(this);
    this.updateModel = updateModel.bind(this);
    return this;
  };
  go.Diagram.inherit(SequenceLayout, go.TreeLayout);

  SequenceLayout.prototype.doLayout = function(coll) {

    let nodes = getNodes(this.diagram);
    let dimensions = getMaxNodeDimensions({nodes, trace, diagram: this.diagram, horizontal: false});
    let { maxNodeWidth, maxNodeHeight, maxViewRowWidth, maxNodeHeightsByRow, maxNodeWidthsByColumn } = dimensions;

    // Position nodes
    if (trace) {
      // update graph
      this.diagram.startTransaction(`${trace.guid}`);

      nodes.forEach(node => {
        // Position non-grouped and non-view nodes (ROOTS, COMPOSITES, etc.)
        if (node) {

          // If it's a regular node:
          if (regularNodes.indexOf(node.data.type) !== -1) {
            let { x, y } = getCoordinates({
              node,
              maxNodeWidth: maxNodeWidth + 25,
              maxNodeHeight: maxNodeHeight,
              maxNodeHeightsByRow,
              maxNodeWidthsByColumn,
              verticalPadding: 50,
              horizontalPadding:  50,
              offsetX: maxViewRowWidth,
              offsetY: 0
            });

            this.diagram.model.setDataProperty(node, "locationSpot", go.Spot.TopLeft);
            this.diagram.model.setDataProperty(node, "location", new go.Point(x, y));
          }
        }
      });
      this.diagram.commitTransaction(`${trace.guid}`);
    }

  }

  let updateModel = (options: { trace: Trace }) => {
    trace = options.trace;
  };

  let layout = new SequenceLayout();

  Object.assign(layout, {
    angle: 90,
    layerSpacing: 80,
    nodeSpacing: 100,
    setsPortSpot: false,
    setsChildPortSpot: false,
    isOngoing: false,
    isInitial: false,
    treeStyle: go.TreeLayout.StyleLayered,
    layerStyle: go.TreeLayout.LayerSiblings,
    alignment: go.TreeLayout.AlignmentCenterChildren,
    arrangement: go.TreeLayout.ArrangementHorizontal,
  });

  return layout;
}
