import * as go from "gojs";

// List of activity diagram link categories
const adLinkCategories = [
  "AD_START", 
  "AD_END", 
  "AD_DECISION", 
  "AD_FOLLOWS", 
  "FOLLOWS_AVOID_NODES", 
  "AD_BAR", 
  "AD_ACTION"
];

// This custom Link class is smart about computing the link point and direction
// at "AD_BAR" nodes.
function BarLink() {
  go.Link.call(this);
}
go.Diagram.inherit(BarLink, go.Link);
/** @override */

BarLink.prototype.computePoints = function() {

  let result = go.Link.prototype.computePoints.call(this);

  if (this.toNode.category.substring(0, 2) !== "AD") {
    return result;
  }

  let link = this;

  // Save to & from points
  const numPoints = this.pointsCount;
  const fromPoint = this.getPoint(0);
  const toPoint = this.getPoint(numPoints - 1);
  const toNode = this.toNode;
  const fromNode = this.fromNode;
  const toNodeWidth = toNode.actualBounds.width;
  const fromNodeWidth = fromNode.actualBounds.width;
  const fromNodeHeight = fromNode.actualBounds.height;
  const toNodeHeight = toNode.actualBounds.height;
  const rowSpan = link.data.rowSpan ? (link.data.rowSpan * 15) : 0;
  const noBarNodes = fromNode.data.category !== "AD_BAR" && toNode.data.category !== "AD_BAR";

  if (link.data.category !== "FOLLOWS_AVOID_NODES") {
    return result;
  }

  // BAR NODES - If the fromNode is above the toNode and is going to a bar node:
  if (toNode.data.category === "AD_BAR" && fromNode.data.row <= toNode.data.row) {

    // Delete all points
    link.clearPoints();

    const barCenter = toNode.actualBounds.x + (toNodeWidth / 2);

    // If fromNode goes too far left of bar
    if ((fromPoint.x - 50) <= toNode.actualBounds.x) {
      this.insertPointAt(0, fromPoint.x, fromPoint.y);
      this.insertPointAt(1, toNode.actualBounds.x, toPoint.y);
    }

    // If fromNode goes too far right of bar
    if ((fromPoint.x + 50) >= (toNode.actualBounds.x + toNodeWidth)) {
      this.insertPointAt(0, fromPoint.x, fromPoint.y);
      this.insertPointAt(1, toNode.actualBounds.x + toNodeWidth, toPoint.y);
    }

    // If fromNode is within bar width
    if ((fromPoint.x - 50) > toNode.actualBounds.x && (fromPoint.x + 50) < (toNode.actualBounds.x + toNodeWidth)) {
      this.insertPointAt(0,
        fromPoint.x > barCenter ? fromPoint.x + (fromNodeWidth / 2) : fromPoint.x - (fromNodeWidth / 2),
        toPoint.y > fromPoint.y ? fromPoint.y - (fromNode.actualBounds.height / 2) : fromPoint.y + (fromNode.actualBounds.height / 2)
      );

     this.insertPointAt(1, fromPoint.x > barCenter ? fromPoint.x + 50 : fromPoint.x - 50, fromPoint.y - (fromNode.actualBounds.height/2));
     this.insertPointAt(2, fromPoint.x > barCenter ? fromPoint.x + 50 : fromPoint.x - 50, toPoint.y);
    }
    // TODO: When node goes below bar node, fix ports
  }

  // Non bar nodes; if node spans rows
  if (rowSpan > 0 && fromNode.data.column === toNode.data.column && noBarNodes) {
    // Delete all points
    link.clearPoints();

    let fromNodeCoords = {x: fromPoint.x - (fromNodeWidth / 2), y: fromPoint.y - (fromNodeHeight / 2)};
    let fromNodeElbowCoords = {x: fromPoint.x - (fromNodeWidth / 2) - rowSpan, y: fromPoint.y - (fromNodeHeight / 2)};
    let toNodeElbowCoords = {x: fromPoint.x - (fromNodeWidth / 2) - rowSpan, y: toPoint.y + (toNodeHeight / 2)};
    let toNodeCoords = {x: toPoint.x - (toNodeWidth / 2), y: toPoint.y + (toNodeHeight / 2)};

    if (fromNode.actualBounds.y >= toNode.actualBounds.y) {
      fromNodeCoords.y = fromPoint.y + (fromNodeHeight / 4);
      fromNodeCoords.x = fromPoint.x - (fromNodeWidth / 4);
      fromNodeElbowCoords.y = fromPoint.y + (fromNodeHeight / 4);
      toNodeElbowCoords.y = toPoint.y - (toNodeHeight / 4);
      toNodeCoords.y = toPoint.y - (toNodeHeight / 4);
      toNodeCoords.x = toPoint.x - (toNodeWidth / 4);
    }

    if (fromPoint.x > toPoint.x) {
      toNodeCoords.x = toPoint.x + (toNodeWidth / 2);
    }

    this.insertPointAt(0, fromNodeCoords.x, fromNodeCoords.y);
    this.insertPointAt(1, fromNodeElbowCoords.x - rowSpan, fromNodeElbowCoords.y);
    this.insertPointAt(2, toNodeElbowCoords.x - rowSpan, toNodeElbowCoords.y );
    this.insertPointAt(3, toNodeCoords.x, toNodeCoords.y);
  }

  // If to/from nodes are not in the same row
  else if (noBarNodes) {
    // Delete all points
    link.clearPoints();

    // If toNode is to the bottom right
    if (fromNode.actualBounds.y < toNode.actualBounds.y && fromNode.actualBounds.x < toNode.actualBounds.x) {
      if (fromNode.data.category === "AD_ACTION") {
        this.insertPointAt(0, fromPoint.x, fromPoint.y);
        this.insertPointAt(1, fromPoint.x, toPoint.y);
        this.insertPointAt(2, toPoint.x - (toNodeWidth / 2), toPoint.y + (toNodeHeight / 2));
      }
      // decision nodes that span rows in different columns
      else if (fromNode.data.category === "AD_DECISION" && rowSpan > 1) {
        this.insertPointAt(0, fromPoint.x + (fromNodeWidth/2), fromPoint.y - (fromNodeHeight/2));
        this.insertPointAt(1, fromPoint.x + rowSpan, fromPoint.y - (fromNodeHeight/2));
        this.insertPointAt(2, fromPoint.x + rowSpan, toPoint.y - (toNodeHeight/2));
        this.insertPointAt(3, toPoint.x - (toNodeWidth/2), toPoint.y + (toNodeHeight/2));
      }
      else {
        this.insertPointAt(0, fromPoint.x + (fromNodeHeight/2), fromPoint.y - (fromNodeHeight / 2));
        this.insertPointAt(1, toPoint.x, fromPoint.y - (fromNodeHeight / 2))
        this.insertPointAt(2, toPoint.x, toPoint.y);
      }
    }

    // If toNode is to the top right
    if (fromNode.actualBounds.y > toNode.actualBounds.y && fromNode.actualBounds.x < toNode.actualBounds.x) {
      if (fromNode.data.category === "AD_ACTION") {
        this.insertPointAt(0, fromPoint.x, fromPoint.y);
        this.insertPointAt(1, fromPoint.x, toPoint.y)
        this.insertPointAt(2, toPoint.x - (toNodeWidth/2), toPoint.y - (toNodeHeight/2));
      }
      // decision nodes that span rows in different columns
      else if (fromNode.data.category === "AD_DECISION" && rowSpan > 1) {
        this.insertPointAt(0, fromPoint.x + (fromNodeWidth/2), fromPoint.y + (fromNodeHeight/2));
        this.insertPointAt(1, fromPoint.x + rowSpan, fromPoint.y + (fromNodeHeight/2));
        this.insertPointAt(2, fromPoint.x + rowSpan, toPoint.y + (toNodeHeight/2));
        this.insertPointAt(3, toPoint.x - (toNodeWidth/2), toPoint.y - (toNodeHeight/2));
      }
      else {
        this.insertPointAt(0, fromPoint.x + (fromNodeHeight/2), fromPoint.y + (fromNodeHeight/2));
        this.insertPointAt(1, toPoint.x, fromPoint.y + (fromNodeHeight/2))
        this.insertPointAt(2, toPoint.x, toPoint.y);
      }
    }

    // If toNode is to the bottom left
    if (fromNode.actualBounds.y < toNode.actualBounds.y && fromNode.actualBounds.x > toNode.actualBounds.x) {
      if (fromNode.data.category === "AD_ACTION") {
        this.insertPointAt(0, fromPoint.x, fromPoint.y);
        this.insertPointAt(1, fromPoint.x, toPoint.y - (toNodeHeight/2))
        this.insertPointAt(2, toPoint.x + (toNodeWidth/2), toPoint.y + (toNodeHeight/2));
      }
      // decision nodes that span rows in different columns
      else if (fromNode.data.category === "AD_DECISION" && rowSpan > 1) {
        this.insertPointAt(0, fromPoint.x - (fromNodeWidth/2), fromPoint.y - (fromNodeHeight/2));
        this.insertPointAt(1, fromPoint.x - rowSpan, fromPoint.y - (fromNodeHeight/2));
        this.insertPointAt(2, fromPoint.x - rowSpan, toPoint.y - (toNodeHeight/2));
        this.insertPointAt(3, toPoint.x + (toNodeWidth/2), toPoint.y + (toNodeHeight/2));
      }
      else {
        this.insertPointAt(0, fromPoint.x - (fromNodeWidth/2), fromPoint.y - (fromNodeHeight/2));
        this.insertPointAt(1, toPoint.x, fromPoint.y - (fromNodeHeight/2));
        this.insertPointAt(2, toPoint.x, toPoint.y);
      }
    }

    // If toNode is to the top left
    if (fromNode.actualBounds.y > toNode.actualBounds.y && fromNode.actualBounds.x > toNode.actualBounds.x) {
      if (toNode.data.category === "AD_ACTION") {
        this.insertPointAt(0, fromPoint.x - (fromNodeWidth/2), fromPoint.y + (fromNodeHeight/2));
        this.insertPointAt(1, toPoint.x, fromPoint.y + (fromNodeHeight/2));
        this.insertPointAt(2, toPoint.x, toPoint.y);
      }
      // decision nodes that span rows in different columns
      else if (fromNode.data.category === "AD_DECISION" && rowSpan > 1) {
        this.insertPointAt(0, fromPoint.x - (fromNodeWidth/2), fromPoint.y + (fromNodeHeight/2));
        this.insertPointAt(1, fromPoint.x - rowSpan, fromPoint.y + (fromNodeHeight/2));
        this.insertPointAt(2, fromPoint.x - rowSpan, toPoint.y + (toNodeHeight/2));
        this.insertPointAt(3, toPoint.x + (toNodeWidth/2), toPoint.y - (toNodeHeight/2));
      }
      else {
        this.insertPointAt(0, fromPoint.x, fromPoint.y);
        this.insertPointAt(1, fromPoint.x, toPoint.y)
        this.insertPointAt(2, toPoint.x + (toNodeWidth/2), toPoint.y - (toNodeHeight/2));
      }
    }

  }

  return result;
};

BarLink.prototype.getLinkPoint = function(node, port, spot, from, ortho, othernode, otherport) {

  if (node.category.substring(0, 2) !== "AD") {
    return go.Link.prototype.getLinkPoint.call(this, node, port, spot, from, ortho, othernode, otherport);
  }

  const r = new go.Rect(port.getDocumentPoint(go.Spot.TopLeft),
                    port.getDocumentPoint(go.Spot.BottomRight));

  const op = otherport.getDocumentPoint(go.Spot.Center);
  const below = op.y > r.centerY;
  let y = below ? r.bottom : r.top;
  let x = r.centerX;
  // For nodes right next to eachother:
  if (op.y === r.centerY) {
    y = r.centerY;
    x = op.x > r.centerX ? x + (node.actualBounds.width / 2) : x - (node.actualBounds.width / 2);
  }
  const link = this;

  if (node.data.category === "AD_BAR") {

    // If going from bar to bar
    // or if a non-avoids node is going to a bar or vice-versa
    if (((othernode.data.category === "AD_BAR" && node.data.barWidth > othernode.data.barWidth) ||
      othernode.data.category !== "AD_BAR") && link.data.type !== "FOLLOWS_AVOIDS_NODES"
    ) {
      let offset = 0;
      if (op.x < r.left) return new go.Point(r.left, y);
      if (op.x > r.right) return new go.Point(r.right, y);
      return new go.Point(op.x + offset, y);
    }
  }

  return new go.Point(x, y);

};
/** @override */
BarLink.prototype.getLinkDirection = function(node, port, linkpoint, spot, from, ortho, othernode, otherport) {
  let p = port.getDocumentPoint(go.Spot.Center);
  let op = otherport.getDocumentPoint(go.Spot.Center);
  let below = op.y > p.y;
  return below ? 90 : 270;
};
// end BarLink class


let generateLinkTemplate = (options: {
  fill: string,
  strokeDashArray?: number[],
  text?: boolean,
  avoidNodes?: boolean,
  hasArrowheads?: boolean
}) => {
  let $ = go.GraphObject.make;
  let {
    fill,
    strokeDashArray = [],
    text = false,
    hasArrowheads = true
  } = options;

  return $(BarLink, {
      reshapable: true,
      relinkableFrom: false,
      relinkableTo: false,
      toShortLength: hasArrowheads ? 3 : 0,
      textEditable: false,
      routing: go.Link.Normal,
    },
    new go.Binding("opacity", "", (data, node) => {
      const { hidden, showHidden, category } = data;
      const isAdLink = adLinkCategories.indexOf(category) !== -1;

      // Only update links for non-activity diagram links
      if (!isAdLink && showHidden && hidden) {
        return 1;
      }

      else return 1;

    }),
    new go.Binding("visible", "", (data, node) => {
      const { hidden, showHidden, layout, category } = data;
      const isAdLink = adLinkCategories.indexOf(category) !== -1;
      
      if (layout === "SYSML" && hidden && !showHidden && !isAdLink) {
        return false;
      }

      else return (!(!showHidden && hidden) || isAdLink);

    }),
    new go.Binding("curviness", "", (data, node) => {
      const { curviness, text } = data;
      return text ? 8 : curviness;
    }),
    $(go.Shape, 
      { strokeWidth: 1.5, strokeDashArray },
      new go.Binding("stroke", "", (data) => {
        if (data.darkMode) {
          return "#b7b7b7";
        }
        else {
          return fill;
        }
      }),
    ),
    hasArrowheads ? $(go.Shape, 
      { toArrow: "standard", stroke: null },
      new go.Binding("fill", "", (data) => {
        if (data.darkMode) {
          return "#b7b7b7";
        }
        else {
          return fill;
        }
      }),
    ) : [],
    text ? [
      $(go.Panel, "Auto",
      $(go.Shape,  // the label background, which becomes transparent around the edges
        { stroke: null },
        new go.Binding("fill", "", (data) => {
          if (data.darkMode) {
            return null;
          }
          else {
            return $(go.Brush, "Radial",
            { 0: "rgb(255, 255, 255)", 0.3: "rgb(255, 255, 255)", 1: "rgba(255, 255, 255, 0)" })
          }
        }),
      ),

      $(go.TextBlock,  // the label text
        {
          textAlign: "center",
          font: "9pt helvetica, arial, sans-serif",
          margin: 4,
          stroke: "white",
          opacity: 1,
          editable: true  // enable in-place editing
        },
        new go.Binding("stroke", "", (data) => {
          if (data.darkMode) {
            return "white";
          }
          else {
            return fill;
          }
        }),
        // editing the text automatically updates the model data
        new go.Binding("text").makeTwoWay())
    )] : [],
  );
};

export let linkInTemplate = generateLinkTemplate({ fill: "#4d4d4d", strokeDashArray: [5, 5] });
export let linkInTextTemplate = generateLinkTemplate({ fill: "#4d4d4d", strokeDashArray: [5, 5], text: true });
export let linkFollowsTemplate = generateLinkTemplate({ fill: "#000000" });
export let linkFollowsTextTemplate = generateLinkTemplate({ fill: "#000000", text: true });
export let linkNamedTemplate = generateLinkTemplate({ fill: "#0000ff" });
export let linkNamedTextTemplate = generateLinkTemplate({ fill: "#0000ff", text: true });
export let linkFollowsAvoidNodesTemplate = generateLinkTemplate({ fill: "#000000", avoidNodes: true });
export let linkLineTextTemplate = generateLinkTemplate({ fill: "#000000", text: true, hasArrowheads: false });