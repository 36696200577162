import * as React from "react";
import { closeDialog } from "../actions/dialog";
import reduxDialog from ".";
import { connect } from "react-redux";

export let name = "dialogAbout";

export class Export extends React.Component<any, any> {

  state = {};

  constructor(params) {
    super(params);
  }

  render() {
    let { dispatch } = this.props;
    return (
      <div>
        <div className="dialog-header">
          <h1>About</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({ name }))}></span>
        </div>
        <div className="dialog-body">
          <div className="about-view-container">
            <h2>About</h2>
            <p>The Monterey Phoenix language and editor were developed at the <a href="http://nps.edu" target="_blank">Naval
              Postgraduate School</a>.</p>
            <p> For more information about Monterey Phoenix visit the <a
              href="https://wiki.nps.edu/display/MP" target="_blank">Monterey Phoenix website</a>, where you can also download the language's manual.</p>
            <p>Current release version: 4</p>
            <h2>Credits:</h2>
            <ul>
              <li><strong>Mikhail Auguston, Ph.D.</strong>
                <br /> Associate Professor Department of Computer Science, Naval Postgraduate School
                <br /><a href="http://faculty.nps.edu/maugusto/">Faculty Website</a>
              </li>
              <br />
              <li><strong>Kristin Giammarco, Ph.D. </strong>
                <br /> Associate Professor Department of Systems Engineering, Naval Postgraduate School
                <br /> <a href="http://faculty.nps.edu/vitae/cgi-bin/vita.cgi?p=display_vita&id=1234538936">Faculty
                  Website</a>
              </li>
              <br />
              <li>Developed by the Naval Postgraduate School <a href="https://nps.edu/web/extended-campus" target="_blank">Extended Campus</a>.</li>
              <br />
                <li>Current Team:</li>
                <ul>
                  <li>Leah Frye, Developer</li>
                  <li>Adrian Martinez, Developer</li>
                  <li>Mike Northcutt, Designer</li>
                </ul>
                <br />
                <li>Past Members:</li>
                <ul>
                  <li>Denis Shatilov, Developer</li>
                  <li>Philip McCullick, Developer/Manager</li>
                  <li>Michael Nigh, Developer</li>
                  <li>Noah Lloyd-Edelman, Developer</li>
                  <li>Richard von Buelow, Developer</li>
                </ul>
            </ul>

            <div className="credits-logos">

              <a href="https://www.npsfoundation.org/" title="The NPS Foundation" target="_blank">
                <img src="/images/nps_foundation.png" className="nps-foundation-logo" />
              </a>

              <a href="https://my.nps.edu/web/naval-research-program/" title="Naval Research Program" target="_blank">
                <img src="/images/NPS_NRP_Logo.png" className="nps-nrp-logo" />
              </a>

              <a href="https://www.marcorsyscom.marines.mil/" title="Marine Corps Systems Command" target="_blank">
                <img src="/images/MCSC.jpg" className="mcsc-logo" />
              </a>

              <a href="https://www.sercuarc.org/" title="Systems Engineering Research Center" target="_blank">
                <img src="/images/SERC_Logo_stacked.jpg" className="serc-logo" />
              </a>

              <a href="https://cruser.nps.edu"
                title="Consortium for Robotics and Unmanned Systems Education and Research" target="_blank">
                <img src="/images/CRUSER_v4.1_noWhite_subText.jpg" className="cruser-logo" />
              </a>

              <a href="https://www.navair.navy.mil/"
                title="U.S. Navy Naval Air Systems Command - Navy and Marine Corps Aviation Research, Development, Acquisition, Test and Evaluation"
                target="_blank">
                <img src="/images/navair_logo.png" className="navair-logo" />
              </a>

              <a href="https://www.cnic.navy.mil/regions/cnrsw/installations/naws_china_lake.html"
                title="Naval Air Weapons Station China Lake" target="_blank">
                <img src="/images/NAWS_CL.jpeg" className="naws-cl-logo" />
              </a>

              <a href="https://www.navair.navy.mil/nawcwd/command/Navy.aspx"
                title="Naval Air Warfare Center Weapons Division" target="_blank">
                <img src="/images/NAWC_color.jpg" className="nawc-logo" />
              </a>

              <a href="https://www.onr.navy.mil/"
                title="Office of Naval Research" target="_blank">
                <img src="/images/ONR.png" className="ONR-logo" />
              </a>

              <a href="https://www.nps.edu" title="Naval Postgraduate School" target="_blank">
                <img src="/images/nps_logo_hz.jpg" className="nps-logo" />
              </a>

              <a
                title="Graduate Education Advancement Center" target="_blank">
                <img src="/images/NEC_logo.png" className="geac-logo" />
              </a>

              <a href="https://www.nsa.gov/resources/students-educators/centers-academic-excellence/"
                title="Idaho National Laboratory (INL)" target="_blank">
                <img src="/images/INL_logo.png" className="idl-logo" />
              </a>

              <a href="https://www.nsa.gov/resources/students-educators/centers-academic-excellence/"
                title="National Security Agency (NSA)" target="_blank">
                <img src="/images/NSA_logo.png" className="nsa-logo" />
              </a>

              <a
                title="National Cryptologic School (NCS) " target="_blank">
                <img src="/images/NCS_logo.png" className="ncs-logo" />
              </a>

              <a href="https://www.nsa.gov/resources/students-educators/centers-academic-excellence/"
                title="Idaho National Laboratory (INL)" target="_blank">
                <img src="/images/INL_logo.png" className="idl-logo" />
              </a>

              <a href="https://www.nsa.gov/resources/students-educators/centers-academic-excellence/"
                title="National Security Agency (NSA)" target="_blank">
                <img src="/images/NSA_logo.png" className="nsa-logo" />
              </a>

              <a
                title="National Cryptologic School (NCS) " target="_blank">
                <img src="/images/NCS_logo.png" className="ncs-logo" />
              </a>
            </div>

            <h2>Libraries</h2>
            <ul>
              <li> Graphing library built using <a href="https://gojs.net/latest/index.html">GoJS</a>.</li>
            </ul>
            <br />
            <p style={{ fontStyle: 'italic' }}>Material contained herein is made available for the purpose of peer review
              and discussion and does not necessarily reflect the views of the Department of the Navy or the Department
              of Defense.</p>
            <div className="last-updated">Last updated 2/09/2021</div>
          </div>
        </div>
      </div>
    );
  }
};

let ReduxExport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog",
  startStyle: (dialogStyleEvent) => {
    let { nodes: { container } } = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let { nodes: { container } } = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `76px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
})(Export);

let mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(ReduxExport);
