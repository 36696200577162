import { orderBy } from "lodash";

// Takes a node and measurements; returns final x,y coordinates
export const getCoordinates = (options: {
  node: any,
  maxNodeWidth?: number,
  maxNodeHeight?: number,
  maxNodeHeightsByRow?: Object, // List of max node heights sorted by row
  maxNodeWidthsByColumn?: Object,
  verticalPadding:  number, // Vertical padding between nodes
  horizontalPadding: number, // Horizontal padding between nodes
  offsetX: number, // For pushing entire graphs/views to the side to make room for others
  offsetY: number // For pushing entire graphs/views downward to make room for others

}) => {
  const {
    node,
    maxNodeWidth,
    maxNodeHeight,
    maxNodeHeightsByRow,
    maxNodeWidthsByColumn,
    verticalPadding,
    horizontalPadding,
    offsetX,
    offsetY
  } = options;
  
  let x = 0;
  let y = 0;

  // Set coordinates for horizontal view
  if (!maxNodeHeightsByRow || !maxNodeWidthsByColumn) {
    x = (node.data.column * (horizontalPadding + maxNodeWidth));
    y = (node.data.row * (verticalPadding + maxNodeHeight));
  }

  // Get Y coordinate
  if (maxNodeHeightsByRow) {

    Object.keys(maxNodeHeightsByRow).forEach((row) => {
      if (row < node.data.row) {
        y += maxNodeHeightsByRow[row] + verticalPadding;
      }
  
      // Keeps nodes centered horizontally
      if (row == node.data.row) {
        const tallestNodeInRowHeight = maxNodeHeightsByRow[node.data.row];
        const thisNodeHeight = node.actualBounds.height;
        // if not the tallest node in row:
        if (tallestNodeInRowHeight !== thisNodeHeight) {
          // add half the tallest node's height to this node (to position nodes in the center of row)
          y += (tallestNodeInRowHeight/2) - (thisNodeHeight/2);
        }
      }
    });
  }

  // Get X coordinates
  if (maxNodeWidthsByColumn) {

    Object.keys(maxNodeWidthsByColumn).forEach((column) => {
      if (column < node.data.column) {
        x += maxNodeWidthsByColumn[column] + horizontalPadding;
      }
  
      // Keeps nodes centered vertically
      if (column == node.data.column) {
        const widestNodeInColumnWidth = maxNodeWidthsByColumn[node.data.column];
        const thisNodeWidth = node.actualBounds.width;
        // if not the tallest node in row:
        if (widestNodeInColumnWidth !== thisNodeWidth) {
          // add half the tallest node's height to this node (to position nodes in the center of row)
          x += (widestNodeInColumnWidth/2) - (thisNodeWidth/2);
        }
      }
    });

  }

  y += offsetY;
  x += offsetX;

  return { x, y };
};

// Returns offsetTop based on number of rows above
const getOffsetTop = (node, viewRowHeights, horizontal) => {
  if (node.data.row > 0) {
    let offsetTop = 0;
    let topPadding = horizontal ? 160 : 40;
    Object.keys(viewRowHeights).forEach((row, i) => {
      if (node.data.row > row) {
        const tallestInRow = orderBy(viewRowHeights[i], ["asc"])[0];
        offsetTop += tallestInRow + topPadding;
      }
    });
    return offsetTop;
  } else {
    return 0;
  }
};

// Returns offsetLeft based on number of columns to the left
const getOffsetLeft = (node, viewRowWidths, horizontal = false) => {
  // set offsetLeft by Widest Column of Nodes, if horizontal is true
  if (horizontal) {
    let offsetLeft = 0;
    Object.values(viewRowWidths).forEach(row => {
      let columnsUpToThisNode = Object.values(row).slice(0, node.data.column);
      if (columnsUpToThisNode.length > 0) {
        offsetLeft += columnsUpToThisNode.reduce((a, b) => a + b);
      }
    });
    return offsetLeft;
  } else {
    let currentRow: {[key: string]: number} = viewRowWidths[node.data.row];
    let columnsUpToThisNode = Object.values(currentRow).slice(0, node.data.column);
    if (columnsUpToThisNode.length > 0) {
      return columnsUpToThisNode.reduce((a, b) => a + b);
    }
    return 0;
  }
};

export const positionView = (node, viewRowHeights, viewRowWidths) => {
  
  let offsetX = getOffsetLeft(node, viewRowWidths);
  let offsetY = getOffsetTop(node, viewRowHeights, false);

  let { x, y } = getCoordinates({
    node,
    maxNodeWidth: 0,
    maxNodeHeight: 0,
    verticalPadding: 0,
    horizontalPadding: 0,
    offsetX,
    offsetY
  });

  return { x, y };
}

export const positionActivityDiagramNodes = (options: {
  node: any,
  width: number,
  parentGroup: any,
  maxSysmlWidthThisGroup: number,
  maxSysmlHeightThisGroup: number,
  viewRowHeights: any,
  maxColByGroupCol: any,
  horizontal: boolean|null|undefined,
  horizontalOffsetTop: number|null,
  ADMaxNodeHeightsByRow: Object,
  ADMaxNodeWidthsByColumn: Object
}) => {

  const {
    node,
    parentGroup,
    maxSysmlWidthThisGroup,
    maxSysmlHeightThisGroup,
    viewRowHeights,
    maxColByGroupCol,
    horizontal,
    horizontalOffsetTop,
    ADMaxNodeHeightsByRow,
    ADMaxNodeWidthsByColumn
  } = options;

  // Get offset widths to help position nodes with different sizes
  let offsetY = getOffsetTop(parentGroup, viewRowHeights, horizontal);
  let offsetX = 0;

  // Add spacing if there are view rows above
  if (parentGroup.data.row > 0) {
    offsetY += 35;
  }

  if (parentGroup.data.column > 0 && 
      (maxColByGroupCol[parentGroup.data.column] ||
      maxColByGroupCol[parentGroup.data.column] === 0)
    ) {

      Object.keys(maxColByGroupCol).forEach(col => {
      // Spacing between activity diagrams
        if (parentGroup.data.column > col) {
          offsetX += (maxColByGroupCol[col].column + 1) * maxSysmlWidthThisGroup + 20 + maxColByGroupCol[col].rowSpanOffset;
        }
    });
  }

  if (horizontalOffsetTop) {
    offsetY += horizontalOffsetTop;
  }

  let { x, y } = getCoordinates({
    node,
    maxNodeWidth: maxSysmlWidthThisGroup,
    maxNodeHeight: maxSysmlHeightThisGroup,
    verticalPadding: 25,
    horizontalPadding: 25,
    offsetY,
    offsetX,
    maxNodeHeightsByRow: ADMaxNodeHeightsByRow,
    maxNodeWidthsByColumn: ADMaxNodeWidthsByColumn
  });

  return { x, y };
};