import { SET_PAGES, CHANGE_CURRENT_PAGE } from "./pagination.types";
import { merge } from "lodash";

export let setPages = (options: {traceGuids: Array<String>}) => {
  return async (dispatch, getState) => {
    let { traceGuids } = options;

    const state = getState();
    const { pagination: { tracesPerPage } } = state;
    let pages = {};
    let totalPages = Math.ceil(traceGuids.length / tracesPerPage);

    // Organize guids into each page
    for (let i = 1; i <= totalPages; i ++) {
      let startIndex = (i - 1) * tracesPerPage;
      // Traces in groups of 20
      if (i * tracesPerPage <= traceGuids.length) {
        pages[i] = traceGuids.slice(startIndex, startIndex + tracesPerPage);
      }
      // Remaining traces (under 20)
      else {
        pages[i] = traceGuids.slice(startIndex, traceGuids.length);
      }
    }

    dispatch(merge({}, {
      type: SET_PAGES,
      pages,
      totalPages
    }, options));
  };
};

export let changeCurrentPage = (options: {nextPage: Number}) => {
  return async (dispatch, getState) => {
    dispatch(merge({}, {
      type: CHANGE_CURRENT_PAGE,
      nextPage: options.nextPage
    }, options));
  };
};

