import * as go from "gojs";

export let generateDiagramTemplate = (options: { goJSElement: HTMLDivElement }) => {
  let $ = go.GraphObject.make;
  let { goJSElement } = options;

  const diagram = $(go.Diagram, goJSElement, {
    initialContentAlignment: go.Spot.Center,
    allowDelete: false,
    "toolManager.mouseWheelBehavior": go.ToolManager.WheelZoom,
    "undoManager.isEnabled": true,
    "toolManager.hoverDelay": 1000
  });
  let panningToolDelay = diagram.toolManager.panningTool.delay;
  diagram.commandHandler.doKeyDown = function () {
    const e = diagram.lastInput;
    if (e.shift) {
      // disable panning on shift down
      diagram.toolManager.panningTool.isEnabled = false;
      panningToolDelay = diagram.toolManager.dragSelectingTool.delay;
      diagram.toolManager.dragSelectingTool.delay = 0;
      return;
    }
    go.CommandHandler.prototype.doKeyDown.call(this);
  };
  diagram.commandHandler.doKeyUp = function () {
    const e = diagram.lastInput;
    if (!e.shift) {
      // re enable panning on shift up
      diagram.toolManager.panningTool.isEnabled = true;
      if (panningToolDelay) {
        diagram.toolManager.dragSelectingTool.delay = panningToolDelay;
      }
      return;
    }
    go.CommandHandler.prototype.doKeyUp.call(this);
  };

  return diagram;
};
