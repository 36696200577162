import * as go from "gojs";
import * as React from "react";
import * as ReactDOM from "react-dom";
let hamburgerIcon = require("../../../../../../sprites/canvasSprites/hamburger_icon.png");
let hamburgerIconHover = require("../../../../../../sprites/canvasSprites/hamburger_icon_hover.png");

export interface GenerateNodeTemplateOptions {
  fill?: string;
  type: "ROOT"|"COMPOSITE"|"ATOM";
  onCollapse: {(guid: string): void};
  onExpandOrCollapseAllOfType: {(type: string, action: string): void};
  findIfAllAreCollapsedOrExpanded: {(type: string, isCollapsed: true): void};
  onHide: {(guid: string): void};
  onShiftClick: {(guid: string): void};
 // layout: "SYSML"|"SEQUENCE"|"HORIZONTAL";
}

const createTooltip = (options: {text: string}) => {
  let $ = go.GraphObject.make;
  return $(go.Adornment, "Auto",
    { isShadowed: true, shadowColor: "rgba(0, 0, 0, 0.3)", shadowOffset: new go.Point(3, 3) },
    $(go.Shape, "RoundedRectangle", { fill: "#3b73af", stroke: null }),
    $(go.TextBlock,
      { margin: new go.Margin(2, 3, 2, 3),
        stroke: "white",
        width: 400,
        textAlign: "center",
        spacingAbove: 2,
        spacingBelow: 2
      },
      options.text),
    new go.Binding("visible", "", (data) => {
      return data.showTooltips;
    }),
  );
};

let generateNodeTemplate = (options: GenerateNodeTemplateOptions) => {
  let $ = go.GraphObject.make;
  let {
    fill,
    type,
    onCollapse,
    onExpandOrCollapseAllOfType,
    findIfAllAreCollapsedOrExpanded,
    onHide,
    onShiftClick,    
  } = options;

  let mouseOver = false;
  const contextMenuTemplate = ContextMenuTemplate({ onCollapse, onExpandOrCollapseAllOfType, findIfAllAreCollapsedOrExpanded, onHide });
  let nodeObj = null;

  const nodeTooltip = type === "ATOM" ? {
    toolTip: createTooltip({text: "Select event to drag/move, ctrl + click to select multiple events. Note: Atoms cannot be collapsed"}),
  } : {
    toolTip: createTooltip({text: "Select event to drag/move, ctrl + click to select multiple events."})
  };

  return $(go.Node, go.Panel.Auto, {
      width: 250,
      layerName: "Foreground",
      mouseEnter: (e, obj, prev) => {
        mouseOver = true;
        nodeObj = obj;
      },
      mouseLeave: (e, obj, prev) => {
        mouseOver = false;
      },
      click: (e, obj, prev) => {

        // Adds event listener to listen for "H" key that will show/hide node
        // and "C" key that will collapse/expand node
        const keyPress = (e) => {
          if (e.key === "h") {
            onHide(obj.part.data.key);
          }
          if (e.key === "c" && !obj.part.isTreeLeaf) {
            onCollapse(obj.part.data.key);
          }
          // Removes event listener when done.
          graph.removeEventListener("keydown", keyPress);
        };

        const graph = document.getElementsByClassName("graph-view")[0];
        graph.removeEventListener("keydown", keyPress);
        graph.addEventListener("keydown", keyPress);

        if (e.shift) {
          onShiftClick(obj.part.data.key);
        }

      },
    },
    new go.Binding("opacity", "", (data, node) => {
      const { hidden, showHidden } = data;
      return showHidden && hidden ? 0.5 : 1;
    }),

    new go.Binding("visible", "", (data, node) => {
      const { hidden, showHidden, layout } = data;
      // Do not allow hidden roots in sysml layout to show when "showing hidden"
      if (layout === "SYSML" && type === "ROOT") {
        return false;
      }
      return !(!showHidden && hidden);
    }),
    new go.Binding("width", "width"),
    new go.Binding("position", "position").makeTwoWay(),
    $(go.Shape, "RoundedRectangle", {
        parameter1: 2.5,
        cursor: "move",
      }, 
      // Set accessibility mode colors depending on node type
      new go.Binding("fill", "", (data) => {
        if (data.grayscaleGraphs) {
          switch(type) {
            case "ROOT": return "black";
            case "COMPOSITE": return "#a8a8a8";
            case "ATOM": return "white";
            default: return fill;
          }
        }
        else {
          return fill;
        }
      }),
      // Set accessibility mode stroke colors depending on node type
      new go.Binding("stroke", "", (data) => {
        if (data.grayscaleGraphs) {
          switch(type) {
            case "ROOT": return "white";
            case "COMPOSITE": return null;
            case "ATOM": return "#a8a8a8";
            default: return null;
          }
        }
        else {
          return null;
        }
      }),  
    ),
    $(go.TextBlock, {
      margin: 2,
      cursor: "move",
      textAlign: "center",
      width: 200,
    }, 
    // Set accessibility mode font colors depending on node type
    new go.Binding("stroke", "", (data) => {
      if (data.grayscaleGraphs) {
        switch(type) {
          case "ROOT": return "white";
          case "COMPOSITE": return "black";
          case "ATOM": return "black";
          default: return "white";
        }
      }
      else {
        return "white";
      }
    }),  
    new go.Binding("text", "name"), new go.Binding("width", "textBlockWidth"),
    new go.Binding("font", "", (data, node) => {
      const { width } = data;
      // If width = 150, then the layout is HORIZONTAL
      // make font-size smaller in Horizontal, to prevent word-wrapping
      return width !== 150 ? "24px arial, sans-serif" : "18px arial, sans-serif";
    }),),
    HamburgerIconTemplate(mouseOver, contextMenuTemplate, nodeObj),
    HamburgerMenuTemplate(),
    { contextMenu: contextMenuTemplate },
    nodeTooltip,
  );
};

const ContextMenuTemplate = (options: {
  onCollapse,
  onExpandOrCollapseAllOfType,
  findIfAllAreCollapsedOrExpanded,
  onHide
}) => {
  let $ = go.GraphObject.make;
  let { onCollapse, onExpandOrCollapseAllOfType, findIfAllAreCollapsedOrExpanded, onHide } = options;
  return $(go.HTMLInfo,
    {
      show: (object: go.GraphObject, diagram: go.Diagram, tool: go.ContextMenuTool) => {
        const { x, y } = diagram.lastInput.viewPoint;
        renderContextMenu({
          visible: true,
          left: (diagram.div.getClientRects()[0]).x + x,
          top: (diagram.div.getClientRects()[0]).y + y,
          onCollapse,
          onExpandOrCollapseAllOfType,
          findIfAllAreCollapsedOrExpanded,
          onHide,
          guid: object.part.data.key,
          data: object.part.data,
        });
      },
      hide: () => {
        renderContextMenu({
          visible: false,
        });
      }
    }
  );
};

class ContextMenu extends React.Component<any, any> {

  render() {
    const { visible } = this.props;
    if (!visible) {
      return null;
    }

    const {
      left,
      top,
      data: { type, collapsed, hidden, hasCollapsedParent },
      onHide,
      onCollapse,
      onExpandOrCollapseAllOfType,
      findIfAllAreCollapsedOrExpanded,
      guid,
    } = this.props;

    const allAreExpanded = findIfAllAreCollapsedOrExpanded(type, false);
    const allAreCollapsed = findIfAllAreCollapsedOrExpanded(type, true);
    return !visible ? null : (
      <ul className={`context-menu`} style={{ left, top }}>
        <li onClick={() => onHide(guid)}>{hidden ? `Show Node` : `Hide Node`}</li>
        {
          // Do not show the following options if parent is collapsed
          !(type === "ROOT" || (type === "COMPOSITE" && !hasCollapsedParent)) ? null :
          <div>
            <li onClick={() => onCollapse(guid)}>{collapsed ? `Expand Event` : `Collapse Event`}</li>
            { !allAreExpanded ?
              <li onClick={() => onExpandOrCollapseAllOfType(type, "expand")}>
                Expand all {type === "ROOT" ? `Roots` : `Composites`}
              </li>
              : null }
            { !allAreCollapsed ?
              <li onClick={() => onExpandOrCollapseAllOfType(type, "collapse")}>
                Collapse all {type === "ROOT" ? `Roots` : `Composites`}
              </li>
            : null }
            </div>
        }
      </ul>
    );
  }
}

const contextMenuElement = document.createElement(`span`);
contextMenuElement.addEventListener("contextmenu", e => {
  e.preventDefault();
  return false;
});

document.body.appendChild(contextMenuElement);
const renderContextMenu = (props) => {
  return ReactDOM.render(<ContextMenu { ...props } />, contextMenuElement);
};

const HamburgerMenuTemplate = () => {
  let $ = go.GraphObject.make;
  const lineShapeOpts = {
    parameter1: 2.5,
    margin: new go.Margin(2, 7, 0, 0),
    height: 15,
    angle: 180,
    stroke: null,
  };

  const getFill = () => "#ffffff";

  return $(go.Panel,
    $(go.Shape, "Circle", {
      ...lineShapeOpts,
      toolTip: createTooltip({text: "Event is currently collapsed. Right-click, or click menu icon on the left to expand."}),
      position: new go.Point(0, 0),
    },
    new go.Binding("fill", "", getFill)),
    {
      alignment: go.Spot.Right,
    },
    new go.Binding("visible", "", (data, obj) => {
      const { collapsed, type } = data;
      return collapsed && (type === "ROOT" || type === "COMPOSITE") ? true : false;
    }),
    new go.Binding("opacity", "", (data, node) => {
      return .7;
    }),
  );
};

const HamburgerIconTemplate = (overNode = false, contextMenuTemplate = null, nodeObj = null) => {
  let $ = go.GraphObject.make;
  return $(go.Panel,
    $(go.Picture, {
      source: hamburgerIcon,
      width: 20,
      height: 15,
      cursor: "pointer",
      margin: new go.Margin(0, 0, 0, 5),
      click: (e, obj, prev) => {
        e.diagram.commandHandler.showContextMenu(obj);
      },
      mouseEnter: (e, obj, prev) => {
        let shape = obj;
        if (shape) {
          shape.source = hamburgerIconHover;
        }
      },
      mouseLeave: (e, obj, prev) => {
        let shape = obj;
        if (shape) {
          shape.source = hamburgerIcon;
        }
      },
    }),
    {
      alignment: go.Spot.Left,
    },
    { contextMenu: contextMenuTemplate },
  );
};

export let nodeRootTemplate = (options: GenerateNodeTemplateOptions) => generateNodeTemplate({ fill: "#257c40", type: "ROOT", ...options });
export let nodeCompositeTemplate = (options: GenerateNodeTemplateOptions) => generateNodeTemplate({ fill: "#ED6203", type: "COMPOSITE", ...options });
export let nodeAtomTemplate = (options: GenerateNodeTemplateOptions) => generateNodeTemplate({ fill: "#3182BD", type: "ATOM", ...options });
