import { DIALOG_OPEN, DIALOG_CLOSE } from "./dialog.types";
import { merge } from "lodash";

export let openDialog = (options: { name: string, props?: {}}) => {
  return async (dispatch, getState) => {
    const { dialogs } = getState();
    
    // Close any other dialogs that might be open
    Object.keys(dialogs).forEach(dialogName => {
      if (dialogName !== options.name && dialogs[dialogName].isOpen) {
        closeDialog({name: dialogName, props: dialogs[dialogName].props});
      }
    });  

    dispatch({ type: DIALOG_OPEN, name: options.name, props: options.props });
  };
};

export let closeDialog = (options: {
  name: string,
  props?: {},
}) => {
  return async (dispatch, getState) => {
    dispatch({type: DIALOG_CLOSE, name: options.name, props: options.props});
  };
};

export let toggleDialog = (options: {
  name: string,
  props?: {},
}) => {
  return async (dispatch, getState) => {
    let state = getState();
    let { name } = options;
    if (state.dialogs[name] && state.dialogs[name].isOpen) {
      dispatch(merge({}, {
        type: DIALOG_CLOSE,
      }, options));
    } else {
      dispatch(merge({}, {
        type: DIALOG_OPEN,
      }, options));
    }
  };
};
