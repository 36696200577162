import { merge } from "lodash";

import * as UI from "./ui.types";

import { saveStateToLocalStorage } from "../store";
import { ViewMode } from "../reducers/ui/index.types";

// Toggles Swim Lane layout
export let updateViewMode = (options: {
  viewMode: ViewMode,
}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: UI.UI_UPDATE_VIEW_MODE,
    }));
    saveStateToLocalStorage(getState());
  };
};

// Toggles visibility of hidden nodes
export let updateShowHidden = (options: {
  showHidden: boolean,
}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: UI.UI_UPDATE_SHOW_HIDDEN,
    }));
    saveStateToLocalStorage(getState());
  };
};

// Toggles split screen editor view
export let updateSplitScreen = (options: {
  splitScreen: boolean,
}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: UI.UI_TOGGLE_SPLIT_SCREEN,
    }));
    saveStateToLocalStorage(getState());
  };
};

// Updates split screen editor view
export let updateSplitScreenSizes = (options: {
  leftEditor: string, rightEditor: string
}) => {
  const { leftEditor, rightEditor } = options;
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: UI.UI_UPDATE_SPLIT_SCREEN_SIZES,
      leftEditor,
      rightEditor
    }));
    saveStateToLocalStorage(getState());
  };
};

// Toggles tooltip visibility
export let toggleTooltips = () => {
  return async (dispatch, getState) => {
    await dispatch({type: UI.UI_UPDATE_SHOW_TOOLTIPS});
    saveStateToLocalStorage(getState());
  };
};

export let updateSplitSizes = (options: {
  splitSizes: object,
}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: UI.UI_UPDATE_SPLIT_SIZES,
    }));
    saveStateToLocalStorage(getState());
  };
};

export let updateConsoleSplitSize = (options: {
  consoleSplit: number,
}) => {
  return async (dispatch, getState) => {
    dispatch(merge({}, options, {
      type: UI.UI_UPDATE_CONSOLE_SPLIT_SIZE,
    }));
    saveStateToLocalStorage(getState());
  };
};

export let toggleExpandConsole = (options: {}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: UI.UI_TOGGLE_EXPAND_CONSOLE,
    }));
    saveStateToLocalStorage(getState());
  };
};

export let toggleExpandGlobal = (options: {}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: UI.UI_TOGGLE_EXPAND_GLOBAL,
    }));
    saveStateToLocalStorage(getState());
  };
};

export let toggleExpandNavigation = (options: {}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: UI.UI_TOGGLE_EXPAND_NAVIGATION,
    }));
    saveStateToLocalStorage(getState());
  };
};

// Toggles find and replace feature in CodeMirror
export let toggleFindAndReplace = () => {
  return async (dispatch, getState) => {
    await dispatch({type: UI.TOGGLE_FIND_AND_REPLACE});
    saveStateToLocalStorage(getState());
  };
};

// Toggles word-wrapping in CodeMirror
export let toggleWordWrap = () => {
  return async (dispatch, getState) => {
    await dispatch({type: UI.TOGGLE_WORD_WRAP});
    saveStateToLocalStorage(getState());
  };
};


// Start the app tour
export let beginTour = () => {
  return async (dispatch, getState) => {
    await dispatch({type: UI.BEGIN_TOUR});
    saveStateToLocalStorage(getState());
  };
};

// End the app tour
export let endTour = () => {
  return async (dispatch, getState) => {
    await dispatch({type: UI.END_TOUR});
    saveStateToLocalStorage(getState());
  };
};

export let toggleDarkMode = () => {
  return async (dispatch, getState) => {
    await dispatch({type: UI.TOGGLE_DARK_MODE});
    saveStateToLocalStorage(getState());
  };
};

export let toggleGrayscaleGraphs = () => {
  return async (dispatch, getState) => {
    await dispatch({type: UI.TOGGLE_GRAYSCALE_GRAPHS});
    saveStateToLocalStorage(getState());
  };
};
