import { merge } from "lodash";

import { SET_USER } from "./user.types";
import { saveStateToLocalStorage } from "../store";
import { resetCurrentProject } from "./currentProject";
import { compilerReset } from "./compiler";
import { resetProjects } from "./projects";

export interface User {
  id: number,
  firstName: string,
  lastName: string
};

// Sets the user to null and resets other info pertaining to user
export let logoutUser = () => {
  return async (dispatch, getState) => {
    
    // Set user to null
    await dispatch(merge({}, {
      type: SET_USER,
      userData: null
    }));

    // Remove current project data
    await dispatch(resetCurrentProject());

    // Remove all other project data
    await dispatch(resetProjects());

    // Reset compiler
    await dispatch(compilerReset());
    
    saveStateToLocalStorage(getState());
    window.location.href = `${process.env.BASE_URL}`;

  };
};