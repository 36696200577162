import * as React from "react";
import ReactTooltip from "react-tooltip";

import ProjectListItem from "./project-list-item";
import CreateProjectButton from "./create-project-button";
import { connect } from "react-redux";
import { getAllProjects } from "../../actions/projects";
import ImportButton from "./import-button";
import { ProjectListHeader } from "./project-list-header";

export class Dashboard extends React.Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      listType: "small"
    };

    this.viewChange = this.viewChange.bind(this);
  }

  async componentDidMount(): Promise<void> {

    const { dispatch } = this.props;
    await dispatch(getAllProjects());

    // Set overflow to auto when on dashboard
    document.getElementsByTagName("body")[0].style.overflow = "auto";

        // Make react tooltips work for this component
        setTimeout(ReactTooltip.rebuild, 0);
  }

  getProjectsByCreationDate() {
    const { projects } = this.props;
    if (!projects || Object.keys(projects).length === 0) {
      return false;
    }
    return Object.keys(projects).map(p => projects[p]).sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  }

  viewChange() {
    const { listType } = this.state;
    if (listType === "large") {
      this.setState({listType: "small"});
    } else {
      this.setState({listType: "large"});
    }
  }

  componentWillUnmount() {
    // Set overflow back to hidden when leaving dashboard
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  }

  render() {
    const { listType } = this.state;
    const projectsByCreationDate = this.getProjectsByCreationDate();

    return (
      <div className="dashboard projects-view">

        <div id="create-import-buttons">
          <CreateProjectButton/>
          <ImportButton/>
        </div>
        
        <div style={{
          marginLeft: "20px",
          fontSize: "16px",
          marginBottom: "20px"
        }}>
          <b className="bold">Add feedback/suggestions</b>: <a href="https://forms.office.com/r/7CqjLgDmjN" target="_blank">https://forms.office.com/r/7CqjLgDmjN</a>
          <br/> <br/>
          <b className="bold">Report a bug</b>: <a href="https://forms.office.com/r/cwkFHXVM19" target="_blank">https://forms.office.com/r/cwkFHXVM19</a>
        </div>

        <div className="display-buttons">
          <span style={{paddingRight: "10px"}}>View: </span>
          <button
            disabled={listType === "large" ? true : false}
            className={listType === "large" ? "selected" : null}
            data-place="left"
            data-tip="Gallery View"
            onClick={this.viewChange}
          >
            <i className="icon fas fa-bars"/>
          </button>
          <button
            disabled={listType === "small" ? true : false}
            className={listType === "small" ? "selected" : null}
            data-place="right"
            data-tip="List View"
            onClick={this.viewChange}
          >
            <i className="icon fas fa-list"/>
          </button>
        </div>
        <div id="project-list" style={{marginTop: "80px"}}>

          {
            /* display list header if list view selected */
            listType === "small" ?
              <ProjectListHeader ></ProjectListHeader>
              : null
          }

          { projectsByCreationDate ?
            projectsByCreationDate.map((project) => { 
              return (
                <ProjectListItem className="project" key={project.id} projectItem={project} listType={listType}/>
              ); 
            }) : <div className="no-projects">You do not have any projects yet.</div>
          }
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  const { projects, user } = state;
  return {
    projects,
    user
  };
};

export default connect(mapStateToProps)(Dashboard);
