import * as go from "gojs";
import { Trace } from "../../../../../actions/trace.types";
import { Node } from "../../../../../actions/node.types";
import { Link } from "../../../../../actions/link.types";
import { getCoordinates } from "./helpers/verticalHelpers";
import { regularNodes, getNodes,getMaxNodeDimensions } from "./helpers/verticalNodePositioning";

export type HorizontalTrace = Trace & {
  nodes: HorizontalNode[];
  links: HorizontalLink[];
};

export type HorizontalNode = Node & {
  parents: HorizontalNode[];
  inParents: HorizontalNode[];
  children: HorizontalNode[];
  branchChildren: HorizontalNode[];
  subBranches: HorizontalNode[][];
  pathsToRoot: HorizontalNode[][];
};

export type TableNode = go.Node & {
  column: number,
  row: number
};

export type HorizontalLink = Link & {
  source: HorizontalNode;
  target: HorizontalNode;
};

export let generateLayoutHorizontalTemplate = () => {

  let trace: Trace = null;

  let HorizontalLayout: go.Constructor = function (...args: any[]): Object {
    go.TreeLayout.call(this);
    this.updateModel = updateModel.bind(this);
    return this;
  };
  go.Diagram.inherit(HorizontalLayout, go.TreeLayout);

  HorizontalLayout.prototype.doLayout = function(coll) {

    let nodes = getNodes(this.diagram);
    let dimensions = getMaxNodeDimensions({nodes, trace, diagram: this.diagram, horizontal: true});
    let { maxNodeWidth, maxNodeHeight, maxViewRowHeight, maxViewRowWidth } = dimensions;

    if (trace) {
      // update graph
      this.diagram.startTransaction(`${trace.guid}`);
      nodes.forEach(node => {
        // Position non-grouped and non-view nodes (ROOTS, COMPOSITES, etc.)
        if (regularNodes.indexOf(node.category) !== -1) {

          let { x, y } = getCoordinates({
            node,
            maxNodeWidth: maxNodeWidth,
            maxNodeHeight: maxNodeHeight,
            verticalPadding: 25,
            horizontalPadding: 25,
            offsetX: 0,
            offsetY: -60
          });

          this.diagram.model.setDataProperty(node, "locationSpot", go.Spot.MiddleLeft);
          this.diagram.model.setDataProperty(node, "location", new go.Point(x, y));
        }
      });
      this.diagram.commitTransaction(`${trace.guid}`);
    }
  };

  let updateModel = (options: { trace: Trace }) => {
    trace = options.trace;
  };

  let layout = new HorizontalLayout();

  Object.assign(layout, {
    angle: 90,
    layerSpacing: 80,
    nodeSpacing: 100,
    setsPortSpot: false,
    setsChildPortSpot: false,
    isOngoing: false,
    isInitial: false,
    treeStyle: go.TreeLayout.StyleLayered,
    layerStyle: go.TreeLayout.LayerSiblings,
    alignment: go.TreeLayout.AlignmentCenterChildren,
    arrangement: go.TreeLayout.ArrangementVertical,
  });

  return layout;
};

export const ROW_VERTICAL_SPACING = 25;
export const TREE_HORIZONTAL_SPACING = 25;
