import {
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  SOCKET_LEAVE_ROOM
} from "./socket.types";

import { saveProjectError } from "./currentProject";

// Add logged in user to current project room
export let socketConnect = () => {
  return async (dispatch, getState) => {
    const { currentProject, user } = getState();

    await dispatch({type: SOCKET_CONNECT});

    // If logged in and viewing project with permissions:
    if (user && currentProject && currentProject.permission) {
      // Remove old save error
      if (currentProject.saveError) {
        dispatch(saveProjectError({message: null}));
      }

    }

  };
};


// Disconnects socket and removes user from room if editing a project
export let socketDisconnect = () => {
  return async (dispatch, getState) => {
    const { user, currentProject } = getState();
    
    // If logged in and viewing project with permissions, leave room:
    if (user && currentProject && currentProject.permission) {
      await dispatch({
        type: SOCKET_LEAVE_ROOM,
        userId: user.id,
        projectId: currentProject.id
      });
    }

    // If not logged in, just disconnect
    else {
      await dispatch({ type: SOCKET_DISCONNECT })
    }

  };
};