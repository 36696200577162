import * as React from "react";
import { connect } from "react-redux";
import SourceText from "./source";
import {
  updateConsoleSplitSize,
  toggleExpandConsole,
  toggleFindAndReplace,
  toggleWordWrap,
  updateSplitScreen
} from "../../../actions/ui";
import { openDialog } from "../../../actions/dialog";
import { updateScope, compileBegin, stop } from "../../../actions/compiler";
import Draggable from "../../../component/draggable";
import { MIN_MIDDLE_WIDTH } from "../";

export class CodeEditor extends React.Component<any, any> {

  _consoleText = null;
  _consoleControlbar = null;
  _codeEditorContainer;
  _editorOptionsEl: HTMLElement = null;
  usingIE: boolean = false;

  state = {
    consoleSplit: 30,
    editorOptionsOpen: false,
  };

  constructor(props) {
    super(props);
    this.handleCodeSplitDrag = this.handleCodeSplitDrag.bind(this);
    this.handleConsoleSplitDrag = this.handleConsoleSplitDrag.bind(this);
    this.handleConsoleSplitDragStop = this.handleConsoleSplitDragStop.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleSliderMouseLeave = this.handleSliderMouseLeave.bind(this);
    this.handleSliderMouseEnter = this.handleSliderMouseEnter.bind(this);
    this.clickAnywhereToCloseEditorOptions = this.clickAnywhereToCloseEditorOptions.bind(this);
    this.handleSplitScreenToggle = this.handleSplitScreenToggle.bind(this);
  }

  handleSliderChange(e) {
    let {dispatch} = this.props;

    const scopeValue = parseInt((e.target as HTMLInputElement).value);

    // Sends the value to state to update the displayed scope value
    dispatch(updateScope({scope: scopeValue}));

    // Only displays tooltip if scope is above 3
    if (scopeValue > 3) {
      document.getElementsByClassName("scope-warning")[0].style.display = "block";
    } else {
      document.getElementsByClassName("scope-warning")[0].style.display = "none";
    }
  }

  // Unhides tooltip so it won't be invisible when triggered elsewhere
  handleSliderMouseLeave(e) {
    const tooltipEl = document.getElementsByClassName("scope-warning")[0];
    if (tooltipEl && tooltipEl.style.display === "none") {
      tooltipEl.style.display = "block";
    }
  }

  // Hides tooltip if hovering over 
  handleSliderMouseEnter(e) {
    const tooltipEl = document.getElementsByClassName("tooltip")[0];
    if (tooltipEl && this.props.scope <= 3) {
      tooltipEl.style.display = "none";
    }
  }

  handleConsoleSplitDrag(e: MouseEvent) {
    let { consoleSplit } = this.state;
    e.preventDefault();
    e.stopPropagation();

    // Calculate console split size differently if using IE:
    if (this.usingIE) {
      const containerHeight = document.getElementsByClassName("code-and-console-container")[0].getBoundingClientRect().bottom;
      const fromTopOfContainer = e.screenY - (window.outerHeight - containerHeight);
      const newHeightPercentage = 100 - ((fromTopOfContainer / containerHeight) * 100);
      const consoleBarHeight = document.getElementsByClassName("console-controlbar")[0].offsetHeight + 5;
      if (newHeightPercentage > 0 && fromTopOfContainer < containerHeight - consoleBarHeight) {
      this.setState({consoleSplit: newHeightPercentage});
      }
    }
    else {
      let consoleSplitSizePx = (consoleSplit / 100) * window.innerHeight;
      let clientY = Math.max(consoleSplitSizePx - e.movementY, 35);
      consoleSplit = clientY / window.innerHeight * 100;
      this.setState({consoleSplit});
    }

  }

  handleConsoleSplitDragStop() {
    // Saves the consoleSplit percentage to the redux state when user stops dragging
    this.props.dispatch(updateConsoleSplitSize({consoleSplit: this.state.consoleSplit}));
  }

  handleCodeSplitDrag(e: MouseEvent) {
    let { onResize, splitSizes, widthPercentage } = this.props;

    e.preventDefault();
    e.stopPropagation();

    // Handle code sidebar sizing differently when on IE
    if (this.usingIE) {
      const newWidthPercentage = (e.screenX / window.outerWidth) * 100;
      const newMiddleWidthPercentage = 100 - (splitSizes.right + newWidthPercentage);
      // Only allow resize if the middle width will stay above 485px and code editor is >= 20%
      if ((newMiddleWidthPercentage > MIN_MIDDLE_WIDTH) && (newWidthPercentage >= 20)) {
        onResize("left", newWidthPercentage);
      }
    }
    else {
      let deltaX = e.movementX;
      let codeSplitSizePx = (widthPercentage / 100) * window.innerWidth;

      const newWidthPercentage = (codeSplitSizePx + deltaX) / window.innerWidth * 100;
      // Does not allow dragging code editor to be less than 350px
      if (codeSplitSizePx + deltaX >= 350 && (e.clientX >= 350)) {
        onResize("left", newWidthPercentage);
      }
    }
  }

  // Close the view options menu when the user clicks anywhere except the menu
  clickAnywhereToCloseEditorOptions(e, el) {
    if (this._editorOptionsEl) {
      let children = this._editorOptionsEl.querySelectorAll("div,ul,li,input,span,label");
      // Convert NodeList to array
      children = Array.prototype.slice.call(children);
      const targetInFilters = children.find((child) => {
        if (child === e.target) {
          return true;
        }
      });
      // If target is not the filter container and is not a child of filter container,
      // and is not the "open sort filters" button
      if (e.target !== this._editorOptionsEl && !targetInFilters) {
        this.setState({ editorOptionsOpen: false })
      }
    }
  }

  componentDidMount() {
    // Set codeSplit value to saved value in Redux state
    this.setState({consoleSplit: this.props.consoleSplit})
    // Get browser type for sidebar resizing
    const browserType = window.navigator.userAgent;
    this.usingIE = browserType.includes("MSIE") || browserType.includes("Trident");
  }

  componentDidUpdate(prevProps, prevState) {
    const { consoleExpanded, consoleSplit, compileError, output, completed } = this.props;
    if (this._consoleText &&
      (prevProps.output.length < output.length) ||
      (!prevProps.completed === completed) ||
      (!prevProps.compileError.message.length !== compileError.message.length)
    ) {
      this._consoleText.scrollTop = this._consoleText.scrollHeight;
    }

    // Set consoleSplit percentage if the user minimizes the console
    if (prevProps.consoleExpanded !== consoleExpanded && this._consoleControlbar) {
      // If the console was dragged in its minimized position:
      if (!prevProps.consoleExpanded && consoleExpanded && (prevProps.consoleSplit !== consoleSplit)) {
        this.setState({consoleSplit});
      }
      // If it wasn't dragged, it was just clicked to expand/minimize it:
      else {
        const browserHeight = window.innerHeight;
        const consoleControllbarHeight = this._consoleControlbar.offsetHeight + 5;
        const consoleControllbarPercentage = (consoleControllbarHeight / browserHeight) * 100;
        this.setState({consoleSplit: consoleExpanded ? 30 : consoleControllbarPercentage});
      }
    }
  }

  async handleSplitScreenToggle(e) {
    e.preventDefault();
    const { dispatch, splitScreen } = this.props;
    await dispatch(updateSplitScreen({splitScreen: !splitScreen}));
  }

  render() {
    let { editorOptionsOpen } = this.state;
    let {
      dispatch,
      viewMode,
      aborted,
      sorting,
      scope,
      output,
      compileError,
      compiling,
      graphing,
      completed,
      graphedTraceCount,
      totalTraceCount,
      widthPercentage,
      onResizeStop,
      globalTrace,
      offsetHeight,
      wordWrapEnabled,
      splitScreen,
      currentProject,
      user
    } = this.props;

    const completedTraces = graphedTraceCount / totalTraceCount;
    return (
      <div
        className="code-view"
        ref={(n) => this._codeEditorContainer = n}
        style={{
          width: viewMode === "CODE" ? `100%` : `${widthPercentage}%`,
          opacity: viewMode === "GRAPH" ? 0 : 1,
          top: `${offsetHeight}px`,
          height: ` calc(100% - ${offsetHeight}px)`,
        }}>
        <div className="code-view-control-bar">
        {
          compiling || graphing
          ? <span className="button stop-button" onClick={ () => dispatch(stop()) }> Stop </span>
          : <span 
            className={`button button-blue run-button ${user !== null && currentProject && currentProject.saving ? "disabled" : null}`}
            onClick={ () => dispatch(compileBegin()) }> 
              Run 
          </span>
        }
          <span className="scope-container">
            <label htmlFor="scopeInput" className="scope-label">Scope:</label>
            <span className="scope-text-value red-tooltip">{scope}</span>
              <input
                type="range"
                id="scopeInput"
                className="scope-range-value"
                min={1} max={5} step={1} value={scope}
                data-toggle="tooltip"
                data-place="bottom"
                data-class="tooltip scope-warning"
                data-tip="Warning: Scope 4 and 5 may take a long time to run!"
                ref="tooltipToggle"
                onChange={this.handleSliderChange}
                onMouseLeave={this.handleSliderMouseLeave}
                onMouseEnter={this.handleSliderMouseEnter}
                />
          </span>
          <div className="options-dropdown-container">
            <div
              className={`options-dropdown-button ${editorOptionsOpen ? " active" : ""}`}
              onClick={(e) => {
                this.setState({ editorOptionsOpen: !editorOptionsOpen });
                window.addEventListener("mousedown", this.clickAnywhereToCloseEditorOptions);
              }}
              data-place="left"
              data-tip="Adjust view options"
              data-offset="{'top': -1}">
              Options
              <i className="icon fas fa-caret-down dropdown-icon"/>
            </div>
            <ul
              style={{ display: editorOptionsOpen ? "block" : "none" }}
              ref={(n) => this._editorOptionsEl = n}>
              <li onClick={async e => { await dispatch(toggleWordWrap()); }}>
                <div
                  data-place="left"
                  data-tip='Enable word-wrapping'
                  ref="showHiddenTooltip">
                  <input
                    type="checkbox"
                    checked={wordWrapEnabled}
                    onChange={e => e.preventDefault()} />
                  <span className="custom-input" />
                  <label
                    htmlFor="show-hidden-input"
                    className="graph-controlbar-options-show-hidden-label">
                    Word Wrapping
                    </label>
                </div>
              </li>
              <li onClick={this.handleSplitScreenToggle}>
                <div
                  data-place="left"
                  data-tip='Enable Split Screen'
                  ref="showHiddenTooltip">
                  <input
                    type="checkbox"
                    checked={splitScreen}
                    onChange={e => e.preventDefault()} />
                  <span className="custom-input" />
                  <label
                    htmlFor="show-hidden-input"
                    className="graph-controlbar-options-show-hidden-label">
                    Split Screen
                  </label>
                </div>
              </li>
              <li onClick={async e => { dispatch(openDialog({name: "clear-graphs-warning"})) }}>
                <div
                  data-place="left"
                  data-tip="Clears browser cache and resets data"
                  ref="showHiddenTooltip">
                  <div className="controlbar-options-button">Clear Data</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="code-and-console-container">
          <SourceText style={{"height": `${100 - this.state.consoleSplit}%`}}/>
          <Draggable onDragEvent={this.handleConsoleSplitDrag} onStopEvent={this.handleConsoleSplitDragStop}>
            <span className="source-console-split-drag-area"/>
          </Draggable>
          <div className="console-container" style={{height: `${this.state.consoleSplit}%`}}>
            <div ref={(n) => this._consoleControlbar = n} className="console-controlbar" onClick={(e) => dispatch(toggleExpandConsole({}))}>
              <i className="icon fas fa-indent close-icon"/>Console
              <span className="console-compile-status">
                {
                  compileError.message !== ""
                  ? <span><span className="icon status-error-icon"/>{`Error compiling`}</span>
                  : null
                }
                {
                  compiling
                  ? <span><span className="icon status-processing-icon"/>{`Compiling...`}</span>
                  : null
                }
                {
                  sorting
                  ? <span><span className="icon status-processing-icon"/>{`Sorting...`}</span>
                  : null
                }
                {
                  graphing
                  ? <span>
                    <span className="icon status-processing-icon"/>
                      {`Graphing ${graphedTraceCount} of ${totalTraceCount} event traces`}
                    </span>
                  : null
                }
                {
                  !compiling && !graphing && !sorting
                  ? <span>
                      <i className="icon fas fa-check"/>
                      {`Generated ${globalTrace ? graphedTraceCount - 1 : graphedTraceCount} event traces${globalTrace ? " and 1 global view" : ""}`}                    </span>
                  : null
                }
              </span>
            </div>
            <pre className="console-text" ref={(node) => this._consoleText = node}>
              {output === "" ? null : <span className="console-important">{"Compiling...\n\n"}</span>}
              <span className="console-info" dangerouslySetInnerHTML={{__html: output}}/>

               {
                completed && !aborted && compileError.message === ""
                ? <span
                  className="console-important">
                  {`\nFinished Compiling! Graphing ${globalTrace ? graphedTraceCount - 1 : graphedTraceCount} event traces${globalTrace ? " and 1 global view..." : "..."}`}
                </span>
                : null
              }
              {
                completed && aborted && compileError.message === ""
                ? <span className="console-important">{`\nFinished Compiling! Aborted Graphing having completed ${globalTrace ? completedTraces - 1 : completedTraces} event traces.${globalTrace ? " and 1 global view" : ""}`}</span>
                : null
              }

              {
                compileError.message === ""
                ? null
                : <span className="console-error">
                  {"\n" + compileError.message + "\n\n"}
                  {compileError.message === "Error compiling." ? null :
                    <span className="console-important">
                    Fix the errors in your Monterey Phoenix code or click Run to try again.
                    </span>}
                  </span>
              }
            </pre>
          </div>
        </div>
        <Draggable onDragEvent={this.handleCodeSplitDrag} onStopEvent={() => onResizeStop()}>
          <span className="split-view-drag-area"/>
        </Draggable>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let {
    ui: { 
      consoleSplit, 
      consoleExpanded, 
      viewMode, 
      splitSizes,
      wordWrapEnabled,
      splitScreen,
      leftEditor,
      rightEditor,
    },
    sort: { sorting },
    compiler: {
      scope,
      output,
      status,
      compileError,
      aborted,
      compiling,
      parsing,
      graphing,
      completed,
      totalTraceCount,
      graphedTraceCount,
    },
    entities: { traces },
    currentProject,
    user
  } = state;
  const globalTrace = traces["global"];
  return {
    aborted,
    consoleSplit,
    consoleExpanded,
    splitSizes,
    viewMode,
    sorting,
    scope,
    output,
    status,
    compileError,
    compiling,
    parsing,
    graphing,
    completed,
    graphedTraceCount,
    totalTraceCount,
    globalTrace,
    wordWrapEnabled,
    splitScreen,
    leftEditor,
    rightEditor,
    currentProject,
    user
  };
};

export default connect(mapStateToProps)(CodeEditor);
