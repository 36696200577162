import { merge } from "lodash";

import * as NODE from "../../actions/node.types";
import * as GRAPH from "../../actions/graph.types";

export let reducer = (state = {}, action) => {
  switch (action.type) {
    case NODE.NODE_UPDATE_NODE: {
      let nextState = merge({}, state);
      console.log("Updating Node", action.node.guid)
      nextState[action.node.guid] = merge({}, nextState[action.node.guid], action.node);
      return nextState;
    }
    case GRAPH.GRAPH_SYNC_TO_REDUX: {
      return Object.assign({}, state, action.nodes);
    }
    default:
      return state;
  }
};

export default reducer;
