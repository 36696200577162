import { SET_USER } from "../../actions/user.types";
import { User } from "../../actions/user";

// Default user is "null"
export let reducer = (state: null | User = null, action) => {
  switch (action.type) {
    case SET_USER: {
      return action.userData;
    }
    default:
      return state;
  }
};

export default reducer;

