import * as GRAPH from "../../actions/graph.types";

export let reducer = (state = {}, action) => {
  switch (action.type) {
    case GRAPH.GRAPH_SYNC_TO_REDUX: {
     return Object.assign({}, state, action.links);
    }
    default:
      return state;
  }
};

export default reducer;
