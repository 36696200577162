import * as React from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { updateShowHidden } from "../../../actions/ui";
import { updateTrace } from "../../../actions/trace";

import GoJS from "./gojs";

export class Graph extends React.Component<any, any> {

  _graphViewNode = null;
  _viewOptionsEl: HTMLElement = null;
  resizeListenerFunc;

  // *TODO* BUG HERE (rotated selecting on null)
  state = {
    altKeyPressed: false,
    rotated: false,
    showingSwimLanes: false,
    viewOptionsOpen: false,
  };

  constructor(props) {
    super(props);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.showAllEventsKeyShortcut = this.showAllEventsKeyShortcut.bind(this);
    this.updateGraphSize = this.updateGraphSize.bind(this);
    this.toggleRotation = this.toggleRotation.bind(this);
    this.clickAnywhereToCloseViewOptions = this.clickAnywhereToCloseViewOptions.bind(this);
  }

  updateGraphSize() {
    if (this._goJS) {
      // Fits the graph to the parent div size
      this._goJS.getWrappedInstance().fitToParent();
    }
  }

  componentDidMount() {
    const { currentTrace } = this.props;
    this.showAllEventsKeyShortcut();
    // Add event listener to window to check for resize
    // this.resizeListenerFunc = debounce(, 1000);
    window.addEventListener("resize", this.updateGraphSize);

    if (currentTrace) {
      // Show graph as as either HORIZONTAL (rotated) or SYSML (showingSwimLanes)
      if (currentTrace.layout === "HORIZONTAL") {
        this.setState({ rotated: true });
      } else if (currentTrace.layout === "SYSML") {
        this.setState({ showingSwimLanes: true });
      }
    }

  }

  componentWillUnmount() {
    document.body.removeEventListener("keyup", this.showAllEventsKeyShortcut);
    document.body.removeEventListener("keydown", this.showAllEventsKeyShortcut);
    window.removeEventListener("resize", this.resizeListenerFunc);
  }

  componentDidUpdate(prevProps, prevState) {
    const { viewOptionsOpen } = this.state;
    if (prevState.viewOptionsOpen && !viewOptionsOpen) {
      window.removeEventListener("mousedown", this.clickAnywhereToCloseViewOptions);
    }
  }

  zoomIn(e) {
    if (this.props.currentTrace) {
      this.props.goJSDiagram.commandHandler.increaseZoom(1.1);
    }
    else {
      e.preventDefault();
    }
  }

  zoomOut(e) {
    if (this.props.currentTrace) {
      this.props.goJSDiagram.commandHandler.decreaseZoom(0.9);
    }
    else {
      e.preventDefault();
    }
  }

  // Collapses or expands all nodes
  // async collapseOrExpandAll(type: string) {
  //   const { dispatch } = this.props;
  //   const nodes = this.props.currentTrace.nodes;
  //   await nodes.forEach((node) => {
  //     dispatch(updateNode({
  //       node: {
  //         guid: node,
  //         collapsed: type === "collapse",
  //       }
  //     }));
  //   });
  //   await dispatch(initializeGoJSModel({ traceGuid: this.props.currentTrace.guid }));
  //   // TODO: Find out if this is the correct way to collapse all the nodes??
  //   await this.props.goJSDiagram.findTreeRoots().each((n) => {
  //     type === "collapse" ? n.collapseTree() : n.expandTree();
  //   });
  // }

  // Shows all events when ALT + h is pressed
  showAllEventsKeyShortcut() {
    // Adds event listener to div with nodes in it
    this._graphViewNode.addEventListener("keydown", (e) => {
      // Prevents browser "help" from opening:
      e.preventDefault();
      // Prevents browser "help" from opening in IE:
      e.returnValue = false;
      if (e.altKey && !this.state.altKeyPressed) {
        this.setState({ altKeyPressed: true });
      }
      if (e.key === "h" && this.state.altKeyPressed) {
        this.props.dispatch(updateShowHidden({ showHidden: true }));
      }
    });
    document.body.addEventListener("keyup", (e) => {
      if (e.key === "Alt" && this.state.altKeyPressed) {
        this.setState({ altKeyPressed: false });
      }
    });
  }

  toggleRotation() {
    this.state.rotated = !this.state.rotated;
    return (
      this.state.rotated ? "HORIZONTAL" : "SEQUENCE"
    );
  }

  // Close the view options menu when the user clicks anywhere except the menu
  clickAnywhereToCloseViewOptions(e) {
    if (this._viewOptionsEl) {
      let children = this._viewOptionsEl.querySelectorAll("div,ul,li,input,span,label");
      // Convert NodeList to array
      children = Array.prototype.slice.call(children);
      const targetInFilters = children.find((child) => {
        if (child === e.target) {
          return true;
        }
      });
      // If target is not the filter container and is not a child of filter container,
      // and is not the "open sort filters" button
      if (e.target !== this._viewOptionsEl && !targetInFilters) {
        this.setState({ viewOptionsOpen: false })
      }
    }
  }

  render() {
    let {
      viewMode,
      currentTrace,
      dispatch,
      currentTraceGuid,
      showHidden,
      widthPercentage,
      leftWidthPercentage,
      rightWidthPercentage,
      nodes,
      hidingGraph,
      offsetHeight
    } = this.props;

    const { viewOptionsOpen } = this.state;
    const regularNodes = ["ROOT", "COMPOSITE", "ATOM"];
    const traceHasRegNodes = currentTrace && nodes ? currentTrace.nodes.find(id => {
      return regularNodes.indexOf(nodes[id].type) !== -1;
    }) : false;

    return (
      <div className="graph-view" ref={(node) => this._graphViewNode = node} style={{
        left: viewMode === "GRAPH" ? `0%` : `calc(${leftWidthPercentage}% + 5px)`,
        width: viewMode === "GRAPH" ? `calc(${100 - rightWidthPercentage}%)` : `calc(${widthPercentage}%)`,
        height: `calc(100% - ${offsetHeight}px)`
      }}>
        {hidingGraph ? <div className="traces-hidden-message">Click "Run" when you are ready to generate traces.</div> : null}
        <div className="graph-controlbar" style={{display: "flex"}}>
          <div style={{display: "flex", flexDirection: "row", "justifyContent": "center", alignItems: "center"}} className={`graph-controlbar-zoom-container${currentTrace ? "" : " disabled"}`}>
            <span className="graph-controlbar-zoom-title">Zoom</span>
            <span onClick={this.zoomOut}>
              <i className={`icon fa fa-minus zoom-icon${currentTrace ? "" : " disabled"}`}/>
            </span>
            <span onClick={this.zoomIn}>
              <i className={`icon fa fa-plus zoom-icon${currentTrace ? "" : " disabled"}`}/>
            </span>
          </div>
          <div
            className={`graph-controlbar-layout-container${traceHasRegNodes ? "" : " disabled"}`}
            data-place="bottom"
            data-tip="Rotate current trace"
            onClick={async e => {
              // Do not allow rotation if there are no regular nodes
              if (!traceHasRegNodes) {
                e.preventDefault();
                return;
              }
              await dispatch(updateTrace({
                trace: {
                  guid: currentTrace.guid,
                  layout: this.toggleRotation(),
                }
              }))
            }}>
            Rotate
            {this.state.rotated ? 
              <i className="icon fas fa-redo-alt"/> : 
              <i className="icon fas fa-undo-alt"/>
            }
          </div>
          <div
            className={`graph-controlbar-layout-container${currentTrace ? "" : " disabled"}`}
            data-place="bottom"
            data-tip="Reload current trace"
            onClick={(e) => {
              currentTrace ? this._goJS.getWrappedInstance().layout() : e.preventDefault();
            }}>
            Reload
            <i className="icon fas fa-sync-alt"/>
          </div>

          <div className="graph-controlbar-options-container">
            <div className="options-dropdown-container">
              <div className={`options-dropdown-button ${viewOptionsOpen ? " active" : ""} ${traceHasRegNodes ? "" : " disabled"}`}
                  onClick={(e) => {
                  // If no regular nodes on this trace, prevent options from opening
                  if (!traceHasRegNodes) {
                    e.preventDefault();
                    return;
                  }
                  this.setState({ viewOptionsOpen: !viewOptionsOpen });
                  window.addEventListener("mousedown", this.clickAnywhereToCloseViewOptions);
                }}
                data-place="left"
                data-tip="Adjust view options"
                data-offset="{'top': -1}">
                Options
                <i className="icon fas fa-caret-down dropdown-icon"/>
              </div>
              <ul
                style={{ display: viewOptionsOpen ? "block" : "none" }}
                ref={(n) => this._viewOptionsEl = n}>
                <li
                  onClick={async e => {
                    await dispatch(updateShowHidden({ showHidden: !showHidden }));
                  }}>
                  <div
                    data-place="left"
                    data-tip="Toggle the visibility of hidden elements"
                    ref="showHiddenTooltip">
                    <input
                      type="checkbox"
                      checked={showHidden}
                      onChange={e => e.preventDefault()} />
                    <span className="custom-input" />
                    <label
                      htmlFor="show-hidden-input"
                      className="graph-controlbar-options-show-hidden-label">
                      Show Hidden
                      </label>
                  </div>
                </li>
                <li
                  onClick={async (e) => {
                    if (currentTrace) {
                      this.setState({ showingSwimLanes: currentTrace.layout !== "SYSML" });
                      await dispatch(updateTrace({
                        trace: {
                          guid: currentTrace.guid,
                          layout: currentTrace.layout === "SYSML" ? "SEQUENCE" : "SYSML"
                        }
                      }));
                    }
                  }}>
                  <div
                    data-toggle="tooltip"
                    data-place="bottom"
                    data-tip="Toggle the Swim Lanes Layout"
                    ref="swimLanesTooltip">
                    <span className="toggle-node-visibility-icon" />
                    <input
                      type="checkbox"
                      checked={currentTrace && currentTrace.layout === "SYSML" ? true : false}
                      onChange={e => e.preventDefault()} />
                    <span className="custom-input" />
                    <label htmlFor="show-sysml">Swim Lanes</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="graph-current-trace-view">
          {hidingGraph || !currentTraceGuid ? null : <GoJS isMainGraph={true} traceGuid={currentTraceGuid} ref={(n) => this._goJS = n} />}
        </div>
      </div>
    );
  }
};

let mapStateToProps = (state, ownProps) => {
  let {
    ui: { viewMode, showHidden },
    currentTraceGuid,
    entities: { traces, nodes },
    graph: { hidingGraph },
  } = state;
  let { graph: { goJSDiagram } } = state;
  let currentTrace = currentTraceGuid ? traces[currentTraceGuid] : undefined;
  return {
    goJSDiagram,
    viewMode,
    currentTrace,
    currentTraceGuid,
    showHidden,
    hidingGraph,
    nodes
  };
};

export default connect(mapStateToProps)(Graph);
