import { merge } from "lodash";
import * as SORT from "../../actions/sort.types";
import * as CP from "../../actions/compiler.types";

export let reducer = (state: {
  marked: {
    current: boolean
  };
  events: {
    current: string,
    options: object,
  },
  probability: {
    current: string,
    options: object
  },
  sorting: boolean
} = {
  marked: {
    current: false
  },
  events: {
    current: "",
    options: ["", "Asc", "Desc"],
  },
  probability: {
    current: "",
    options: ["", "Lowest", "Highest"]
  },
  sorting: false
}, action) => {
  switch (action.type) {
    case SORT.BEGIN_SORTING: {
      let nextState = merge({}, state);
      nextState.sorting = true;
      return nextState;
    }
    case SORT.SORT_UPDATE_SORT_FILTERS: {
      let nextState = merge({}, state);
      nextState[action.filter].current = action.choice;
      return nextState;
    }
    case SORT.SORT_UPDATE_SORTED_TRACES: {
      let nextState = merge({}, state);
      nextState.sorting = false;
      return nextState;
    }
    case SORT.CLEAR_SORT_FILTERS: {
      let nextState = merge({}, state);
      nextState.marked.current = false;
      nextState.events.current = "";
      nextState.probability.current = "";
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
