import { combineReducers } from "redux";
import traces from "./traces";
import nodes from "./nodes";
import links from "./links";

import * as CP from "../../actions/compiler.types";
import * as G from "../../actions/graph.types";

export let entitiesReducer = combineReducers({
  traces,
  nodes,
  links,
});

export let reducer = (state = {
  traces: {},
  nodes: {},
  links: {},
}, action) => {
  state = entitiesReducer(state, action);
  switch (action.type) {
    case G.CLEAR_GRAPHS: {
      return {
        traces: {},
        nodes: {},
        links: {},
      };;
    }
    case G.GRAPH_ADD_TRACE: {
      let { payload } = action;

      let nextState = Object.assign({}, state);

      nextState.traces = Object.assign({}, nextState.traces, payload.traces);
      nextState.nodes = Object.assign({}, nextState.nodes, payload.nodes);
      nextState.links = Object.assign({}, nextState.links, payload.links);

      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
