import { merge } from "lodash";

import * as E from "../../actions/batchExport.types";

export let reducer = (state: {
  exporting: boolean, zip: any, markedOnly: boolean, error: boolean
} = { exporting: false, zip: null, markedOnly: false, error: false
}, action) => {
  switch (action.type) {
    case E.EXPORT_START: {
      return {...state, exporting: true, markedOnly: action.markedOnly};
    }
    case E.SEND_ZIP_FOR_EXPORT: {
      let nextState = merge({}, state);
      nextState.diagrams = action.diagrams;
      return {...state, zip: action.zip, exporting: false};
    }
    case E.SEND_EXPORT_ERROR: {
      return {...state, exporting: false, zip: null, markedOnly: false, error: true};
    }
    case E.RESET_EXPORT: {
      return {...state, exporting: false, zip: null, markedOnly: false, error: false};
    }
    default:
      return state;
  }
};

export default reducer;

