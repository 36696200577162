import * as uuid from "uuid";
import { NormalizedNode, NodeType } from "../node.types";

// Takes compiler node data (t[2]) and maps the arrays into objects that
// are compatible with the redux state.
// Returns object of nodes by guid (nodes) and object of nodes by compiler ID (nodesByCompileId)
export let mapNodesFromCompiler = (compilerNodeData) => {

  let nodes: object = {};
  let nodesByCompileId: object = {};

  compilerNodeData.forEach(n => {
    let type: NodeType = "ROOT";
    switch (n[1]) {
      case "R": type = "ROOT"; break;
      case "C": type = "COMPOSITE"; break;
      case "A": type = "ATOM"; break;
      case "T": type = "SAY"; break;
    }
    let nodeEntity: NormalizedNode = {
      guid: uuid.v4(),
      name: n[0],
      type,
      x: 0,
      y: 0,
      column: n[3],
      row: n[4],
      collapsed: false,
      hidden: false,
    };

    nodes[nodeEntity.guid] = nodeEntity;
    nodesByCompileId[n[2]] = nodeEntity;
  });

  return { nodes, nodesByCompileId };

};