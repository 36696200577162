
// Default version = 3
export let reducer = (state: number = 3, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
