import * as go from "gojs";

export const COLOR_FOLDED_CORNER = "#af8d6a";
export const COLOR_FILL = "#e7ddd2";

export const NODE_WIDTH = 250;
export const STROKE_WIDTH = 1.5;

let generateNodeTemplate = (options: { cornerSize }) => {
  let $ = go.GraphObject.make;
  let { cornerSize } = options;

  return $(go.Node, "Vertical", {
      width: NODE_WIDTH,
    },

    new go.Binding("position", "position").makeTwoWay(),

    // Place 'name' of report above and outside the displayable container
    $(go.TextBlock, {
        font: "24px arial",
        margin: 2,
        stroke: "black",
        cursor: "move",
        textAlign: "center",
        width: 200,
      }, new go.Binding("text", "name")),

    $(go.Panel, go.Panel.Auto,
        $(go.Shape, "TextBox", {
            parameter1: 2.5,
            parameter2: cornerSize,
            fill: COLOR_FILL,
            stroke: COLOR_FOLDED_CORNER,
            strokeWidth: STROKE_WIDTH,
            cursor: "move",
            width: NODE_WIDTH,
          }),

        // List all reports, from top-to-bottom
        $(go.Panel, "Table",
            new go.Binding("itemArray"),
            {
                defaultRowSeparatorStroke: COLOR_FOLDED_CORNER,
                defaultSeparatorPadding: 10,
                itemTemplate:
                $(go.Panel, "TableRow",
                    { margin: 2 },
                    $(go.TextBlock, new go.Binding("text", ""),
                    {
                        font: "24px arial",
                        margin: 2,
                        stroke: "black",
                        cursor: "move",
                        textAlign: "center",
                        width: 200,
                    })
                )  // end of itemTemplate
        }),

        // filled folded corner
        $(go.Shape, {
        alignment: go.Spot.TopRight,
        geometryString: `F M0 0 L${cornerSize} ${cornerSize} 0 ${cornerSize}z`,
        fill: COLOR_FOLDED_CORNER,
        stroke: COLOR_FOLDED_CORNER,
        strokeWidth: STROKE_WIDTH,
        }),
    )
  );
};


export let nodeReportTemplate = () => generateNodeTemplate({ cornerSize: 20 });
