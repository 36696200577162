import * as React from "react";

export let ConnectedComponent: React.ComponentClass<any> = null;
export let Component: React.ComponentClass<any> = null;

if (process.env.JS_ENV === "browser") {
  Component = require("./index.browser").Component;
  ConnectedComponent = require("./index.browser").default;
} else {
  Component = require("./index.server").Component;
  ConnectedComponent = require("./index.server").default;
}

export default ConnectedComponent;
