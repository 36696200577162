
export const barChart = (viewNodes, v, viewNodeEntity) => {

  const barNode = v["BAR_CHART"];
  const rotated = barNode["ROTATE"];
  const colors = ["#e6e6e6", "#ffe880", "#84e18f", "#ff6e66"];
  // Colors for accessibility mode (shades of gray)
  const accessibleColors = ["#f2f2f2", "#bfbfbf", "#808080", "#404040"];

  const formatHeaders = () => {

    const getTotalAvg = (avgHeight) => {
      return avgHeight.reduce((r, c) => r + c);
    }

    const average = (list) => {
      if (list.length === 0) {
        return 0;
      }
      return list.reduce((prev, curr) => prev + curr) / list.length;
    }

    // Find average height of the column
    let avgHeight = barNode["tabs"].reduce((acc, tab, tabI) => {
      const averageHeight = average(viewNodes[viewNodeEntity.guid].itemArray.reduce((rowAcc, row, rowI) => {
        rowAcc.push(row[tabI].height);
        return rowAcc;
      }, []))
      acc.push(Math.round(averageHeight));
      return acc;      
    }, []);

    let totalHeight = getTotalAvg(avgHeight);

    return barNode["tabs"].map((tab, i) => {
      // Replace underscores with spaces
      const titleNoUnderscores = tab.replace("_", " ");
      const textLength = tab.length * 6.5;
      const weight = avgHeight[i] / totalHeight;
      const tabHeight = totalHeight * weight;
      const height = textLength <= tabHeight ? Math.floor(tabHeight) : 0;

      return {
        title: titleNoUnderscores,
        height: height,
        markerFill: i === 0 ? "white" : colors[(i - 1) % 4],
        accessibleMarkerFill: i === 0 ? "white" : accessibleColors[(i - 1) % 4],
        markerSize: i === 0 && height === 0 ? 0 : 13
      };
    });

  }

  const formatItems = () => {
    let itemArray = [];
    let titleArray = [];

    const maxRowHeight = 500;

    let maxDesiredRowHeight = barNode["rows"].reduce((acc, rows) => {
      let rowsLength = rows.reduce((rowAcc, displayValue) => {
        rowAcc += displayToHeight(displayValue);
        return rowAcc;
      }, 0);
      if (rowsLength > acc) {
        acc = rowsLength;
      }
      return acc;
    }, 0);

    let ratio = maxRowHeight / maxDesiredRowHeight;

    barNode["rows"].forEach((row) => {
      let item = [];
    
      row.forEach((value, i) => {
        let height = displayToHeight(value, rotated, ratio, i);
        let labelMargin = i === 0 ? 5 : 0;
        
        if (i === 0) {
          titleArray.push({
            text: value,
            height: height,
            fill: "white",
            accessibleFill: "white",
            col: 0,
            strokeWidth: height === 0 ? 0 : 1,
            textAlign: "center",
            labelMargin: labelMargin
          });
        }

        else {
          item.push({
            text: value,
            height: height,
            fill: colors[(i - 1) % 4],
            accessibleFill: accessibleColors[(i - 1) % 4],
            col: 1,
            strokeWidth: height === 0 ? 0 : 1,
            textAlign: "center",
            labelMargin: labelMargin
          });
        }
       
      });

      if (rotated) {
        itemArray.push(item);
      }

      // if not rotated, reverse the order of bar segments
      else {
        itemArray.push(item.reverse());
      }
           
    });

    return { itemArray, titleArray };
  }

  /**
   * Rules for height calculation:
   * Make the 0 value of start_time invisible.
   * Display other 0 values
   * Display strings
   * Display negative values
   * Scale down extremely high values
   */
  const displayToHeight = (
    height: string | number, 
    rotated: number = 0, 
    ratio: number = 1, 
    index: number = 0
  ) => {
    let finalHeight;

    if (isString(height)) {
      finalHeight = rotated ? 60 : 20;
    }
    else if (height === 0 && index !== 1) {
      finalHeight = 0;
    }
    else if (isNaN(height)){
      finalHeight = rotated ? 30 : 10;
    }
    else {      
      finalHeight = Math.abs(height * ratio);
    }

    return finalHeight;
    /**
     * Determines if the value is a sting.
     * @param x any
     */
    function isString(x: any): x is string {
      return typeof x === "string";
    }
  }

  viewNodes[viewNodeEntity.guid].name = barNode.title;
  viewNodes[viewNodeEntity.guid].type = rotated ? "GANTT_CHART" : "BAR_CHART";
  viewNodes[viewNodeEntity.guid].name = barNode["title"];

  const headers = formatHeaders();

  viewNodes[viewNodeEntity.guid].headers = rotated ? headers : headers.reverse();

  const { itemArray, titleArray } = formatItems();

  viewNodes[viewNodeEntity.guid].itemArray = itemArray;
  viewNodes[viewNodeEntity.guid].titleArray = titleArray;

  return { viewNodes };
};