import { v4 } from "uuid";
import { NormalizedLink } from "../../link.types";
import { NormalizedNode } from "../../node.types";


export const graph = (viewNodes, viewLinks, v, viewNodeEntity) => {
    const viewName = "GRAPH";
    const graphNodesArray = v[viewName][1];
    const graphNodes = {};

    let arrowLinks = v[viewName][2];
    let lineLinks = v[viewName][3];
    let viewNodesByCompileId: { [key: string]: NormalizedNode } = {};
    let graphLinks: NormalizedLink[] = [];

    // Convert the graph's nodes
    graphNodesArray.forEach((graphNode, adindex) => {

      let graphNodeEntity: NormalizedNode = {
        guid: v4(),
        // Only action nodes have a name
        name: graphNode[1],
        type: "GRAPH_NODE",
        x: 0,
        y: 0,
        row: 0,
        column: 0,
        collapsed: false,
        hidden: false,
        isView: false,
        isGroup: false,
        group: viewNodeEntity.guid,
        itemArray: [],
        width: 0,
        avoidNodes: false,
        spansRows: false
      };

      graphNodes[graphNodeEntity.guid] = graphNodeEntity;
      viewNodesByCompileId[graphNode[0]] = graphNodeEntity;

    });

    // Convert arrow links
    if (arrowLinks && arrowLinks.length > 0) {
      arrowLinks.forEach(graphLink => {

        let text = null;

        if (graphLink[2]) {
          // Replace underscores with spaces
          text = graphLink[2].replaceAll("_", " ");
        }

        graphLinks.push({
          guid: v4(),
          type: "FOLLOWS",
          source: viewNodesByCompileId[graphLink[0]].guid,
          target: viewNodesByCompileId[graphLink[1]].guid,
          text,
          rowSpan: 0,
          hidden: false,
          points: [],
          curviness: 0,
        });
      });
    }

    // Convert line links
    if (lineLinks && lineLinks.length > 0) {
      lineLinks.forEach(graphLink => {
        graphLinks.push({
          guid: v4(),
          type: "LINE",
          source: viewNodesByCompileId[graphLink[0]].guid,
          target: viewNodesByCompileId[graphLink[1]].guid,
          text: graphLink[2] ? graphLink[2] : null,
          rowSpan: 0,
          hidden: false,
          points: [],
          curviness: 0,
        });
      });
    }
    viewNodes = { ...viewNodes, ...graphNodes };
    viewLinks = [...viewLinks, ...graphLinks];

    return { viewNodes, viewLinks };
};
