import { merge, find } from "lodash";
import * as uuid from "uuid";

import * as CP from "./compiler.types";
import { CLEAR_GRAPHS } from "./graph.types";
import { generateGraphsFromCompilerOutput } from "./graph";
import { clearStateFromLocalStorage, saveStateToLocalStorage } from "../store";
import { clearSortFilters } from "./sort";
import G = require("glob");

// Start the parse process
export let parseBegin = async (options: {source: string}) => {
  return async (dispatch, getState) => {
    dispatch({type: CP.CP_PARSE_BEGIN});
    dispatch({type: CP.SOCKET_EMIT_PARSE, source: options.source});
  }
}

export let parseOutput = (output) => {
  return async (dispatch, getState) => {
    dispatch({ type: CP.CP_PARSE_OUTPUT, output });
  }
}

export let parseError = (error) => {
  return async (dispatch, getState) => {
    error = merge({ line: null, col: null, message: "" }, error);
    dispatch({ type: CP.CP_PARSE_ERROR, error })
  }
}

export let parseEnd = () => {
  return async (dispatch, getState) => {
    dispatch({type: CP.CP_PARSE_END});
  }
}

// Start the compile process
export let compileBegin = async () => {
  return async (dispatch, getState) => {
    let { code: { source }, currentProject, compiler: { scope }, sort } = getState();

    // disable previousState (no longer valid)
    clearStateFromLocalStorage();

    // Remove sort filters
    if (find(sort, "current")) {
      dispatch(clearSortFilters());
    }

    // Use current project's source if working on a project
    const projectSource = currentProject ? currentProject.code : source;

    dispatch({type: CP.CP_COMPILE_BEGIN});
    dispatch({type: CP.SOCKET_EMIT_COMPILE, source: projectSource, scope});

  }
}

export let compileOutput = (output) => {
  return async (dispatch, getState) => {
    dispatch({ type: CP.CP_COMPILE_OUTPUT, output });
  }
}

export let compileError = (error) => {
  return async (dispatch, getState) => {
    error = merge({ line: null, col: null, message: "" }, error);

    // If it's an empty error, set empty text to default error
    if (error.message === "") {
      error.message = "Error compiling.";
    }
    dispatch({
      type: CP.CP_COMPILE_ERROR,
      error,
    });
  }
}

export let compileEnd = (messageData) => {
  return async (dispatch, getState) => {
    let firstTraceGuid = uuid.v4();
    await dispatch(merge({
      type: CP.CP_COMPILE_END,
    }, {
      messageData,
      firstTraceGuid,
      totalTraceCount: messageData.traces.length + (messageData.GLOBAL ? 1 : 0),
    }));
    await dispatch({type: CLEAR_GRAPHS});
    await dispatch(generateGraphsFromCompilerOutput({
      messageData,
      firstTraceGuid,
    }));
  }
}

export const stop = function() {
  return async (dispatch, getState) => {
    dispatch({
      type: CP.CP_COMPILE_GRAPH_STOP,
    });
  };
};

export const compilerReset = () => {
  return async (dispatch, getState) => {
    dispatch({ type: CP.CP_COMPILE_RESET });
  };
};

export let updateScope = (options: {
  scope: number,
}) => {
  return async (dispatch, getState) => {
    let { scope } = options;
    await dispatch({ type: CP.CP_UPDATE_SCOPE, scope });
    saveStateToLocalStorage(getState());
  };
};
