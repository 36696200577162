import { merge, pick } from "lodash";

import * as G from "../../actions/graph.types";

export let reducer = (state = {
  goJSDiagram: null,
  hidingGraph: false,
}, action) => {
  switch (action.type) {
    case G.GRAPH_SET_GOJS_DIAGRAM: {
      return { ...state, goJSDiagram: action.goJSDiagram };
    }
    case G.HIDE_GRAPH: {
      let nextState = merge({}, state);
      nextState.hidingGraph = true;
      return nextState;
    }
    case G.SHOW_GRAPH: {
      let nextState = merge({}, state);
      nextState.hidingGraph = false;
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
