import * as go from "gojs";
import resizeAdornmentTemplate from "./resizeAdornmentTemplate";
import { barChartTooltip } from "./nodeBarChart";
import e = require("express");
import { fill } from "lodash";

let generateNodeTemplate = () => {
  let $ = go.GraphObject.make;

  let createBarsTemplate = (grayscaleGraphs) => $(go.Panel, "Auto",
    {margin: new go.Margin(3, 0, 3, 0)},

    new go.Binding("visible", "height", (h) => !!(h >= 0)),

    $(go.Shape,
      {
        name: "BAR",
        stroke: "black",
        height: 35
      },
      // Gant is just a reverse bar chart, so use height as the bar width
      new go.Binding("width", "height"),
      new go.Binding("fill", "", (data) => {
        if (grayscaleGraphs) {
          return data.accessibleFill;
        }
        else return data.fill;
      }),
      new go.Binding("strokeWidth"),
      new go.Binding("margin", "", (m) => {
        if (m.labelMargin) {
          return new go.Margin(0, m.labelMargin, 0, 0);
        }
        else return new go.Margin(0, 0, 0, 0);
      }),
      new go.Binding("visible", "height", (h) => !!(h >= 0)),
    ),
    $(go.TextBlock,
      {
        font: "normal 10pt sans-serif",
        width: 60,
        isMultiline: true,
        maxLines: 3,
        overflow: go.TextBlock.OverflowEllipsis,
        wrap: go.TextBlock.None
      },
      new go.Binding("text"),
      new go.Binding("column", "col"),
      new go.Binding("textAlign"),
      new go.Binding("stroke", "", (data) => {
        // If using accessibility mode, make text white for the top 2 darkest colors:
        if (grayscaleGraphs && (data.accessibleFill === "#404040" || data.accessibleFill === "#808080")) {
          return "white";
        }
        else return "black";
      }),
      new go.Binding("visible", "height", (h) => !!(h >= 0)),
    ),
    { toolTip: barChartTooltip() }
  );

  let createHeadersTemplate = (darkMode, grayscaleGraphs) => $(go.Panel, "Horizontal",
    new go.Binding("width", "height", (v) =>  v === 0 ? "auto" : Math.floor(v)),
    $(go.Shape,
      { stroke: "black", margin: new go.Margin(0, 5, 0, 10) },
      new go.Binding("fill", "", (data) => {
        if (grayscaleGraphs && data.accessibleMarkerFill) {
          return data.accessibleMarkerFill;
        }
        else return data.markerFill;
      }),
      new go.Binding("desiredSize", "markerSize", (s) => new go.Size(s, s))
    ),
    $(go.TextBlock,
      { font: "normal 10pt sans-serif", margin: new go.Margin(0, 5, 0, 0) },
      new go.Binding("text", "title"),
      new go.Binding("stroke", "", () => darkMode ? "#cccccc" : "black")
    ),
  );

  let createColumnTemplate = (grayscaleGraphs) => 
    $(go.Panel, "Horizontal", {alignment: go.Spot.Left},
      new go.Binding("itemTemplate", "", () => {
        return createBarsTemplate(grayscaleGraphs);
      }),
      new go.Binding("itemArray", "")
    );

  let createBarTitleTemplate = (darkMode) => $(go.Panel, "Auto",
    {margin: new go.Margin(3, 0, 3, 0), alignment: go.Spot.Right},
    $(go.Shape, { height: 35},
      new go.Binding("fill", "", () => darkMode ? "#121212" : "white"),
      new go.Binding("stroke", "", () => darkMode ? "#121212" : "white")
    ),
    $(go.TextBlock,
      {
        font: "normal 10pt sans-serif",
        textAlign: "right",
        verticalAlignment: go.Spot.Center,
        height: 35
      },
      new go.Binding("text"),
      new go.Binding("stroke", "", () => darkMode ? "#cccccc" : "black")
    ),
  );

  let ganttChart = $(go.Panel, "Table",
    { columnSizing: go.RowColumnDefinition.None, margin: new go.Margin(35, 10, 10, 10) },

    $(go.Panel, "TableRow", { row: 1 },
  
    // Bar left-hand titles
    $(go.Panel, "Vertical", 
      {
        column: 0, 
        alignment: go.Spot.Right,
        margin: new go.Margin(0, 20, 0, 0),
      },
      new go.Binding("itemTemplate", "", (data) => createBarTitleTemplate(data.darkMode)),
      new go.Binding("itemArray", "titleArray")
    ),

    // Bars
    $(go.Panel, {column: 1},
      $(go.Panel, "Vertical",
        {row: 1, stretch: go.GraphObject.Horizontal },
        new go.Binding("itemTemplate", "", (data) => {
          return createColumnTemplate(data.grayscaleGraphs);
        }),
        new go.Binding("itemArray"),
      )
      )    
    ),
 
  );

  let barGraphTemplate =
    $(go.Node, go.Panel.Viewbox,
      {
        resizable: true,
        resizeAdornmentTemplate,
        cursor: "pointer",
      },
      new go.Binding("position").makeTwoWay(),
      $(go.Panel, "Vertical",
        $(go.TextBlock,
          {
            textAlign: "center",
            font: "bold 18px arial, sans-serif",
            verticalAlignment: go.Spot.Top,
            isMultiline: true,
            wrap: go.TextBlock.WrapDesiredSize,
            width: 250
          },
          new go.Binding("stroke", "", (data) => data.darkMode ? "#cccccc" : "black"),
          new go.Binding("text", "name"),
        ),
        $(go.Panel, "Auto",
          $(go.Shape, "RoundedRectangle", { cursor: "pointer", name: "BARGRAPH_CONTAINER" },
            new go.Binding("stroke", "", (data) => data.darkMode ? null : "gray"),
            new go.Binding("fill", "", (data) => data.darkMode ? "#121212" : "white"),
          ),
          // Headers with color legends
          $(go.Panel, "Horizontal",
            {
              alignment: go.Spot.Top,
              margin: new go.Margin(0, 0, 10, 0),
              height: 35
            },
            new go.Binding("itemTemplate", "", (data) => {
              return createHeadersTemplate(data.darkMode, data.grayscaleGraphs);
            }),
            new go.Binding("itemArray", "headers"),
          ),
          ganttChart
        )
      )
  );

  return barGraphTemplate;

};

export let nodeGanttChartTemplate = () => generateNodeTemplate();
