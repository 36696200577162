import { NormalizedTrace } from "../../../actions/trace.types";
import { updateNode } from "../../../actions/node";
import { setGoJSDiagram } from "../../../actions/graph";
import { trace as TraceSchema } from "../../../store/normalizr/schema";
import * as React from "react";
import { connect } from "react-redux";
import * as go from "gojs";

import { denormalize } from "normalizr";

import { syncGraphToRedux } from "../../../actions/graph";

import "./templates/shape/textBox";

import { nodeRootTemplate, nodeCompositeTemplate, nodeAtomTemplate } from "./templates/node";
import { sysmlTableTemplate, groupSysMLTemplate } from "./templates/nodeSysML";
import { nodeSayTemplate } from "./templates/nodeSay";
import { nodeReportTemplate } from "./templates/nodeReport";
import { nodeBarChartTemplate } from "./templates/nodeBarChart";
import { nodeGanttChartTemplate } from "./templates/nodeGanttChart";
import { nodeTableTemplate } from "./templates/nodeTable";

import {
  adStartNodeTemplate,
  adEndNodeTemplate,
  adActionNodeTemplate,
  adDecisionNodeTemplate,
  adBarNodeTemplate
} from "./templates/nodeActivityDiagram";

import { graphNodeTemplate } from "./templates/nodeGraph";
import { linkFollowsGraph } from "./templates/linkFollowsGraph";
import { groupTemplate } from "./templates/nodeGroup";

import {
  linkInTemplate,
  linkInTextTemplate,
  linkFollowsTemplate,
  linkFollowsTextTemplate,
  linkNamedTemplate,
  linkNamedTextTemplate,
  linkFollowsAvoidNodesTemplate,
  linkLineTextTemplate
} from "./templates/link";

import { generateDiagramTemplate } from "./templates/diagram";
import { generateLayoutTemplate } from "./templates/layout";

import {
  convertTraceModelToGoJS,
  syncReduxToGoJSModel,
  convertGoJSModelToRedux,
  generateCollapsedTrace,
} from "./connectReduxAndGoJS";

export class GoJS extends React.Component<any, any> {

  _goJS: HTMLDivElement;
  diagram: go.Diagram;

  constructor(props) {
    super(props);
    this.state = { error: false };

    this.diagramModelChangedListener = this.diagramModelChangedListener.bind(this);
    this.layout = this.layout.bind(this);
    this.fitToParent = this.fitToParent.bind(this);
    this.generateDiagram = this.generateDiagram.bind(this);
    this.postGenerateDiagram = this.postGenerateDiagram.bind(this);
    this.initializeGoJSModel = this.initializeGoJSModel.bind(this);
    this.syncGoJSModelToRedux = this.syncGoJSModelToRedux.bind(this);
    this.onCollapse = this.onCollapse.bind(this);
    this.onExpandOrCollapseAllOfType = this.onExpandOrCollapseAllOfType.bind(this);
    this.findIfAllAreCollapsedOrExpanded = this.findIfAllAreCollapsedOrExpanded.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onShiftClick = this.onShiftClick.bind(this);
  }

  // Adds layout, makes diagram look pretty, updates model and syncs to redux
  layout(prevLayout?: string, curLayout?: string) {
    let { trace } = this.props;
    if (this.diagram.layout.updateModel) {
      this.diagram.layout.updateModel({ trace });
    }
    this.diagram.layout.doLayout({ prevLayout, curLayout });
    this.diagram.alignDocument(go.Spot.Center, go.Spot.Center);
    this.diagram.zoomToFit();
  }

  // Resizes digram to fit its parent div
  fitToParent() {
    const { diagram } = this;
    diagram.zoomToFit();
    diagram.requestUpdate();
  }

  // Takes a guid of a node to collapse, and updates the "collapse" value in the redux state
  async onCollapse(guid: string) {
    const { dispatch, trace, entities: { nodes } } = this.props;
    await dispatch(updateNode({
      node: {
        guid,
        collapsed: !nodes[guid].collapsed,
      }
    }));
    this.initializeGoJSModel({
      applyTransformations: true,
      collapsedNodeType: nodes[guid].type
    });
  }

  // Collapses or expands either all ROOT or COMPOSITE nodes
  async onExpandOrCollapseAllOfType(type: "ROOT" | "COMPOSITE", action: "collapse" | "expand") {
    const { dispatch, trace } = this.props;
    const keys = Object.keys(trace.nodes);
    await keys.forEach(i => {
      if (trace.nodes[keys[i]].type === type) {
        dispatch(updateNode({
          node: {
            guid: trace.nodes[keys[i]].guid,
            collapsed: action === "collapse" ? true : false,
          }
        }));
      }
    });
    this.initializeGoJSModel({
      applyTransformations: true,
      collapsedNodeType: type
    });
  }

  // isCollapsed: boolean - (true = all collapsed, false = all expanded)
  // Searches through nodes to see if they are all either collapsed or expanded already
  findIfAllAreCollapsedOrExpanded(type: "ROOT" | "COMPOSITE", isCollapsed: boolean) {
    const { trace } = this.props;
    const keys = Object.keys(trace.nodes);
    let allAreExpanded = true;
    keys.forEach(i => {
      const node = trace.nodes[keys[i]];
      if (node.type === type && node.collapsed !== isCollapsed) {
        allAreExpanded = false;
      }
    });
    return allAreExpanded;
  }

  // Sets selected node's "hidden" value in the redux state
  async onHide(guid: string) {
    const { dispatch, entities: { nodes } } = this.props;
    await dispatch(updateNode({
      node: {
        guid,
        hidden: !nodes[guid].hidden,
      }
    }));
    this.initializeGoJSModel({});
  }

  // Collapses all nodes underneath the select node
  async onShiftClick(guid: string) {
    const { trace, entities: { nodes } } = this.props;
    let collapsedTrace = generateCollapsedTrace({ trace }).trace;
    const node = collapsedTrace.nodes.find(n => n.guid === guid) as CollapsedNode;
    const getChildNodes = (n: CollapsedNode) => {
      const { inChildren } = n;
      return inChildren.map(c => {
        if (c.type === "ROOT" || c.type === "COMPOSITE") {
          return [c, ...getChildNodes(c)];
        } else {
          return [c];
        }
      }).reduce((a, b) => [...a, ...b], []);
    };

    const nodesToSelect = [node, ...getChildNodes(node)];
    // select all nodes linked to the given node
    const nodesToSelectGuids = nodesToSelect.map(n => n.guid);
    const nodesSelectedGuids = this.diagram.selection.toArray().filter(p => p instanceof go.Node).map(n => n.data.key);
    const finalNodesSelectedGuids = [...nodesToSelectGuids, ...nodesSelectedGuids];
    const diagramNodesToSelect = nodesToSelect.map(n => this.diagram.findNodeForKey(n.guid));

    // select all links between selected nodes
    const linksToSelect = collapsedTrace.links.filter(l =>
      finalNodesSelectedGuids.includes(l.target.guid) &&
      finalNodesSelectedGuids.includes(l.source.guid)
    );
    const diagramLinksToSelect = linksToSelect.map(l => this.diagram.model.linkDataArray.find(d => d.key === l.guid)).map(l => this.diagram.findPartForData(l));

    this.diagram.selectCollection(this.diagram.selection.copy().addAll([...diagramNodesToSelect, ...diagramLinksToSelect]));
  }

  // Creates a brand new diagram using a gojs model
  generateDiagram() {
    let { normalizedTrace, darkMode } = this.props;
    let diagram = generateDiagramTemplate({ goJSElement: this._goJS });
    this.diagram = diagram;
    this.diagram.animationManager.isInitial = false;

    // Set layers
    const forelayer = this.diagram.findLayer("Foreground");
    let $ = go.GraphObject.make;
    this.diagram.addLayerBefore($(go.Layer, { name: "first" }), forelayer);
    this.diagram.addLayerBefore($(go.Layer, { name: "second" }), forelayer);
    this.diagram.addLayerBefore($(go.Layer, { name: "third" }), forelayer);

    const nodeTemplateOptions = {
      onCollapse: this.onCollapse,
      onExpandOrCollapseAllOfType: this.onExpandOrCollapseAllOfType,
      findIfAllAreCollapsedOrExpanded: this.findIfAllAreCollapsedOrExpanded,
      onHoverIcon: { hovering: false },
      onHide: this.onHide,
      onShiftClick: this.onShiftClick,
      darkMode
    };

    const groupTemplateOptions = {
      onShiftClick: this.onShiftClick
    }

    // SysML Layout
    diagram.nodeTemplateMap.add("SYSML_TABLE", sysmlTableTemplate());

    // Regular nodes
    diagram.nodeTemplateMap.add("ROOT", nodeRootTemplate(nodeTemplateOptions));
    diagram.nodeTemplateMap.add("COMPOSITE", nodeCompositeTemplate(nodeTemplateOptions));
    diagram.nodeTemplateMap.add("ATOM", nodeAtomTemplate(nodeTemplateOptions));
    diagram.nodeTemplateMap.add("SAY", nodeSayTemplate(nodeTemplateOptions));

    // Groups
    diagram.groupTemplateMap.add("SYSML_GROUP", groupSysMLTemplate());
    diagram.groupTemplateMap.add("AD_GROUP", groupTemplate({ nodeType: "AD_GROUP" }));
    diagram.groupTemplateMap.add("GRAPH_GROUP", groupTemplate({ nodeType: "GRAPH_GROUP" }));

    // Activity Diagram Nodes
    diagram.nodeTemplateMap.add("AD_START", adStartNodeTemplate(groupTemplateOptions));
    diagram.nodeTemplateMap.add("AD_END", adEndNodeTemplate(groupTemplateOptions));
    diagram.nodeTemplateMap.add("AD_ACTION", adActionNodeTemplate(groupTemplateOptions));
    diagram.nodeTemplateMap.add("AD_DECISION", adDecisionNodeTemplate(groupTemplateOptions));
    diagram.nodeTemplateMap.add("AD_BAR", adBarNodeTemplate(groupTemplateOptions));
    diagram.nodeTemplateMap.add("HIDDEN_NODE", adStartNodeTemplate(groupTemplateOptions));

    // Graph Nodes
    diagram.nodeTemplateMap.add("GRAPH_NODE", graphNodeTemplate(groupTemplateOptions));

    // Views
    diagram.nodeTemplateMap.add("BAR_CHART", nodeBarChartTemplate());
    diagram.nodeTemplateMap.add("GANTT_CHART", nodeGanttChartTemplate());
    diagram.nodeTemplateMap.add("TABLE", nodeTableTemplate());
    diagram.nodeTemplateMap.add("REPORT", nodeReportTemplate());

    // Links
    diagram.linkTemplateMap.add("IN", linkInTemplate);
    diagram.linkTemplateMap.add("FOLLOWS", linkFollowsTemplate);
    diagram.linkTemplateMap.add("NAMED", linkNamedTemplate);
    diagram.linkTemplateMap.add("FOLLOWS_AVOID_NODES", linkFollowsAvoidNodesTemplate);
    diagram.linkTemplateMap.add("GRAPH_FOLLOWS_TEXT", linkFollowsGraph());

    // Links with text
    diagram.linkTemplateMap.add("IN_TEXT", linkInTextTemplate);
    diagram.linkTemplateMap.add("FOLLOWS_TEXT", linkFollowsTextTemplate);
    diagram.linkTemplateMap.add("LINE_TEXT", linkLineTextTemplate);
    diagram.linkTemplateMap.add("NAMED_TEXT", linkNamedTextTemplate);

    try {
      diagram.layout = generateLayoutTemplate({ traceLayout: normalizedTrace.layout });
      this.postGenerateDiagram();
    } catch (e) {
      console.error("CAUGHT:", e);
      diagram.layout = generateLayoutTemplate();
    }

  }

  postGenerateDiagram() {
    // Is overwritten to add customizations to diagram in components extending this one
    // Do not remove
  }

  // Takes updated gojs model and sends to redux state
  syncGoJSModelToRedux() {
    let { dispatch, normalizedTrace } = this.props;
    const { diagram } = this;
    const payload = convertGoJSModelToRedux({ diagram, normalizedTrace });
    dispatch(syncGraphToRedux({ payload }));
  }

  // Takes redux data and puts it into the go.js model
  initializeGoJSModel(options: {
    prevLayout?: any,
    applyTransformations?: boolean | undefined | null,
    collapsedNodeType?: "ROOT" | "COMPOSITE",
    uiChanged: boolean
  }) {
    let {
      normalizedTrace,
      entities,
      showHidden,
      showTooltips,
      darkMode,
      grayscaleGraphs,
      trace,
      isMainGraph,
      isDuplicateOfMainGraph,
      goJSDiagram,
      dispatch,
    } = this.props;
    let { 
      prevLayout, 
      applyTransformations, 
      collapsedNodeType,
      uiChanged
    } = options;

    if (normalizedTrace) {
      this.diagram.model.startTransaction(`${trace.guid}`);
      this.diagram.model.undoManager.isEnabled = false;
      this.diagram.model.removeChangedListener(this.diagramModelChangedListener);
      this.diagram.model.commitTransaction(`${trace.guid}`);

      let newModel: any = null;

      // Save gojs model so "convertTraceModelToGoJS" only needs to run once
      // for the main graph & it's duplicate in the sidebar
      if ((!isDuplicateOfMainGraph || !goJSDiagram) || uiChanged) {

        newModel = convertTraceModelToGoJS({
          normalizedTrace,
          entities,
          showHidden,
          showTooltips,
          darkMode,
          grayscaleGraphs,
          prevLayout: prevLayout ? prevLayout : trace.layout,
          currLayout: trace.layout,
          applyTransformations,
          collapsedNodeType
        });

        if (isMainGraph) {
          dispatch(setGoJSDiagram({goJSDiagram: this.diagram}));
        }
      }

      if ((isDuplicateOfMainGraph && goJSDiagram) && !uiChanged) {
        newModel = goJSDiagram.model;
      }

      this.diagram.model = newModel;

      this.diagram.model.startTransaction(`${trace.guid}`);
      this.diagram.model.undoManager.isEnabled = false;
      this.diagram.model.addChangedListener(this.diagramModelChangedListener);
      this.diagram.model.commitTransaction(`${trace.guid}`);
    }
  }

  componentDidMount() {
    const { trace } = this.props;
    this.setState({ error: false });

    if (!trace) {
      return this.setState({ error: true });
    }

    this.generateDiagram();
    this.initializeGoJSModel({ 
      prevLayout: this.props.trace.layout, 
      applyTransformations: true,
      uiChanged: false
     });
    this.diagram.startTransaction(`${trace.guid}`);
    this.diagram.commitTransaction(`${trace.guid}`);
    this.layout(trace.layout, trace.layout);
    this.syncGoJSModelToRedux();
  }

  diagramModelChangedListener(modelChangedEvent) {
    if (modelChangedEvent.isTransactionFinished) {
      this.syncGoJSModelToRedux();
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    return !this.state.error;
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      currentTraceGuid,
      normalizedTrace,
      trace,
      showHidden,
      showTooltips,
      splitSizes,
      onDiagramLoad,
      markedOnly,
      exporting,
      traceJSON,
      darkMode,
      grayscaleGraphs
    } = this.props;
    const { diagram } = this;

    if (normalizedTrace) {
      const darkModeChanged = prevProps.darkMode !== darkMode;
      const newTrace = !prevProps.trace || prevProps.trace.guid !== trace.guid;
      const tooltipVisibilityChanged = prevProps.showTooltips !== showTooltips;
      const showHiddenChanged = prevProps.showHidden !== showHidden;
      const grayscaleGraphsChanged = prevProps.grayscaleGraphs !== grayscaleGraphs;

      // Refresh model when these redux UI settings are changed:
      if (
        darkModeChanged || 
        newTrace || 
        tooltipVisibilityChanged || 
        showHiddenChanged ||
        grayscaleGraphsChanged
      ) {
        this.initializeGoJSModel({uiChanged: true});
      }

      // Checks to see if widths have changed, and if so, resizes all the graphs
      if (prevProps.splitSizes !== splitSizes) {
        this.fitToParent();
      }

      // If anything changed in the trace (node moved, hidden, etc.), update the model
      if (prevProps.traceJSON !== traceJSON) {
        syncReduxToGoJSModel({ trace, diagram });
      }

      if (
        prevProps.trace &&
        prevProps.trace.layout !== trace.layout &&
        prevProps.currentTraceGuid === currentTraceGuid
      ) {
        this.initializeGoJSModel({prevLayout: prevProps.normalizedTrace.layout});
        this.diagram.startTransaction(`${trace.guid}`);
        this.diagram.layout = generateLayoutTemplate({ traceLayout: normalizedTrace.layout });
        this.diagram.commitTransaction(`${trace.guid}`);
        this.layout(prevProps.trace.layout, trace.layout);
      }

      // For batch export: Send diagrams to parent component (Nagivation)
      if (!prevProps.exporting && exporting && onDiagramLoad) {
        // If exporting only marked, only send marked. Otherwise send all.
        if ((markedOnly && trace.marked) || !markedOnly) {
          onDiagramLoad(diagram, normalizedTrace.order);
        }
      }

    }
  }

  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {this._goJS ? null : <span>Loading Graph...</span>}
        <div style={{ width: "100%", height: "100%" }} ref={(e) => {
          if (e !== null) {
            this._goJS = e;
          }
        }
        } />
      </div>
    );
  }
};

export let Component = GoJS;

export const mapStateToProps = (state, ownProps) => {
  const {
    entities,
    currentTraceGuid,
    ui: { showHidden, splitSizes, showTooltips, darkMode, grayscaleGraphs },
    batchExport: { exporting, markedOnly },
    sort,
    graph: { goJSDiagram }
  } = state;

  let normalizedTrace = entities.traces[ownProps.traceGuid];
  let trace = denormalize(normalizedTrace, TraceSchema, entities);
  let traceJSON = JSON.stringify(trace);

  return {
    entities,
    normalizedTrace,
    currentTraceGuid,
    trace,
    showHidden,
    splitSizes,
    showTooltips,
    exporting,
    markedOnly,
    sort,
    goJSDiagram,
    traceJSON,
    darkMode,
    grayscaleGraphs
  };
};


export default connect(mapStateToProps, null, null, { withRef: true })(GoJS);

export interface CollapsedNode extends Node {
  parents: CollapsedNode[];
  inParents: CollapsedNode[];
  followParents: CollapsedNode[];
  children: CollapsedNode[];
  inChildren: CollapsedNode[];
  followChildren: CollapsedNode[];
}