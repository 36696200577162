import Redux from "redux";
import configureStore from "./configureStore";
import { merge } from "lodash";

export let store: Redux.Store = undefined;

export let initStore = (initialState) => {
  store = configureStore(initialState);
  return store;
};

export let loadStateFromLocalStorage = () => {
  // Merge non conflicting data from initial state provided by the server.
  // Prevents issues when adding new fields to state
  const mp3PreviousState = localStorage.getItem("mp3-previousState");
  if (mp3PreviousState) {
    const { entities, dialogs, routing, ...stateToRestore } = window && window.initialState.ui.splitSizes ? window.initialState : {};
    return merge({}, stateToRestore, JSON.parse(localStorage.getItem("mp3-previousState")));
  } else {
    return null;
  }
};

/**
 * Save state to local storage if possible.
 *
 * Returns true if the state is saved, false otherwise.
 *
 * State may not be saved due to in progress transformations, such as
 * compiling or graphing.
 *
 */
export let saveStateToLocalStorage = (state) => {
  if (process.env.NODE_ENV === "test") {
    return false;
  }
  state = merge({}, state);
  delete state.dialogs;
  delete state.graph.goJSDiagram;
  delete state.graph.diagrams;
  delete state.batchExport;
  // to prevent inconsistencies, don't save the state while generating graphs/compiling
  if (!(state.compiler.compiling || state.compiler.graphing)) {
    //console.log("DEBUG#saveStateToLocalStorage", state);
    localStorage.setItem("mp3-previousState", JSON.stringify(state));
    return true;
  }
  return false;
};

export let clearStateFromLocalStorage = () => {
  return localStorage.removeItem("mp3-previousState");
};

export default store;
