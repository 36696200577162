import { merge } from "lodash";

import * as CODE from "./code.types";
import { 
  SOCKET_EMIT_AUTOSAVE, 
  SAVE_CURRENT_PROJECT_START,
  UPDATE_CURRENT_PROJECT_SOURCE
} from "./currentProject.types";
import { saveStateToLocalStorage } from "../store";
import { parseBegin } from "./compiler";

export let updateSource = (options: {source: string}) => {
  return async (dispatch, getState) => {
    const state = getState();
    // If working on a project, parse and begin autosave
    if (state.currentProject !== null) {
      const role = state.currentProject.permission;

      await dispatch({type: UPDATE_CURRENT_PROJECT_SOURCE, source: options.source});
      await dispatch(parseBegin({source: options.source}));

      // Only owners and collaborators can save updates
      if (role === "owner" || role === "collaborator") {
        dispatch({type: SAVE_CURRENT_PROJECT_START});
        dispatch({
          type: SOCKET_EMIT_AUTOSAVE,
          source: options.source,
          projectId: state.currentProject.id,
          userId: state.user.id
        });
      }
 
    }

    // If not working on a project, parse & save to local storage only
    else {
      await dispatch(merge({}, options, {
        type: CODE.CODE_UPDATE_SOURCE,
      }));
  
      saveStateToLocalStorage(state);
      
      // Parse code
      await dispatch(parseBegin({source: options.source}));
    }

  };
};
