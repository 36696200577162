import { merge } from "lodash";
import * as JSZip from "jszip";
import * as go from "gojs";

import * as E from "./batchExport.types";

export let exportStart = (options: { markedOnly: boolean }) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {type: E.EXPORT_START}));
  };
};

export let addDiagramsForExport = async (options: { diagrams: Array<any> }) => {
  return async (dispatch, getState) => {

    let zip = new JSZip();
    const getImages = async () => {
      let images = [];
      for (let i = 0; i < options.diagrams.length; i++) {
        const blob = await new Promise(resolve => {
          options.diagrams[i].diagram.makeImageData(
            {
              scale: 2,
              type: "image/png",
              /** we don't want to limit sizes, especially as this is client side */
              maxSize: new go.Size(100000, 100000),
              returnType: "blob",
              callback: resolve,
            }
          );
        });
        zip.file(`trace_${options.diagrams[i].order}.png`, blob, {binary: true});
      }
      return images;
    };

    const images = await getImages();

    Promise.all(images).then(() => {
      dispatch(merge({}, {
        type: E.SEND_ZIP_FOR_EXPORT,
        zip
      }));
    }).catch(error => {
      dispatch(merge({}, options, {
        type: E.SEND_EXPORT_ERROR,
      }));
    });

  };
};

export let resetExport = () => {
  return async (dispatch, getState) => {
    await dispatch({type: E.RESET_EXPORT});
  };
};
