import React from 'react';
import useDarkMode from 'use-dark-mode';
import {connect} from "react-redux";
import { toggleDarkMode } from "../actions/ui";

const DarkModeToggle = ({dispatch, darkModeEnabled}) => {
  const darkMode = useDarkMode(darkModeEnabled);

  async function handleChange() {
    if (darkMode.value) {
      await darkMode.disable();
      dispatch(toggleDarkMode());
    } else {
      await darkMode.enable();
      dispatch(toggleDarkMode());
    }
  }

  return (
    <span className="toggle-control">
      <input
        type="checkbox"
        name="checkbox"
        checked={darkMode.value}
        className="cm-toggle"
        onChange={() => handleChange()}
      />
    </span>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { darkMode } = state;
  return {
    darkMode
  };
};

export default connect(mapStateToProps)(DarkModeToggle);
