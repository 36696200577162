export const CP_UPDATE_SCOPE = "CP_UPDATE_SCOPE";

export const CP_PARSE_BEGIN = "CP_PARSE_BEGIN";
export const CP_PARSE_ERROR = "CP_PARSE_ERROR";
export const CP_PARSE_OUTPUT = "CP_PARSE_OUTPUT";
export const CP_PARSE_END = "CP_PARSE_END";

export const CP_COMPILE_BEGIN = "CP_COMPILE_BEGIN";
export const CP_COMPILE_ERROR = "CP_COMPILE_ERROR";
export const CP_COMPILE_OUTPUT = "CP_COMPILE_OUTPUT";
export const CP_COMPILE_END = "CP_COMPILE_END";
export const CP_COMPILE_GRAPH_STOP = "CP_COMPILE_GRAPH_STOP";

export const SOCKET_EMIT_COMPILE = "SOCKET_EMIT_COMPILE";
export const SOCKET_EMIT_PARSE = "SOCKET_EMIT_PARSE";

export const CP_COMPILE_RESET = "CP_COMPILE_RESET";
