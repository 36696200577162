import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { logoutUser } from "../../../actions/user";

import { toggleDialog } from "../../../actions/dialog";

export class DashboardNav extends React.Component<any, any> {

  async handleLogout() {
    await axios({method: "POST", url: "/api/user/logout", withCredentials: true}).then((res) => {
      if (res.status === 200) {
        this.props.dispatch(logoutUser());
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    const { currentProject, dispatch, match, user } = this.props;
    const onProject = currentProject ? match.params.projectUUID === currentProject.uuid : false;
    const userDisplayName = `${user.firstName.split("")[0]} ${user.lastName.split("")[0]}`;
    
    return (
      <div id="dashboard-nav">

        {/* Only display project info if there is a currentProject 
          that the user has permission for.
        */}
        {currentProject && currentProject.permission ? 
          <div className="project-info">
            {/* If the user has a current project, but is not on it's page, display the
              back arrow and link back to the project*/}
            { !onProject ? 
              <Link to={`/project/${currentProject.uuid}`}>
                <span 
                  className="project-name" 
                  data-place="right"
                  data-tip="Return to project">
                  <i className="icon fas fa-long-arrow-alt-left"/>
                  {currentProject.name}
                </span>
              </Link> : 
              <span className="project-name">
                {currentProject.name}
              </span>
            }
            
            {currentProject.permission !== "viewer" ? 
              <div>
                {/* Display errors only when onProject */}
                <span 
                  className="error" 
                  style={{display: !onProject || currentProject.saveError === null ? "none": ""}}>
                    {currentProject.saveError}
                </span>

                {/* Display autosave only when on a project and no error */}
                <div style={{display: currentProject.saveError === null && onProject ? "" : "none"}}>
                  {currentProject.saving ? <div className="auto-save">Saving...</div> : 
                    <div className="auto-save">
                      <span>Saved</span> <i className="fas fa-check"/>
                    </div>
                  }
                </div>
              </div> : 
              <div>You are a viewer on this project. You can make changes, but they won't be saved.</div>
            }
            
          </div> : <div className="project-info"/> 
        }

        <div className="menu">
          <div className="user-info dropdown" style={{paddingTop: "38px;"}}>
            <div 
              className="user-icon" 
              data-place="left" 
              data-tip={`Logged in as ${user.firstName} ${user.lastName}`}>
              <span>{userDisplayName}</span>
            </div>
            <div className="dropdown-container" style={{paddingTop: "40px"}}>
              <ul className="dropdown-content">
                <Link to="/"><li>Projects</li></Link>
                {currentProject && currentProject.permission === "owner" ?
                  <li onClick={e => dispatch(toggleDialog({
                    name: "dialogSettings", 
                    props: { 
                      user: user, 
                      project: currentProject, 
                      projectId: currentProject.id, 
                      editingName: false, 
                      editingDescription: false 
                      }
                    }))}>
                    Settings
                  </li> : null 
                }
                <li onClick={e => this.handleLogout()}>
                  Logout
                  <i className="icon fas fa-sign-out-alt"/>
                </li>
              </ul>
            </div>
          </div>
          <span className="about-button" onClick={(e) => dispatch(toggleDialog({name: "dialogAbout"}))}>About</span>
          <span className="help-button" onClick={(e) => dispatch(toggleDialog({name: "dialogHelp"}))}>Help</span>
        </div>
        
      </div>

    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { currentProject, user } = state;
  return {
    currentProject,
    user
  };
};

export default withRouter(connect(mapStateToProps)(DashboardNav));
