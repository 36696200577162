import { merge } from "lodash";

import { ViewMode } from "./index.types";

import * as UI from "../../actions/ui.types";

export let reducer = (state: {
  viewMode: ViewMode,
  splitSizes: object,
  consoleSplit: number,
  consoleExpanded: boolean,
  globalExpanded: boolean,
  navigationExpanded: boolean,
  showHidden: boolean,
  showTooltips: boolean,
  wordWrapEnabled: boolean,
  tourRunning: boolean,
  tourComplete: boolean,
  darkMode: boolean,
  grayscaleGraphs: boolean
  splitScreen: boolean,
  leftEditor: string,
  rightEditor: string,
  importing: boolean
} = {
  viewMode: "SPLIT",
  splitSizes: {},
  consoleSplit: 30,
  consoleExpanded: true,
  globalExpanded: true,
  navigationExpanded: true,
  showHidden: true,
  showTooltips: true,
  wordWrapEnabled: false,
  tourRunning: false,
  tourComplete: false,
  darkMode: false,
  grayscaleGraphs: false,
  splitScreen: false,
  leftEditor: "50%",
  rightEditor: "50%",
  importing: false
}, action) => {
  switch (action.type) {
    case UI.UI_UPDATE_VIEW_MODE: {
      let nextState = merge({}, state);
      nextState.viewMode = action.viewMode;
      return nextState;
    }
    case UI.UI_UPDATE_SPLIT_SIZES: {
      let nextState = merge({}, state);
      nextState.splitSizes = action.splitSizes;
      return nextState;
    }
    case UI.UI_UPDATE_SHOW_HIDDEN: {
      let nextState = merge({}, state);
      nextState.showHidden = action.showHidden;
      return nextState;
    }
    case UI.UI_UPDATE_SHOW_TOOLTIPS: {
      let nextState = merge({}, state);
      nextState.showTooltips = !state.showTooltips;
      return nextState;
    }
    case UI.UI_TOGGLE_EXPAND_NAVIGATION: {
      let nextState = merge({}, state);
      nextState.navigationExpanded = !nextState.navigationExpanded;
      return nextState;
    }
    case UI.UI_TOGGLE_SPLIT_SCREEN: {
      let nextState = merge({}, state);
      nextState.splitScreen = !nextState.splitScreen;
      return nextState;
    }
    case UI.UI_UPDATE_SPLIT_SCREEN_SIZES: {
      const { leftEditor, rightEditor } = action;
      let nextState = merge({}, state);
      nextState.leftEditor = leftEditor;
      nextState.rightEditor = rightEditor;
      return nextState;
    }
    case UI.UI_UPDATE_CONSOLE_SPLIT_SIZE: {
      let nextState = merge({}, state);
      nextState.consoleSplit = action.consoleSplit;
      if (nextState.consoleSplit > 35 / window.innerHeight * 100) {
        nextState.consoleExpanded = true;
      }
      return nextState;
    }
    case UI.UI_TOGGLE_EXPAND_CONSOLE: {
      let nextState = merge({}, state);
      nextState.consoleSplit = nextState.consoleExpanded ? 35 / window.innerHeight * 100 : 30;
      nextState.consoleExpanded = !nextState.consoleExpanded;
      return nextState;
    }
    case UI.UI_TOGGLE_EXPAND_GLOBAL: {
      let nextState = merge({}, state);
      nextState.globalExpanded = !nextState.globalExpanded;
      return nextState;
    }
    case UI.TOGGLE_WORD_WRAP: {
      let nextState = merge({}, state);
      nextState.wordWrapEnabled = !nextState.wordWrapEnabled;
      return nextState;
    }
    case UI.BEGIN_TOUR: {
      let nextState = merge({}, state);
      nextState.tourRunning = true;
      return nextState;
    }
    case UI.END_TOUR: {
      let nextState = merge({}, state);
      nextState.tourRunning = false;
      nextState.tourComplete = true;
      return nextState;
    }
    case UI.TOGGLE_DARK_MODE: {
      let nextState = merge({}, state);
      nextState.darkMode = !nextState.darkMode;
      return nextState;
    }
    case UI.TOGGLE_GRAYSCALE_GRAPHS: {
      let nextState = merge({}, state);
      nextState.grayscaleGraphs = !nextState.grayscaleGraphs;
      return nextState;
    }
    case UI.START_IMPORT : {
      let nextState = merge({}, state);
      nextState.importing = true;
      return nextState;
    }
    case UI.END_IMPORT: {
      let nextState = merge({}, state);
      nextState.importing = false;
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
