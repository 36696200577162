import * as uuid from "uuid";
import * as go from "gojs";
import { denormalize } from "normalizr";
import { merge, uniqWith } from "lodash";

import { trace as TraceSchema } from "../../../store/normalizr/schema";
import { Trace, NormalizedTrace, TraceLayout } from "../../../actions/trace.types";
import { Node } from "../../../actions/node.types";
import { Link } from "../../../actions/link.types";

import { regularNodes } from "./templates/layout/helpers/verticalNodePositioning";
export {
  convertTraceModelToGoJS,
  generateCollapsedTrace,
  applyCollapsedTransformations,
  syncReduxToGoJSModel,
  convertGoJSModelToRedux
 };

 const convertTraceModelToGoJS = (options: {
   normalizedTrace: NormalizedTrace,
   entities, showHidden, showTooltips, darkMode, grayscaleGraphs
   prevLayout: TraceLayout|null,
   currLayout: TraceLayout,
   applyTransformations?,
   collapsedNodeType?: "ROOT"|"COMPOSITE"|undefined
  }) => {
  let {
    normalizedTrace,
    entities,
    showHidden,
    showTooltips,
    darkMode,
    grayscaleGraphs,
    prevLayout,
    currLayout,
    applyTransformations,
    collapsedNodeType
  } = options;

  let trace = denormalize(normalizedTrace, TraceSchema, entities);

  // If this is running because a node was collapsed, collapse/hide nodes:
  if (applyTransformations) {
    trace = applyCollapsedTransformations({ trace, showHidden, collapsedNodeType }).trace as Trace;
  }
  else {
    trace = generateCollapsedTrace({ trace }).trace;
  }

  let nodes = {};
  let links = {};
  // Used to reset rows + columns when switching from horizontal layout
  let maxCol = 0;
  let maxRow = 0;
  let viewMaxRow = 0;
  let ADMaxRow = 0;
  let sysmlGroupGuid: string = "";
  let sysmlTableGuid: string = "";
  let headers: object = {};

  trace.nodes.forEach((n: Node) => {

    if (n.type === "SYSML_GROUP") {
      sysmlGroupGuid = n.guid;
    }
    if (n.type === "SYSML_TABLE") {
      sysmlTableGuid = n.guid;
    }

    // Get maxRow of all regular nodes for resetting from horizontal
    if (n.type.substring(0, 2) !== "AD" && !n.isView && !n.isGroup && n.type !== "SYSML_TABLE") {
      maxRow = n.row > maxRow ? n.row : maxRow;
      maxCol = n.column > maxCol ? n.column : maxCol;
    }

    // Get max rows for views and activity diagrams
    if (n.isView) {
      if (n.type === "AD_GROUP") {
        ADMaxRow = n.row > ADMaxRow ? n.row: ADMaxRow;
      } else {
        viewMaxRow = n.row > viewMaxRow ? n.row : viewMaxRow;
      }
    }

    let node = {
      group: n.group,
      isGroup: n.isGroup ? true : false,
      category: n.type,
      key: n.guid,
      name: n.name ? n.name.replace(/_/g, " ") : null,
      position: new go.Point(Number(n.x), Number(n.y)),
      width: n.width,
      height: n.height,
      originalWidth: n.originalWidth,
      originalHeight: n.originalHeight,
      originalX: n.originalX,
      originalY: n.originalY,
      textBlockWidth: n.textBlockWidth,
      barWidth: n.barWidth,
      collapsed: n.collapsed ? true : false,
      hasCollapsedParent: n.hasCollapsedParent ? true : false,
      hidden: n.hidden ? true : false,
      showHidden,
      showTooltips,
      darkMode,
      grayscaleGraphs,
      layout: trace.layout,
      avoidNodes: n.avoidNodes,
      type: n.type,
      isView: n.isView ? true : false,
      headers: n.headers,
      itemArray: n.itemArray,
      titleArray: n.titleArray,
      row: n.row,
      column: n.column,
      spansRows: n.spansRows ? true : false,
    };

    if (prevLayout === "SYSML" && currLayout !== "SYSML") {
      if (regularNodes.indexOf(n.type) !== -1) {
        node.group = undefined;
      }
      // Hide sysml group & table
      if (n.type === "SYSML_GROUP" || n.type === "SYSML_TABLE") {
        node.hidden = true;
      }
    }

    if (prevLayout !== "SYSML" && currLayout === "SYSML") {
      // Unhide sysml group & table
      if (n.type === "SYSML_GROUP" || n.type === "SYSML_TABLE") {
        node.hidden = false;
      }
    }

    nodes[n.guid] = node;
  });

  trace.links.forEach((l: Link) =>  {
    links[l.guid] = {
      from: l.source.guid,
      to: l.target.guid,
      category: l.text ? `${l.type}_TEXT` : l.type,
      curviness: l.curviness ? l.curviness : 0,
      points: l.points ? l.points : [],
      text: l.text,
      key: l.guid,
      hidden: l.source.hidden || l.target.hidden,
      showHidden,
      layout: trace.layout,
      type: l.type,
      rowSpan: l.rowSpan,
      darkMode,
      grayscaleGraphs
    };
  });

  // Set/reset horizontal positions
  if ((prevLayout === "HORIZONTAL" || currLayout === "HORIZONTAL") && prevLayout !== currLayout) {

    trace.nodes.forEach(node => {

      // Regular nodes
      if (currLayout === "HORIZONTAL" && regularNodes.indexOf(node.type) !== -1) {
        nodes[node.guid].row = maxCol - node.column;
        nodes[node.guid].column = node.row;
        [node.row, node.column] = [maxCol - node.column, node.row];
        nodes[node.guid].width = 150;
        nodes[node.guid].textBlockWidth = 100;
      }

      if (currLayout !== "HORIZONTAL" && regularNodes.indexOf(node.type) !== -1) {
        nodes[node.guid].row = node.column;
        nodes[node.guid].column = maxRow - node.row;
        [node.row, node.column] = [node.column, maxRow - node.row];
        nodes[node.guid].width = 250;
        nodes[node.guid].textBlockWidth = 200;
      }

      // Views
      // Removing since we don't need to rotate view columns/rows
      // but keeping in case client wants to in the future
      // if (node.isView && !node.isGroup) {
      //   nodes[node.guid].row = node.column;
      //   nodes[node.guid].column = viewMaxRow - node.row;
      //   [node.row, node.column] = [node.column, viewMaxRow - node.row];
      // }

    });
  }

  // If this is the first time creating sysml layout for this trace
  // Add group & table nodes
  if (prevLayout !== "SYSML" && currLayout === "SYSML") {

    // If no sysml group on this trace, add one
    if (!sysmlGroupGuid) {
      sysmlGroupGuid = uuid.v4();
      nodes[sysmlGroupGuid] = {
        category: "SYSML_GROUP",
        type: "SYSML_GROUP",
        key: sysmlGroupGuid,
        collapsed: false,
        hidden: false,
        isGroup: true,
        isView: false,
        name: "SysML Group!",
        position: new go.Point(Number(0), Number(0)),
        itemArray: undefined,
        headers: undefined,
        row: 0,
      };
    }

    // If no sysml table, add one
    if (!sysmlTableGuid) {
      sysmlTableGuid = uuid.v4();
      nodes[sysmlTableGuid] =  {
        category: "SYSML_TABLE",
        type: "SYSML_TABLE",
        key: sysmlTableGuid,
        grayscaleGraphs,
        collapsed: false,
        hidden: false,
        isGroup: false,
        isView: false,
        name: "SysML Table!",
        position: new go.Point(Number(0), Number(0)),
        row: 0,
        column: 0
      };
    }
  }

  if (prevLayout !== "SYSML" && currLayout === "SYSML") {

    // Loop through once to get header columns
    Object.keys(nodes).forEach(nodeId => {
      let node = nodes[nodeId];

      // Assign nodes to sysml group
      if (regularNodes.indexOf(node.category) !== -1 || node.category === "SYSML_TABLE") {
        node.group = sysmlGroupGuid;
      }

      // Make headers for sysml table
      if (node.type === "ROOT") {
        headers[node.column] = {
          column: node.column,
          text: node.name,
          rootNode: node.guid,
          width: 259
        };
      }

    });
  }

  // Add table if on SYSML view
  if (prevLayout !== "SYSML" && currLayout === "SYSML") {

    let rows: object = {};
    let columns: object = {};
    let tableOffsetColumn: number = 0;
    let orphanColumnsMerged: object[] = [];

    // Get the remaining nodes (composite and atom)
    trace.nodes.forEach((node) => {
     // let node = nodes[nodeId];
      if (node.type === "COMPOSITE" || node.type === "ATOM" ) {
        // If the node has no header (is an an orphaned column) and hasn't been merged,
        if (orphanColumnsMerged.indexOf(node.column) === -1 && !headers[node.column]) {
          // Add span to the header that is connecting into this composite
          if (node.parents.length > 0) {

            // Find the root connecting to it
            let rootNode = node.parents.find(n => n.type === "ROOT");
            if (rootNode) {
              // Multiple the column width
              headers[rootNode.column].width = headers[rootNode.column].width + 300;
              // Keep track of column merged so we don't add it again
              orphanColumnsMerged.push(node.column);
            }
            // If node does not have a Root connecting to it, 
            // check to see if it has a Composite connecting to it
            //
            // @NOTE: Making a massive assumption here, to maintain fast speed
            // It may be possible that orphan node is not linked to anything, 
            // or has multiple layers of nodes/composite parents, which might break this logic
            else
            {
              let compNode = node.parents.find(n => n.type === "COMPOSITE");
              if (compNode) {

                // Loop up the node chain until we find a root node
                let root = compNode.parents.find(n => n.type === "ROOT");
                let currentNode = compNode;

                while (!root) {
                  root = currentNode.parents.find(n => n.type === "ROOT");
                  let composite = currentNode.parents.find(n => n.type === "COMPOSITE");
                  if (!root && composite) {
                    currentNode = composite;
                  }
                  else if (!root && !composite) {
                    return;
                  }
                }

                if (root) {
                  // Multiple the column width
                  headers[root.column].width = headers[root.column].width + 300;
                  // Keep track of column merged so we don't add it again
                  orphanColumnsMerged.push(node.column);
                }
                // If no root node, get the root node from the compNode's column
                else {

                }
              }
            }
          }
        }
        if (orphanColumnsMerged.indexOf(node.column) === -1) {
          // Add remaining nodes
          if (!rows[node.row]) {
            rows[node.row] = []
          }
          if (!columns[node.column]) {
            columns[node.column] = node.column;
          }
          rows[node.row].push({column: node.column, text: node.name});
        }
      }
      // Find largest column outside the table to offset the table to the right, so they don't overlap
      else if (node.type === "SAY" && node.column >= tableOffsetColumn) {
        tableOffsetColumn = node.column + 1;
      }

    });

    if (sysmlTableGuid) {
      nodes[sysmlTableGuid].itemArray = Object.keys(rows).map(r => ({columns: rows[r]}));
      nodes[sysmlTableGuid].headers = Object.keys(headers).map(h => headers[h]);
      nodes[sysmlTableGuid].column = tableOffsetColumn;
    }
  }

  let nodesArray = Object.keys(nodes).map(n => nodes[n]);
  let linksArray = Object.keys(links).map(n => links[n]);

  let graphLinksModel = new go.GraphLinksModel(nodesArray, linksArray);
  return graphLinksModel;
};

const generateCollapsedTrace = (options: { trace: Trace }) => {
  let trace = merge({}, options.trace) as CollapsedTrace;
  let { nodes, links } = trace;

  nodes = nodes.map(n => ({ ...n,
    children: [],
    inChildren: [],
    followChildren: [],
    parents: [],
    inParents: [],
    followParents: [],
  }));

  links = links.map(l => ({ ...l,
    source: nodes.find(n => n.guid === l.source.guid),
    target: nodes.find(n => n.guid === l.target.guid),
  }) as CollapsedLink);

  // find children/parents of nodes
  links.forEach(link => {
    let { target, source } = link;
    // named links often have back links, ignore them during layout
    // TODO: handle self links rather then avoid them
    if (link.type !== "NAMED") {
      source.children = source.children.concat([target]);
      target.parents = target.parents.concat([source]);
      if (link.type === "IN") {
        source.inChildren = source.inChildren.concat([target]);
        target.inParents = target.inParents.concat([source]);
      } else if (link.type === "FOLLOWS") {
        source.followChildren = source.followChildren.concat([target]);
        target.followParents = target.followParents.concat([source]);
      }
    }
  });

  return {
    trace: {
      ...trace,
      ...{
        nodes,
        links,
      },
    }
  };
};

/**
 * Given a trace, returns the transformed version based on the collapsed node field.
 */
const applyCollapsedTransformations = (options: {
  trace: Trace,
  showHidden: boolean,
  collapsedNodeType
}) => {
  let { trace, showHidden, collapsedNodeType } = options;

  // clone trace before manipulating data
  let collapsedTrace = generateCollapsedTrace({ trace }).trace;
  let { nodes, links } = collapsedTrace;

  // hide or show nodes after collapsing or expanding
  let nodesToHide: CollapsedNode[] = [];
  nodes.forEach(n => {
    // Find parent that is not collapsed
    const allParentsCollapsed = n.inParents.every(p => p.collapsed);

    if (n.type !== "ROOT" && n.type !== "SYSML_TABLE") {
      if (allParentsCollapsed) {
        n.hidden = true;
        if (n.type === "COMPOSITE") {
          n.collapsed = true;
          n.hasCollapsedParent = true;
          if (collapsedNodeType === "COMPOSITE") {
            n.hidden = true;
          }
        }
        nodesToHide = nodesToHide.concat([n]);
      }
      else {
        n.hidden = false;
        if (n.type === "COMPOSITE" && collapsedNodeType === "ROOT") {
          n.collapsed = false;
          n.hasCollapsedParent = false;
        }
      }
    }
  });

  let collapsedNodes = nodes.filter(n => n.collapsed);

  collapsedNodes.forEach(collapsedNode => {
    collapsedNode.children.forEach(childNode => {
      let linksToCreate: CollapsedLink[] = [];

      const childNodeLink = links.find(l => l.source === collapsedNode && l.target === childNode);
      const childNodeChildLinks = links.filter(l => l.source === childNode);
      const childNodeParentLinks = links.filter(l => l.target === childNode);

      // fix childNode child links
      if (!childNode.hidden) {
        // link to parent
        linksToCreate = linksToCreate.concat([{ ...childNodeLink,
          guid: uuid.v4(),
          source: collapsedNode,
          target: childNode,
          points: [],
        } as CollapsedLink]);
      } else {
        // link all parents to chilren of childNode
        const linksToCreateParentsToChildrenOfChildNode = childNodeParentLinks.map(lP => childNodeChildLinks.filter(lC => lP.source !== lC.target && !lC.target.hidden).map(lC => ({
          ...lC,
          guid: uuid.v4(),
          source: lP.source,
          target: lC.target,
          points: [],
        }))).reduce((a, b) => a.concat(b), []);
        linksToCreate = [ ...linksToCreate, ...linksToCreateParentsToChildrenOfChildNode];
        const findValidParentToLink = ((n: CollapsedNode) => {
          if (n.type === "ROOT") {
            return n;
          } else if (!n.hidden) {
            return n;
          } else if (n.parents.every(p => p.hidden)) {
            return null;
          } else {
            return n.parents.find(p => !p.hidden);
          }
        });

        // link all follow parents to parents of childNode
        let linksToCreateParentsOfChildNode = childNodeParentLinks.filter(lP => lP.type === "FOLLOWS").map(lP => {
          const otherParents = childNodeParentLinks.filter(l => l !== lP && l.source !== lP.source);
          return otherParents.map(oLP => {
            const validParentToLink = findValidParentToLink(oLP.source);
            return {
              ...lP,
              guid: uuid.v4(),
              source: lP.source,
              target: validParentToLink,
              points: [],
            };
          }).filter(l => l.source !== null && l.target !== null).filter(l => l.source !== l.target);
        }).reduce((a, b) => a.concat(b), []);

        // link all follow children to parents of childNode
        let linksToCreateChildrenOfChildNode = childNodeChildLinks.filter(lP => lP.type === "FOLLOWS").map(lP => {
          const validParentToLink = findValidParentToLink(lP.source);
          return {
            ...lP,
            guid: uuid.v4(),
            source: validParentToLink,
            target: lP.target,
            points: [],
          };
        }).filter(l => l.source !== null && l.target !== null).filter(l => l.source !== l.target).reduce((a, b) => a.concat(b), []);

        linksToCreate = [ ...linksToCreate, ...linksToCreateParentsOfChildNode, ...linksToCreateChildrenOfChildNode];

      }

      // if not showHidden, original nodes will be visible and we don't need additional links
      if (!showHidden) {
        links = [ ...links, ...linksToCreate];
        // remove duplicates
        links = uniqWith(links, (a, b) =>
          a.type !== "NAMED" &&
          a.source === b.source &&
          a.target === b.target &&
          a.type === b.type);
      }
    });
  });

  return {
    trace: {
      ...collapsedTrace,
      ...{
        nodes,
        links,
      },
    },
  };
};

const syncReduxToGoJSModel = (options: { trace: Trace, diagram: go.Diagram }) => {
  const { trace, diagram } = options;
  // update graph
  diagram.model.startTransaction(`${trace.guid}`);
  trace.nodes.forEach(nodeEntity => {
    let n = diagram.findNodeForKey(nodeEntity.guid);

    if (n) {
      diagram.model.setDataProperty(n.data, "position", new go.Point(nodeEntity.x, nodeEntity.y));
      diagram.model.setDataProperty(n.data, "hidden", nodeEntity.hidden);
      diagram.model.setDataProperty(n.data, "collapsed", nodeEntity.collapsed);
      if (n.data.isView) {
        diagram.model.setDataProperty(n.data, "width", nodeEntity.width);
        diagram.model.setDataProperty(n.data, "height", nodeEntity.height);
      }
    }
  });

  diagram.model.commitTransaction(`${trace.guid}`);
};

const convertGoJSModelToRedux = (options: { diagram: go.Diagram, normalizedTrace: NormalizedTrace }) => {
  const { diagram, normalizedTrace } = options;

  let modelData = JSON.parse(diagram.model.toJson());
  let trace = normalizedTrace;
  let nodes = modelData.nodeDataArray.reduce((obj, item) => {

    // Take what we need from the node to update in redux
    let {
      key,
      name,
      position: { x, y },
      width,
      barWidth,
      height,
      nodeWidth,
      textBlockWidth,
      originalWidth,
      originalHeight,
      originalX,
      originalY,
      group,
      isGroup,
      isView,
      itemArray,
      titleArray,
      headers,
      type,
      column,
      row,
      hidden,
      collapsed,
      hasCollapsedParent,
      avoidNodes,
      spansRows
     } = item;

    let newOriginalWidth = null;
    let newOriginalHeight = null;
    let newOriginalX = null;
    let newOriginalY = null;

    // If the original width/height has not been set yet, set it
    if (item.isView || item.type === "AD_TITLE" || item.type === "AD_ACTION") {
      newOriginalWidth = !originalWidth ? diagram.findNodeForKey(key).actualBounds.width : originalWidth;
      newOriginalHeight = !originalHeight ? diagram.findNodeForKey(key).actualBounds.height : originalHeight;
    }

    // Record original x and y for the first time if type is a graph node
    if (item.type.includes("GRAPH") && item.type !== "GRAPH_GROUP") {
      newOriginalX = !originalX ? diagram.findNodeForKey(key).actualBounds.x : originalX;
      newOriginalY = !originalY ? diagram.findNodeForKey(key).actualBounds.y : originalY;
    }

    obj[key] = {
      guid: key,
      x,
      y,
      row,
      column,
      group,
      name,
      width,
      barWidth,
      height,
      nodeWidth,
      textBlockWidth,
      originalWidth: newOriginalWidth,
      originalHeight: newOriginalHeight,
      originalX: newOriginalX,
      originalY: newOriginalY,
      isGroup,
      isView,
      itemArray,
      titleArray,
      headers,
      type,
      hidden,
      collapsed,
      hasCollapsedParent,
      avoidNodes,
      spansRows
    }

    return obj;
  }, {});

  
  let links = modelData.linkDataArray.reduce((obj, item) => (obj[item.key] = {
      guid: item.key,
      points: item.points ? [ ...item.points] : [],
      curviness: item.curviness,
      source: item.from,
      target: item.to,
      type: item.type,
      text: item.text,
      rowSpan: item.rowSpan
    }, obj), {});

  return {
    trace,
    nodes,
    links
  };
};


export interface CollapsedTrace extends Trace {
  nodes: CollapsedNode[];
  links: CollapsedLink[];
}

export interface CollapsedNode extends Node {
  parents: CollapsedNode[];
  inParents: CollapsedNode[];
  followParents: CollapsedNode[];
  children: CollapsedNode[];
  inChildren: CollapsedNode[];
  followChildren: CollapsedNode[];
}

export interface CollapsedLink extends Link {
  source: CollapsedNode;
  target: CollapsedNode;
}