import { merge, pick } from "lodash";
import * as uuid from "uuid";

import * as TRACE from "../../actions/trace.types";
import * as GRAPH from "../../actions/graph.types";

export let reducer = (state = {}, action) => {
  switch (action.type) {
    case TRACE.TRACE_UPDATE_TRACE: {
      let nextState = merge({}, state);
      nextState[action.trace.guid] = merge({}, nextState[action.trace.guid], action.trace);
      return nextState;
    }
    case GRAPH.GRAPH_SYNC_TO_REDUX: {
      let nextState = merge({}, state);
      nextState[action.trace.guid].nodes = action.trace.nodes;
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
