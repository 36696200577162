import { orderBy } from "lodash";

// Takes all traces and sort data, and returns an array of sorted traces
export let sortTraces = (traces, sort) => {
  let sortedTraces = Object.assign({}, traces);

  // Organize filters so sortBy function can use them
  let marked = sort.marked.current;
  let events = sort.events.current;
  let probability = sort.probability.current;
  let itemsToOrder: Array<string> = [];
  let order: Array<"desc" | "asc"> = [];

  if (marked) {
    itemsToOrder.push("marked");
    order.push("desc");
  }

  if (events) {
    itemsToOrder.push("nodes.length")
    if (events === "Desc") {
      order.push("desc");
    }
    if (events === "Asc") {
      order.push("asc");
    }
  }

  if (probability) {
    itemsToOrder.push("probability")
    if (probability === "Lowest") {
      order.push("asc");
    }
    if (probability === "Highest") {
      order.push("desc");
    }
  }

  // Do sort
  sortedTraces = orderBy(sortedTraces, itemsToOrder, order);

  // Make array of sorted guids
  let sortedTraceGuids = sortedTraces.map(t => t.guid);

  // Remove "global" guid
  sortedTraceGuids = sortedTraceGuids.filter(guid => guid !== "global");

  return sortedTraceGuids;
};