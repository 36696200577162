import * as React from "react";
import { connect } from "react-redux";
import * as go from "gojs";

import * as GoJSComponent from "../../../../component/graph/gojs/index.browser";

export class GoJSMainGraphComponent extends GoJSComponent.Component {

  constructor(props) {
    super(props);
    this.layout = this.layout.bind(this);
  }

  diagramModelChangedListener(modelChangedEvent) {
    super.diagramModelChangedListener(modelChangedEvent);
  }

  postGenerateDiagram() {
    this.diagram.initialAutoScale = go.Diagram.Uniform;
    this.diagram.initialContentAlignment = go.Spot.Center;
  }

  componentDidUpdate(prevProps, prevState) {
    const { trace } = this.props;

    super.componentDidUpdate(prevProps, prevState);

    if (!prevProps.trace || !trace || prevProps.trace.guid !== trace.guid) {
      // TODO: remove when no longer needed
      if (process.env.NODE_ENV === "development") {
        window.testData = trace;
      }
    }
  }
}

export const Component = GoJSMainGraphComponent;

const mapStateToProps = (state, ownProps) => {
  return {
    ...GoJSComponent.mapStateToProps(state, ownProps),
  };
};

export default connect(mapStateToProps, null, null, { withRef: true })(GoJSMainGraphComponent);
