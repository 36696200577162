import axios from "axios";
import * as go from "gojs";

import {
  SET_CURRENT_PROJECT,
  UPDATE_CURRENT_PROJECT,
  RESET_CURRENT_PROJECT,
  SAVE_CURRENT_PROJECT_START,
  SAVE_CURRENT_PROJECT_END,
  SAVE_CURRENT_PROJECT_ERROR,
  UPDATE_CURRENT_PROJECT_SOURCE
} from "./currentProject.types";

import { saveStateToLocalStorage } from "../store";
import * as compiler from "./compiler";
import { hideGraph, setGoJSDiagram } from "./graph";

// Set current project
export let loadProject = (options: {project, permission}) => {
  return async (dispatch, getState) => {
    const { currentProject } = getState();
    const { project, permission } = options;

    // Project is different from currently loaded project:
    if (currentProject === null || (currentProject.id !== project.id)) {
      dispatch(compiler.compilerReset());
      dispatch(hideGraph());
      dispatch(setGoJSDiagram({goJSDiagram: null}));

      // First time loading project:
      if (currentProject === null) {
        dispatch({ project, permission, type: SET_CURRENT_PROJECT });
      }

      else {
        dispatch({ project, permission, type: UPDATE_CURRENT_PROJECT });
      }

    }

    else {
      dispatch({ project, permission, type: UPDATE_CURRENT_PROJECT });
    }

  };
};

// Removes current project
export let resetCurrentProject = () => {
  return async (dispatch, getState) => {
    await dispatch(compiler.compilerReset());
    await dispatch({type: RESET_CURRENT_PROJECT});
    await dispatch(hideGraph());
    
    saveStateToLocalStorage(getState());
  };
};

export let saveProjectStart = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_CURRENT_PROJECT_START });
  };
};

// Takes the saved project code and saves it to state
export let saveProjectEnd = (output: {projectId: number, code: string}) => {
  return async (dispatch, getState) => {

    // Set a delay so the autosave UI message has time to show
    setTimeout(() => {
      dispatch({ type: SAVE_CURRENT_PROJECT_END });
    }, 500);

    saveStateToLocalStorage(getState());

  };
};

export let saveProjectError = (options: {message: string}) => {
  return async (dispatch, getState) => {
    dispatch({ 
      type: SAVE_CURRENT_PROJECT_ERROR, 
      error: options.message
    });
  };
};

// Takes a goJsDiagram object and saves it as a jpg to display as project thumbnail
export let saveProjectSnapshot = () => {
  return async (dispatch, getState) => {
    const state = getState();

    if (state.graph.goJSDiagram) {
      const imageData = await state.graph.goJSDiagram.makeImageData({
        type: "image/jpg",
        size: new go.Size(250, 250),
        returnType: "string",
        parts: undefined,
        padding: 10
      });
      
      // Send blob to server to save as image
      await axios({
        method: "POST",
        url: `/api/project/update/snapshot`,
        data: { imageData: imageData, projectId: state.currentProject.id }
      }).then((response) => {
        return;
      }).catch((error) => {
        console.log(error);
      });
    }

  }
}
