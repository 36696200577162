import * as React from "react";
import reduxDialog from "./index";
import { connect } from "react-redux";
import { closeDialog } from "../actions/dialog";

export let name = "dialogLogin";

export class Login extends React.Component<any, any> {

  constructor(props) {
    super(props);
  }

  render() {
    const { dispatch } = this.props;

    return (
      <div>
        <div className="dialog-header">
          <h1>Login to view project</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({ name }))}></span>
        </div>
        <div className="dialog-body">
          <h2 className="login-header">Login to view an existing project</h2>
          <a className="create_project_button add-permission-button" href="/login">Login</a>
        </div>
      </div>
    );
  }
}

let ReduxExport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog settings-dialog",
  startStyle: (dialogStyleEvent) => {
    let { nodes: { container } } = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let { nodes: { container } } = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `76px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  }
})(Login);

export default connect()(ReduxExport);
