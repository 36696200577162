import * as React from "react";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";

import DialogImport from "../../dialog/import";
import DialogAbout from "../../dialog/about";
import DialogHelp from "../../dialog/help";
import DialogCreateProject from "../../dialog/dashboard-dialogs/create-project";
import DialogSettings from "../../dialog/dashboard-dialogs/settings";
import DialogExport from "../../dialog/export";
import DialogCollaboratorSettings from "../../dialog/dashboard-dialogs/settings/collaborator";
import DialogLogin from "../../dialog/login";
import DashboardImport from "../../dialog/dashboard-dialogs/dashboard-import";
import DataWarning from "../../dialog/dataWarning";
import ClearGraphsWarning from "../../dialog/clearGraphsWarning";
import GlobalSettings from "../../dialog/globalSettings";

export class PageLayout extends React.Component<any, any> {
  render() {
    const { showTooltips, user } = this.props;

    return (
      <div className="page">
        
        <ReactTooltip
          effect="solid"
          multiline={true}
          className={showTooltips ? "tooltip" : "hidden"}
          delayHide={0}
          delayShow={1000}
        />

        <div className="dialog-container">
          <DialogImport/>
          <DialogAbout/>
          <DialogHelp/>
          <DialogSettings/>
          <DialogCollaboratorSettings/>
          <DialogLogin/>
          <DialogExport/>
          <DataWarning/>
          <ClearGraphsWarning/>
          <GlobalSettings/>
          { user !== null ? <DialogCreateProject/> : null}
          { user !== null ? <DashboardImport/> : null}
        </div>
        <div className="content">
          { this.props.children }
        </div>
        <div className="footer"/>

      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const { ui: { showTooltips }, user } = state;
  return { showTooltips, user };
};

export default connect(mapStateToProps)(PageLayout);
