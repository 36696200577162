import { merge } from "lodash";

import * as TRACE from "./trace.types";

import { saveStateToLocalStorage } from "../store";
import { changeCurrentPage } from "./pagination";

export let updateTrace = (options: {
  trace: TRACE.NormalizedTrace & {
    guid: string,
  },
}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: TRACE.TRACE_UPDATE_TRACE,
    }));
    saveStateToLocalStorage(getState());
  };
};

export let selectTraceByGuid = (options: {
  guid: string,
}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: TRACE.TRACE_SELECT,
    }));
    saveStateToLocalStorage(getState());
  };
};

export let selectTraceByIndex = (options: {
  index: number,
}) => {
  return async (dispatch, getState) => {
    let {
      pagination: { currentPage, pages, tracesPerPage }
    } = getState();
    let { index } = options;
    let nextPage = Math.ceil((index + 1) / tracesPerPage);
    let guid = pages[nextPage][index % tracesPerPage];

    // If next trace is not on current page, update current page
    if (currentPage !== nextPage) {
      await dispatch(changeCurrentPage({nextPage}));
    }

    await dispatch(merge({}, {
      type: TRACE.TRACE_SELECT,
      guid,
    }));

    saveStateToLocalStorage(getState());
  };
};
