export const table = (viewNodes, v, viewNodeEntity) => {
  const tableNode = v["TABLE"];
  type Column = { column: number, text: string | number }[];
  let headers: Column = [];
  let rows = [];


  tableNode["tabs"].forEach((columnTitle, index) => {
    // Replace underscores with spaces
    const titleNoUnderscores = columnTitle.replace(new RegExp("_", "g"), " ");
    headers.push({ column: index, text: titleNoUnderscores });
  });

  tableNode["rows"].forEach((val, rowIndex) => {
    let columns: Column = [];
    headers.forEach((header, columnIndex) => {
        columns.push({ text: tableNode["rows"][rowIndex][columnIndex], column: columnIndex });
    });
    rows.push({ columns });
  });

  viewNodes[viewNodeEntity.guid].itemArray = rows;
  viewNodes[viewNodeEntity.guid].headers = headers;
  viewNodes[viewNodeEntity.guid].name = tableNode["title"];

  return {viewNodes};
};

