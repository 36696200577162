import * as React from "react";
import { connect } from "react-redux";
import * as classNames from "classnames";
import { withRouter } from "react-router-dom";

import { toggleDialog, openDialog } from "../../actions/dialog";
import { updateViewMode } from "../../actions/ui";
// import { RelativeDiv } from "../../../../../../fast/client/js/src/dialogs/styles";

export class Header extends React.Component<any, any> {

  constructor(props) {
    super(props);

    this.getCurrentPathname.bind(this);
  }

  getCurrentPathname(): string {
    const pathArr = window.location.pathname.split("/");
    const path = pathArr[1];
    return path;
  }

  render() {
    let { darkMode, dispatch, viewMode, project, user, match } = this.props;

    return (
      <div id="header">
        
        
          {/* Do not display the view controllers unless viewing a project or no user*/}
          {match.params.projectUUID || user === null ? 
            <div className="controller-container">
              <span className="view-controller">
                <span onClick={(e) => dispatch(updateViewMode({viewMode: "CODE"}))}
                      className={"code-view-button header-button " + classNames({selected: viewMode === "CODE"})}>Code</span>
                <span onClick={(e) => dispatch(updateViewMode({viewMode: "SPLIT"}))}
                      className={"split-view-button header-button " + classNames({selected: viewMode === "SPLIT"})}>Split</span>
                <span onClick={(e) => dispatch(updateViewMode({viewMode: "GRAPH"}))}
                      className={"graph-view-button header-button " + classNames({selected: viewMode === "GRAPH"})}>Graph</span>
              </span>

              {/* Do not display import/export buttons when viewing a project (from user dashboard) */}  
              {(project !== null) ?
                <span className="import-export-controller">
                  <span className="import-button header-button"
                        onClick={(e) => dispatch(toggleDialog({name: "dialogImport", props: {target: e.target}}))}>Import</span>
                  <span className="export-button header-button"
                        onClick={(e) => dispatch(toggleDialog({name: "dialogExport", props: {target: e.target}}))}>Export</span>
                </span>: null
              }

              <span 
                className="settings-button toggle-button" 
                data-place="right" 
                data-tip="Settings"
                onClick={(e) => dispatch(openDialog({name: "global-settings"}))}>
                <i className="icon fas fa-cog"/>
              </span>

          </div> : null }
        


        <div className="title-container">
          <h1>Monterey Phoenix Version 4</h1>
        </div>

        {/* Do not display the below links if a user is logged in */}
        <div>
          {!user ?
            <span className="header-links">
              
              <span className="about-button" onClick={(e) => dispatch(toggleDialog({name: "dialogAbout"}))}>About</span>
              
              <span className="help-button" onClick={(e) => dispatch(toggleDialog({name: "dialogHelp"}))}>Help</span>
              
              
              <span className="dropdown">
                Login <i className="icon fas fa-caret-down dropdown-icon"/>
                <div
                  className="help-button dropdown-container" 
                  style={{right: "30px", paddingTop: "30px"}}>
                  <ul className="dropdown-content">
                    <a href="/login/nps"><li>NPS Login</li></a>
                    <a href="/login/guest"><li>Guest Login</li></a>
                    <a href="https://ey64eqrdoe.execute-api.us-gov-west-1.amazonaws.com/"><li>Request Access</li></a>
                  </ul>
                </div>
              </span>
              
            </span> : null
          }
        </div>


      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let {darkMode, ui: { viewMode }, project, user } = state;
  return {
    darkMode,
    viewMode,
    project,
    user
  };
};

export default withRouter(connect(mapStateToProps)(Header));
