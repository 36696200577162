
import * as go from "gojs";

// based off of sample from http://gojs.net/latest/intro/shapes.html
go.Shape.defineFigureGenerator("TextBox", function (shape, w, h) {
    // this figure takes one parameter, the size of the corner
    let p1 = 5;  // default corner size
    let cornerSize = 25;
    if (shape !== null) {
      let param1 = shape.parameter1;
      let param2 = shape.parameter2;
      if (!isNaN(param1) && param1 >= 0) p1 = param1;  // can't be negative or NaN
      if (!isNaN(param2) && param2 >= 0) cornerSize = param2;  // can't be negative or NaN
    }
    p1 = Math.min(p1, w / 2);
    p1 = Math.min(p1, h / 2);  // limit by whole height or by half height?
    let geo = new go.Geometry();
    // a single figure consisting of straight lines and quarter-circle arcs
    geo.add(new go.PathFigure(0, p1)
             .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, p1, p1, p1, p1))
             .add(new go.PathSegment(go.PathSegment.Line, w - cornerSize, 0))
             .add(new go.PathSegment(go.PathSegment.Line, w, cornerSize))
             .add(new go.PathSegment(go.PathSegment.Line, w, h))
             .add(new go.PathSegment(go.PathSegment.Line, 0, h).close()));
    // don't intersect with two top corners when used in an "Auto" Panel
    geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0.3 * p1);
    geo.spot2 = new go.Spot(1, 1, -0.3 * p1, 0);
    return geo;
  });