import * as IMPORT from "./import.types";
import { saveStateToLocalStorage } from "../store";
import * as compiler from "./compiler";
import { hideGraph } from "./graph";
import { START_IMPORT, END_IMPORT } from "./ui.types";
import { compileBegin, compileEnd } from "./compiler";

// Imports .mp file to local storage
export let importMP = (options: {
  source: string,
  title: string,
}) => {
  return async (dispatch, getState) => {
    await dispatch(compiler.compilerReset());
    const title = options.title.replace(".mp", "");
    
    await dispatch({type: START_IMPORT});

    await dispatch({
      source: options.source,
      title,
      type: IMPORT.IMPORT_MP,
    });

    await dispatch({type: END_IMPORT});

    await dispatch(hideGraph());
    saveStateToLocalStorage(getState());
  };
};

// Imports .wng file to local storage
export let importWNG = (options: {
  state: any,
}) => {
  return async (dispatch, getState) => {

    await dispatch({type: START_IMPORT});
    await dispatch(compiler.compilerReset());
    const { projects, user, currentProject, batchExport } = getState();

    // generate new trace guids, to prevent collisions with existing
    let nextState = { ...options.state };
    const traces = nextState.entities.traces;
    nextState.currentTraceGuid = Object.keys(traces)[0];

    // Remove any user-specific data
    nextState.user = user;
    nextState.projects = projects;
    nextState.currentProject = currentProject;
    nextState.batchExport = batchExport;

    await dispatch({ ...options,
      type: IMPORT.IMPORT_WNG,
      state: nextState,
    });

    await dispatch({type: END_IMPORT});

    saveStateToLocalStorage(getState());
  };
};

// Imports .json 
export let importJSON = (options: {
  json: any,
}) => {
  return async (dispatch, getState) => {
    const { json } = options;

    await dispatch({type: START_IMPORT});
    await dispatch(compiler.compilerReset());

    await dispatch(compileBegin());

    // Remove code and title since JSON files don't contain them
    await dispatch({
      source: "Unknown source",
      title: "Unknown",
      type: IMPORT.IMPORT_MP,
    });
    
    await dispatch(compileEnd(json));
    await dispatch({type: END_IMPORT});

    saveStateToLocalStorage(getState());
  };
};