import * as React from "react";
import { closeDialog } from "../../../actions/dialog";
import reduxDialog from "../../index";
import { connect } from "react-redux";
import EditProjectSettings from "./edit-project-settings";
import ShareProject from "./share-project";
import SettingsExport from "./export";
import axios from "axios";
import { getAllProjects } from "../../../actions/projects";
import { withSnackbar } from "react-simple-snackbar";

export let name = "dialogSettings";

export class Index extends React.Component<any, any> {

  constructor(props) {
    super(props);

    const { project, editingName, editingDescription } = props;
    this.state = {
      email: "",
      selectedPermission: "Viewer",
      project: project,
      editingName: editingName,
      editingDescription: editingDescription,
      error: null,
      inputValue: "",
      deleteDisabled: true
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.project !== prevProps.project) {
      const { project } = this.props;
      this.setState({ project });
    }
  }

  handleInputChange(e) {
    let { project, deleteDisabled } = this.props;

    if (e.target.value === project.name) {
      this.setState({deleteDisabled: false});
    } else if (!deleteDisabled) {
      this.setState({deleteDisabled: true});
    }
    this.setState({ inputValue: e.target.value });
  }

  async handleDelete() {
    let { project, projectId, user, dispatch, openSnackbar } = this.props;
    let { inputValue } = this.state;

    if (project.name === inputValue) {
      await axios({
        method: `POST`,
        url: `/api/project/delete`,
        data: {
          userId: user.id,
          projectId: projectId,
        }
      }).then( async (response) => {
        dispatch(getAllProjects());
        window.location.href = "/";
        await this.setState({ inputValue: "" });
        dispatch(closeDialog({ name }));
        openSnackbar(`Project "${project.name}" was deleted`, 3000);
      }).catch((error) => {
        console.log("error", error.response);
      });
    }
  }

  render() {
    let { dispatch, editingName, editingDescription, project, projectId, user } = this.props;
    let { deleteDisabled } = this.state;

    return (
      <div>
        <div className="dialog-header">
          <h1>{ project ? project.name : null }</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({ name }))}></span>
        </div>
        <div className="dialog-body">
          <div className="settings-group">
            <EditProjectSettings 
              className="settings-group"
              projectId={projectId}
              editingName={editingName}
              editingDescription={editingDescription}/>
          </div>
          <div className="settings-group">
            <ShareProject className="settings-group" project={project}></ShareProject>
          </div>
          <div className="settings-group">
            <SettingsExport className="settings-group" project={project}></SettingsExport>
          </div>
          <div className="settings-group">
            <h2>Delete project</h2>
            <div>Type the project name to delete</div>
            <input
              className="dialog-input project-title-input"
              name="delete"
              type="text"
              onChange={this.handleInputChange}
            />
            <button
              disabled={deleteDisabled}
              className="create_project_button add-permission-button"
              onClick={this.handleDelete}>
              Delete
              </button>
          </div>
        </div>
      </div>
    );
  }
}

let ReduxExport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog settings-dialog",
  startStyle: (dialogStyleEvent) => {
    let { nodes: { container } } = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let { nodes: { container } } = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `76px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  }
})(Index);

export default withSnackbar(connect()(ReduxExport));
