import { Node } from "./node.types";
import { Link } from "./link.types";

export const TRACE_UPDATE_TRACE = "TRACE_UPDATE_TRACE";
export const TRACE_SELECT = "TRACE_SELECT";

export type TraceLayout = "SEQUENCE"|"HORIZONTAL"|"SYSML";

interface TraceNoRelations {
  guid: string;
  layout: TraceLayout;
  marked: boolean;
}

export interface NormalizedTrace extends TraceNoRelations {
  nodes: string[];
  probability: number;
  links: string[];
  order: number;
}

export interface Trace extends TraceNoRelations {
  nodes: Node[];
  links: Link[];
}

export interface TracesEntity { [guid: string]: NormalizedTrace; }
