import * as React from "react";

// http://stackoverflow.com/a/20927899
export class Draggable extends React.Component<{
    onDragEvent: (e: MouseEvent) => void|boolean,
    onStopEvent: (e: MouseEvent) => void|boolean
  }, any>{

  state = {
    dragging: false,
  };

  constructor(props) {
    super(props);
    this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
    this.handleOnMouseUp = this.handleOnMouseUp.bind(this);
    this.handleOnMouseMove = this.handleOnMouseMove.bind(this);
  }

  componentDidUpdate(props, state) {
    if (this.state.dragging && !state.dragging) {
      document.addEventListener("mousemove", this.handleOnMouseMove);
      document.addEventListener("mouseup", this.handleOnMouseUp);
    } else if (!this.state.dragging && state.dragging) {
      document.removeEventListener("mousemove", this.handleOnMouseMove);
      document.removeEventListener("mouseup", this.handleOnMouseUp);
    }
  }

  handleOnMouseDown(e: React.MouseEvent) {
    this.setState({dragging: true});
    e.stopPropagation();
    e.preventDefault;
  }

  handleOnMouseUp(e: MouseEvent) {
    const { onStopEvent } = this.props;
    onStopEvent(e);
    this.setState({dragging: false});
    e.stopPropagation();
    e.preventDefault();
  }

  handleOnMouseMove(e: MouseEvent) {
    let { onDragEvent } = this.props;
    let { dragging } = this.state;
    if (dragging) {
      onDragEvent(e);
    }
  }

  render() {
    return (
      <span onMouseDown={this.handleOnMouseDown} onMouseUp={this.handleOnMouseUp}>
        {this.props.children}
      </span>
    );
  }
}

export default Draggable;
