const io = require("socket.io-client");
import * as BlueBird from "bluebird";

import * as CP from "../actions/compiler.types";
import { 
  SOCKET_EMIT_AUTOSAVE, 
  SAVE_CURRENT_PROJECT_START
} from "../actions/currentProject.types";

import { 
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  SOCKET_LEAVE_ROOM
 } from "../actions/socket.types";

import { saveProjectEnd, saveProjectError } from "../actions/currentProject";

import { 
  parseOutput,
  parseError,
  parseEnd,
  compileOutput,
  compileError,
  compileEnd
} from "../actions/compiler";

export let socket = io({
  autoConnect: true,
  path: "/socket.io",
});

const socketMiddleware = () => {

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case SOCKET_CONNECT:
        const { dispatch, getState } = store;

        socket = io({
          autoConnect: true,
          path: "/socket.io",
        });

        socket.emitAsync = BlueBird.promisify(socket.emit);

        socket.on("disconnect", () => {
          const state = getState();
          // If working on a project
          if (state.currentProject) {
            dispatch(saveProjectError({message: "Disconnected from server"}));
          }
        });

        socket.on("connect_error", () => {
          const state = getState();
          if (state.currentProject) {
            dispatch(saveProjectError({
              message: "Error connecting to server: waiting to reconnect..."
            }));
          }
        });
      
        // Autosave when reconnected, in case there were changes during disconnect
        socket.on("reconnect", () => {
          const state = getState();
          // If working on a project, dispatch autosave again
          if (state.currentProject) {
            dispatch({type: SAVE_CURRENT_PROJECT_START});
            dispatch({
              type: SOCKET_EMIT_AUTOSAVE,
              source: state.code.source,
              projectId: state.currentProject.id,
              userId: state.user.id
            });
          }
        });

        // Parse listeners 
        socket.on("socket:parse:output", (res) => dispatch(parseOutput(res.output)));
        socket.on("socket:parse:end", () => dispatch(parseEnd()));
        socket.on("socket:parse:error", (res) => dispatch(parseError(res.error)));

        // Compile listeners
        socket.on("socket:compile:output", (res) => dispatch(compileOutput(res.output)));
        socket.on("socket:compile:error", (error) => dispatch(compileError(error.error)));
        socket.on("socket:compile:end", (output) => dispatch(compileEnd(output)));

        // Autosave listeners
        socket.on("socket:autosave:end", (output) => dispatch(saveProjectEnd(output)));
        socket.on("socket:autosave:error", () => {
          dispatch(saveProjectError({message: "There was an error saving your project"}));
        });

        break;
      case SOCKET_DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      case CP.SOCKET_EMIT_PARSE:
        socket.emit("parseMP", action.source);
        break;
      case CP.SOCKET_EMIT_COMPILE:
        socket.emit("compileMP", action.source, action.scope);
        break;
      case SOCKET_EMIT_AUTOSAVE:
        socket.emit("autoSave", { 
          source: action.source, 
          projectId: action.projectId, 
          userId: action.userId 
        });
        break;
      case SOCKET_LEAVE_ROOM:  
        socket.emit("room:leave");
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
