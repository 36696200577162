require("codemirror/addon/mode/simple");
require("codemirror/addon/edit/matchbrackets");
require("codemirror/addon/hint/show-hint");
require("codemirror/addon/hint/anyword-hint");
require("codemirror/addon/search/match-highlighter");
import * as CodeMirror from "codemirror";

CodeMirror.defineSimpleMode("montereyphoenix", {
  // The start state contains the rules that are intially used
  start: [
    {
      regex: /<[0-9]+(-[0-9]+)?>/i,
      token: "mp-number"
    },
    {
      regex: /(\$[a-z][a-z0-9_]*)/i,
      token: "mp-variable"
    },
    // Rules are matched in the order in which they appear, so there is
    // no ambiguity between this one and the one above
    {
      regex: /^\s*(SCHEMA)(\s+)([A-Za-z][A-Za-z0-9_]*)/m,
      token: ["mp-keyword", null, "mp-schema"]
    },
    {
      regex: /^\s*(ROOT)(\s+)([A-Za-z][A-Za-z0-9_]*)(\s*:)/m,
      token: ["mp-keyword", null, "mp-root"]
    },
    {
      regex: /([a-z][a-z0-9_]*)(\s*:)/i,
      token: ["mp-composite", null]
    },
    {
      regex: /(?:ACTIVITY|ADD|AFTER|ALL|AND|APPLY|ARROW|AT|ATTRIBUTES|BAR|BEFORE|BUILD|CHART|CHECK|CLEAR|CHAIN|CONTAINS|COORDINATE|CUT_END|CUT_FRONT|DISJ|DIAGRAM|DO|ELSE|ENCLOSING|ENSURE|EXISTS|FI|FIRST|FOLLOWS|FOR|FOREACH|FROM|GLOBAL|GRAPH|HAS|IF|IN|IS|LAST|LEAST|LINE|MAP|MARK|MAY_OVERLAP|MAX|MIN|NEW|NOT|OD|ON|ONFAIL|OR|PRECEDES|REJECT|REPORT|REVERSE|ROOT|ROTATE|SAY|SCHEMA|SET|SHARE|SHIFT_LEFT|SHIFT_RIGHT|SHOW|SORT|STEP|SUCH|SUM|TABLE|TABS|THAT|THEN|THIS|TIMES|TITLE|WITHIN|X_AXIS )\b/,
      token: "mp-keyword"
    },
    {regex: /[a-z][a-z0-9_]*/i, token: "mp-atom"},

    //{regex: /\/\/.*/, token: "mp-comment"},
    // A next property will cause the mode to move to a different state
    {regex: /\/\*/, token: "comment", next: "comment"},
    {regex: /[-+\/*=<>!]+/, token: "operator"},
    // indent and dedent properties guide autoindentation
    {regex: /[\{\[\(]/, indent: true},
    {regex: /[\}\]\)]/, dedent: true},


  ],
  // The multi-line comment state.
  comment: [
    {regex: /.*?\*\//, token: "comment", next: "start"},
    {regex: /.*/, token: "comment"}
  ],
  // The meta property contains global information about the mode. It
  // can contain properties like lineComment, which are supported by
  // all modes, and also directives like dontIndentStates, which are
  // specific to simple modes.
  meta: {
    dontIndentStates: ["comment"],
    lineComment: "//"
  }
});
