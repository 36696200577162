import * as React from "react";

import { closeDialog } from "../actions/dialog";
import { clearGraphs } from "../actions/graph";
import reduxDialog from ".";
import { connect } from "react-redux";

export let name = "clear-graphs-warning";

export class Export extends React.Component<any, any> {

  constructor(params) {
    super(params);
  }

  render() {
    let { dispatch } = this.props;

    return (
      <div>
        <div className="dialog-header" style={{paddingBottom: "3px", lineHeight: "24px"}}>
          <h1>Are you sure you want to clear your data?</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({name}))}/>
        </div>
        <div className="dialog-body" style={{fontSize: "16px", padding: "20px"}}>
          <p>{`Clearing your data may help your browser handle larger sets of event traces 
          resulting from more complex behavior logic. This action removes graphs and compiler 
          output from previous runs to make a "clean slate" for your next model run.  
          Your schema code and settings will not be removed."`}
          </p>
          <br/>
          <p>Are you sure you wish to continue?</p>
          <br/>
          <button 
            className="button button-red" 
            onClick={() => dispatch(clearGraphs())} 
            style={{marginRight: "20px"}}>
            Clear Data
          </button> 
          <button 
            className="button button-blue"
            onClick={() => dispatch(closeDialog({name}))}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
};

let ReduxExport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog",
  startStyle: (dialogStyleEvent) => {
    let {nodes: {container}} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let {nodes: {container}} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `76px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
})(Export);

let mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(ReduxExport);
