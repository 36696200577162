import * as React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { getAllProjects } from "../../../actions/projects";

export let name = "editProjectSettings";

export class EditProjectSettings extends React.Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      textAreaValue: "",
      editingName: false,
      editingDescription: false,
      user: props.user,
      projectId: props.projectId
    };

    this.updateProjectDescription = this.updateProjectDescription.bind(this);
    this.updateProjectName = this.updateProjectName.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
    this.updateTextAreaValue = this.updateTextAreaValue.bind(this);
  }

  async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    // Update project value in state and project array in props
    if (this.state.project !== prevState.project) {
      let { dispatch, user } = this.props;

      this.setState({inputValue: this.state.project.name, textAreaValue: this.state.project.description});
      dispatch(getAllProjects());

      // Load project from db when projectId is changed
    } else if (this.props.projectId !== prevProps.projectId) {
      let { projectId, user } = this.props;
      await axios({
        method: "GET",
        url: `/api/project/get?projectId=${projectId}`
      }).then( async (response) => {
        let { project } = response.data;
        await this.setState({ inputValue: project.name, textAreaValue: project.description, project: project, editingName: false, editingDescription: false });
      }).catch((error) => {
        console.log("error", error.response);
      });
    }
  }

  updateInputValue(e) {
    this.setState({ inputValue: e.target.value });
  }

  updateTextAreaValue(e) {
    this.setState({ textAreaValue: e.target.value });
  }

  updateProjectName() {
    let { editingName, inputValue, user, project } = this.state;

    if (editingName) {
      axios({
        method: `POST`,
        url: `/api/project/update/settings/name`,
        data: {
          userId: user.id,
          projectId: project.id,
          name: inputValue,
        }
      }).then( async (response) => {
        await this.setState({ project: response.data, editingName: false });
      }).catch((error) => {
        console.log("error", error.response);
      });
    } else {
      this.setState({ editingName: true });
    }
  }

  updateProjectDescription() {
    const { textAreaValue, project, editingDescription, user } = this.state;

    if (editingDescription) {
      axios({
        method: `POST`,
        url: `/api/project/update/settings/description`,
        data: {
          userId: user.id,
          projectId: project.id,
          description: textAreaValue,
        }
      }).then( async (response) => {
        await this.setState({ project: response.data, editingDescription: false });
      }).catch((error) => {
        console.log("error", error.response);
      });
    } else {
      this.setState({ editingDescription: true });
    }
  }

  render() {
    let { inputValue, editingName, editingDescription } = this.state;

    return (
      <div>
        <h2>Project title</h2>
        <div>
          <input disabled={!editingName} className="dialog-input project-title-input" type="text" value={inputValue} onChange={this.updateInputValue}/>
          <button className="create_project_button add-permission-button" onClick={this.updateProjectName}>{ editingName ? "Save" : "Edit" }</button>
        </div>
        <h2>Project description</h2>
        <div>
          <textarea disabled={!editingDescription} rows={12} className="dialog-textarea dialog-input" value={this.state.textAreaValue} onChange={this.updateTextAreaValue}/>
          <button className="create_project_button add-permission-button" onClick={this.updateProjectDescription}>{ editingDescription ? "Save" : "Edit" }</button>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  const { projects, user } = state;
  return {
    projects,
    user
  };
};

export default connect(mapStateToProps)(EditProjectSettings);
