import * as React from "react";

import { closeDialog } from "../actions/dialog";
import { toggleGrayscaleGraphs, toggleTooltips } from "../actions/ui";
import reduxDialog from ".";
import { connect } from "react-redux";
import DarkModeToggle from "../component/darkMode";

export let name = "global-settings";

export class Export extends React.Component<any, any> {

  constructor(params) {
    super(params);
  }

  render() {
    let { dispatch, darkMode, grayscaleGraphs, showTooltips } = this.props;

    return (
      <div style={{maxWidth: "500px !important", width: "100% !important"}}>
        <div className="dialog-header" style={{
          paddingBottom: "3px", 
          lineHeight: "24px",
   
          }}>
          <h1>Settings</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({name}))}/>
        </div>
        <div className="dialog-body" style={{fontSize: "16px", padding: "20px"}}>
          <div className="global-settings">
            
            <div className="global-settings-option">
              <span>Dark Mode: </span>
              <DarkModeToggle dispatch={dispatch} darkModeEnabled={darkMode}/>
            </div>

            <div className="global-settings-option">
              <span>Grayscale Graphs: </span>
              <span className="toggle-control">
                <input 
                  type="checkbox" 
                  name="checkbox" 
                  checked={grayscaleGraphs} 
                  className="cm-toggle"
                  onChange={() => dispatch(toggleGrayscaleGraphs())}
                />
              </span>
            </div>

            <div className="global-settings-option" data-tip="Toggle tooltip visibility (like this one)">
              <span>Show Tooltips: </span>
              <span className="toggle-control">
                <input 
                  type="checkbox" 
                  name="checkbox" 
                  checked={showTooltips} 
                  className="cm-toggle"
                  onChange={() => dispatch(toggleTooltips())}
                />
              </span>
            </div>

          </div>
        </div>
      </div>
    );
  }
};

let ReduxExport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog",
  startStyle: (dialogStyleEvent) => {
    let {nodes: {container}} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`
    };
  },
  endStyle: (dialogStyleEvent) => {
    let {nodes: {container}} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `76px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
})(Export);

let mapStateToProps = (state, ownProps) => {
  const { ui: { darkMode, grayscaleGraphs, showTooltips } } = state;
  return { darkMode, grayscaleGraphs, showTooltips };
};

export default connect(mapStateToProps)(ReduxExport);
