import Shepherd from "shepherd.js";
import { endTour } from "../actions/ui";

// Used between slides for a delayed transition to next slide
const timeDelay = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      resolve();
    }, 500);
  });
}

// Creates step which points to help button
// and shows user where to find the tour
const pointToHelpStep = (tour, dispatch) => {
  return {  
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: "Finish",
        action: () => { 
          dispatch(endTour());
          tour.complete();
        }
      }
    ],
    attachTo: {
      element: ".help-button",
      on: "bottom"
    },
    classes: "bottom",
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    text: [`You can revisit this tour at any time under the "Help" window.`] 
  }
}

// Returns buttons
const createButtons = (tour, dispatch) => {
  return [
    {
      classes: "shepherd-button-secondary",
      text: "Exit",
      action: () => {
        const nextStepIndex = tour.steps.indexOf(tour.currentStep);
        const nextStep = pointToHelpStep(tour, dispatch);
        tour.addStep(nextStep, nextStepIndex + 1);
        tour.next();
      }
    },
    {
      classes: "shepherd-button-primary",
      text: "<i class='fas fa-arrow-left'></i> Back",
      action: () => tour.back()
    },
    {
      classes: "shepherd-button-primary",
      text: "Next <i class='fas fa-arrow-right'/>",
      action: () => tour.next()
    }
  ]
}

// Returns all steps
const createSteps = (tour, dispatch) => {
  return [
    {
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: 'Skip',
          action: () => { 
            const nextStep = pointToHelpStep(tour, dispatch);
            tour.addStep(nextStep, 1);
            tour.next();
          }
        },
        {
          classes: 'shepherd-button-primary',
          text: "Next <i class='fas fa-arrow-right'/>",
          action: () => tour.next()
        }
      ],
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: 'Take a Tour!',
      text: [`This quick tour will get you started with MP-Firebird.<br/>
      To proceed, click the "Next" button. Or to skip the tour, click "Skip."`],
    },
    {
      attachTo: {
        element: ".source-text",
        on: "right"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "right",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`The text editor is where you load or type your MP model.
        Don't code? Have no fear! MP is a high-level language that can be learned quickly.<br/><br/>
        Take the self-paced MP tutorial at 
        <a href="https://wiki.nps.edu/display/MP/Tutorials" target="_blank">
          https://wiki.nps.edu/display/MP/Tutorials
        </a> when you are ready to begin  composing your own models.
      `],
    },
    {
      attachTo: {
        element: ".run-button",
        on: "bottom"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "bottom",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`The Run button compiles the MP code and generates a set of event traces – 
      graphs that show paths through the model’s logic.`],
    },
    {
      attachTo: {
        element: ".scope-container",
        on: "bottom"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "bottom",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`The set of generated event traces is exhaustive up to the scope that is 
        set using this slider bar.  “Scope” is the maximum number of times you want to run 
        any loops in your model. 
      `],
    },
    {
      attachTo: {
        element: ".graph-current-trace-view",
        on: "left"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "left",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`The center pane displays the event traces when the trace generation is finished.<br/><br/>
        Each event box in a graph has a menu (marked with three horizontal stripes) enabling 
        show/hide and collapse/expand options for the box.<br/><br/>
        Boxes and arrows can be dragged to different positions.  
      `],
    },
    {
      attachTo: {
        element: ".graph-controlbar",
        on: "bottom"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "bottom",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`Just above the displayed event trace is a menu with controls to zoom in 
        and out, rotate the graph 90 degrees counterclockwise, and reload the graph 
        (clearing user movements to the boxes and arrows).<br/><br/>
        The Options drop-down has some toggle controls for showing hidden boxes, 
        showing a swim lanes view of the graph, and showing tool tips.
      `],
    },
    {
      attachTo: {
        element: ".navigation-container",
        on: "left"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "left",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`The navigation pane displays thumbnail images of all the generated traces. 
        Click on a thumbnail or use the forward and back buttons at the top to bring a trace 
        into the center pane.<br/><br/>
        The Sort menu provides options for the order in which the traces appear in the navigation pane.
      `],
    },
    {
      attachTo: {
        element: ".console-container",
        on: "right"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "right",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`The console pane displays real-time and summary statistics about trace 
        generation such as speed, number of events, and total traces generated.
      `],
    },
    {
      attachTo: {
        element: ".view-controller",
        on: "bottom"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "bottom",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`Different view options let you configure the display for code only (Code), 
        both code and graphs (Split), and graphs only (Graph).  
      `],
    },
    {
      attachTo: {
        element: ".export-button",
        on: "bottom"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "bottom",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`Use the Export button when you are ready to save your work.  You can:
        <ul>
          <li>save the contents of the text editor only (.mp file)</li>
          <li>save the contents of the text editor plus the generated graphs (.wng file)</li>
          <li>export images of some or all generated traces (.png or .zip files)</li>
        </ul>
      `],
    },
    {
      attachTo: {
        element: ".import-button",
        on: "bottom"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "bottom",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`Use the Import button to open locally saved models and browse model examples.<br/><br/>
        Dozens of pre-loaded model examples are available here for you to load and explore! 
      `],
    },
    {
      attachTo: {
        element: ".help-button",
        on: "bottom"
      },
      arrow: true,
      beforeShowPromise: timeDelay,
      buttons: createButtons(tour, dispatch),
      classes: "bottom",
      highlightClass: "source-text",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`See the Help menu for additional features, keyboard shortcuts, and link to the 
        website where the latest MP Manual is posted.
      `],
    },
    {
      buttons: [
        {
          classes: "shepherd-button-primary",
          text: "Done",
          action: () => {
            dispatch(endTour());
            tour.complete();
          }
        }
      ],
      highlightClass: 'highlight',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      text: [`Experiment with running and inspecting traces from the pre-loaded examples.
        Feel free to delete or type over anything in the text editor and see the effect of your 
        changes when you press Run.<br/><br/>
        Learn more about MP at
        <a href="https://wiki.nps.edu/display/MP/" target="_blank">
          https://wiki.nps.edu/display/MP/</a>. 
      `],
    },
  ]
}

export const runTour = (dispatch) => {
  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      cancelIcon: {
        enabled: true
      },
    },
    useModalOverlay: true,
  });
  const steps = createSteps(tour, dispatch);
  tour.addSteps(steps);
  // Anytime the close icon is clicked, the tour will be marked as complete
  tour.on("cancel", () => dispatch(endTour()));
  tour.start();
};
