import * as React from "react";
import { closeDialog } from "../../actions/dialog";
import reduxDialog from "../";
import { connect } from "react-redux";
import axios from "axios";
import { withSnackbar } from "react-simple-snackbar";

import { getAllProjects } from "../../actions/projects";
import ExampleDropdown from "../exampleDropdown";

export let name = "dialogDashboardImport";
const validExtensions = [".mp", ".wng"];

export class DashboardImport extends React.Component<any, any> {

  state = {
    mpExamples: []
  };

  _fileInputEl: HTMLInputElement | null = null;

  constructor(params) {
    super(params);
    this.importMP = this.importMP.bind(this);
    this.importWNG = this.importWNG.bind(this);
  }

  componentDidMount() {
    this.getExamples();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen && !prevProps.isOpen) {
      (this._fileInputEl as HTMLInputElement).value = "";
    }
  }

  async getExamples(){
    await axios({
      method: "GET",
      url: `/api/example/all/`
    }).then((response) => {
      this.setState({mpExamples: response.data.examples});
    });
  }

  // Uses regex to get the top comment from the source, to be used as the project description
  getCommentFromSource(source) {
    const comment = source.match(/\/\*([\0-\uffff]*?)\*\//);
    return comment && comment[1] ? comment[1] : "";
  }

  // Saves project to DB
  async saveProject(source, title, description) {
    const { dispatch, openSnackbar } = this.props;
    await axios({
      method: "POST",
      url: `/api/project/create`,
      data: {
        name: title,
        description: description,
        code: source
      }
    }).then(async (res) => {
      await this.setState({ inputValue: "", textAreaValue: "", usingExample: false, error: null });
      dispatch(getAllProjects());
      dispatch(closeDialog({name}));
      openSnackbar(`Project "${title}" created!`, 3000);
    }).catch((error) => {
      alert("There was an error creating the project.");
      console.log("error", error);
    });
  }

  async importMP(source, title) {
    const description = this.getCommentFromSource(source);
    await this.saveProject(source, title, description);
  }

  async importWNG(state) {
    const description = this.getCommentFromSource(state.code.source);
    await this.saveProject(state.code.source, state.code.title, description);
  }

  render() {
    let { dispatch } = this.props;
    return (
      <div>
        <div className="dialog-header">
          <h1>Import</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({ name }))}></span>
        </div>
        <div className="dialog-body">
          <ExampleDropdown creatingProject={true} dialogName={ name }/>
          <div className="importContainer">
            Or
          </div>
          <div className="importContainer">
            <label>Load .mp or .wng file:</label>
            <input type="file" className="import-upload-file" accept={validExtensions.join(",")} ref={e => this._fileInputEl = e} onChange={async e => {
              e.preventDefault();
              const file = e.target.files[0];
              const extension = `.${file.name.split(".").pop().toLowerCase()}`;
              if (!validExtensions.includes(extension)) {
                // this shouldn't be possible, but some browsers...
                // TODO: let user know they selected a bad filetype and try again
                console.log("error wrong file type - extension=", extension);
                return;
              }
              const title = file.name.replace(/\.+$/g, "");
              const source = await new Promise<string>(resolve => {
                const reader = new FileReader();
                reader.onload = (u) => resolve(reader.result);
                reader.readAsText(file);
              });
              if (extension === ".mp") {
                await this.importMP(source, title);
              } else if (extension === ".wng") {
                await this.importWNG(JSON.parse(source));
              }
              await dispatch(closeDialog({ name }));
            }} />
          </div>
        </div>
      </div>
    );
  }
};

let ReduxDashboardImport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog",
  startStyle: (dialogStyleEvent) => {
    let { dialogComponent, nodes: { container }} = dialogStyleEvent;
    let { target } = dialogComponent.props;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 250ms, top 250ms`,
      transitionTimingFunction: `ease`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let { dialogComponent, nodes: { container }} = dialogStyleEvent;
    let { target } = dialogComponent.props;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${window.innerHeight / 2 - container.offsetHeight / 2}px`,
      transition: `left 250ms, top 250ms`,
      transitionTimingFunction: `ease`,
    };
  },
})(DashboardImport);

let mapStateToProps = (state, ownProps) => {
  return {

  };
};

export default withSnackbar(connect(mapStateToProps)(ReduxDashboardImport));
