import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { toggleDialog } from "../../../actions/dialog";
import axios from "axios";
import { getAllProjects } from "../../../actions/projects";

export class ProjectListItem extends React.Component<any, any> {

  defaultThumbnailUrl = "/images/thumbnail-placeholder.jpg";

  constructor(props) {
    super(props);

    const { listType } = props;

    this.state = {
      thumbnail: null,
      listType: listType
    };

    this.formatDate = this.formatDate.bind(this);
    this.handleSettingsClicked = this.handleSettingsClicked.bind(this);
    this.handleDuplicateProject = this.handleDuplicateProject.bind(this);
    this.renderLargeList = this.renderLargeList.bind(this);
    this.renderSmallList = this.renderSmallList.bind(this);
  }

  async componentDidMount(): void {
    this.getThumbnail();
    // Make react tooltips work for this component
    setTimeout(ReactTooltip.rebuild, 0);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.state.listType !== this.props.listType) {
      this.setState({listType: this.props.listType});
    }
  }

  formatDate(timestamp: string) {
    const date = new Date(timestamp);
    const formattedDate =
      new Intl.DateTimeFormat("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).format(date);
    return formattedDate;
  }


  async handleSettingsClicked(e) {
    e.preventDefault();
    const { dispatch, user, projectItem } = this.props;

    if (projectItem.permission === "owner") {
      dispatch(toggleDialog({
        name: "dialogSettings",
        props: {
          user: user,
          project: projectItem,
          projectId: projectItem.id,
          editingName: false,
          editingDescription: false
        }
      }));
    } else if (projectItem.permission === "collaborator") {
      dispatch(toggleDialog({
        name: "dialogCollaboratorSettings",
        props: {
          user: user,
          project: projectItem,
          projectId: projectItem.id
        }
      }));
    }
  }

  // Gets project's thumbnail/snapshot from server
  async getThumbnail() {
    let { user, projectItem } = this.props;

    return await axios({
      method: `GET`,
      url: `api/project/snapshot?projectId=${projectItem.id}&userId=${user.id}`
    }).then((res) => {
      // Convert HTML readable image
      const base64image = "data:image/png;base64, " + res.data.image; 
      this.setState({thumbnail: res.data.image ? base64image : this.defaultThumbnailUrl});
    }).catch((error) => {
      console.log("error", error);
      this.setState({thumbnail: this.defaultThumbnailUrl});
    });
  }

  handleDuplicateProject(e) {
    e.preventDefault();
    const { dispatch, user, projectItem } = this.props;
    const { name, description, code } = projectItem;

    axios({
      method: `POST`,
      url: `/api/project/create`,
      data: {
        userId: user.id,
        name,
        description,
        code
      }
    }).then((response) => {
      dispatch(getAllProjects());
    });
  }

  renderLargeList() {
    const { projectItem } = this.props;
    const { thumbnail } = this.state;

    return (
      <li className="project-list-item" >
        <div className="snapshot-container">
          <img src={thumbnail ? thumbnail : this.defaultThumbnailUrl}/>
        </div>
        <div className="project-info-container" >
          <span className="project-name">{projectItem.name}</span>
          <span className="project-description">{projectItem.description}</span>
          <span className="project-updated-at">
                <span className="label">last updated: </span>
            {this.formatDate(projectItem.updatedAt)}
              </span>
          <span className="project-role">
                <span className="label">
                  role: </span>{ projectItem.permission }
                </span>

          <div className="project-buttons">
            <button
              data-place="bottom"
              data-tip="Open Project">
              <i className="icon fas fa-external-link-alt"/>
            </button>

            {/* Only the owner can open project settings */}
            {(projectItem.permission === "owner" || projectItem.permission === "collaborator") ?
              <button
                onClick={this.handleSettingsClicked}
                data-place="bottom"
                data-tip="Project Settings">
                <i className="icon fas fa-cog"/>
              </button> : null
            }

            {(projectItem.permission === "owner" || projectItem.permission === "collaborator") ?
              <button
                onClick={this.handleDuplicateProject}
                data-place="bottom"
                data-tip="Duplicate this Project">
                <i className="icon far fa-clone"/>
              </button> : null
            }

          </div>
        </div>
      </li>
    );
  }

  renderSmallList() {
    const { projectItem } = this.props;
    const { listType } = this.state;

    return <li className="project-list-item" id="small" style={{marginBottom: 0, padding: 0, display: listType === "large" ? "none" : "flex"}}>
      <div className="project-info-container" id="small">
        <span className="project-name flex-1"  style={{marginBottom: 0}}>{projectItem.name}</span>
        <span className="project-updated-at flex-1" style={{marginBottom: 0}}>
              {this.formatDate(projectItem.updatedAt)}
            </span>
        <span className="project-role flex-1" style={{marginBottom: 0}}>
              { projectItem.permission }
              </span>

        <div className="project-buttons flex-1" style={{marginLeft: "auto", display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
          <button
            data-place="bottom"
            data-tip="Open Project">
            <i className="icon fa fa-external-link-alt"/>
          </button>

          {/* Only the owner can open project settings */}
          {(projectItem.permission === "owner" || projectItem.permission === "collaborator") ?
            <button
              onClick={this.handleSettingsClicked}
              data-place="bottom"
              data-tip="Project Settings">
              <i className="icon fa fa-cog"/>
            </button>
            : null
          }

          {(projectItem.permission === "owner" || projectItem.permission === "collaborator") ?
            <button
              onClick={this.handleDuplicateProject}
              data-place="bottom"
              data-tip="Duplicate this Project">
              <i className="icon fa fa-clone"/>
            </button>
            : null
          }

        </div>
      </div>
    </li>;
  }

  render() {
    // projectItem is passed from Dashboard
    const { projectItem } = this.props;
    const { listType } = this.state;

    return(
      <Link to={`/project/${projectItem.uuid}`}>
        {
          listType === "large" ? this.renderLargeList() : this.renderSmallList()
        }
      </Link>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  const { user } = state;
  return {
    user
  };
};

export default connect(mapStateToProps)(ProjectListItem);
