import {merge} from "lodash";
import {DIALOG_OPEN, DIALOG_CLOSE} from "../../actions/dialog.types";

export let reducer = (state = {}, action) => {
  switch (action.type) {
    case DIALOG_OPEN: {
      let nextState = merge({}, state);
      nextState[action.name] = merge({}, nextState[action.name], {
        isOpen: true,
      }, action.props);
      return nextState;
    }
    case DIALOG_CLOSE: {
      let nextState = merge({}, state);
      nextState[action.name] = merge({}, nextState[action.name], {
        isOpen: false,
      }, action.props);
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;

