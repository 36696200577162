import { schema } from "normalizr";

// define schemas representing entity relations
// see - https://github.com/gaearon/normalizr

export let node = new schema.Entity("nodes", {}, { idAttribute: "guid" });

export let link = new schema.Entity("links", {
    source: node,
    target: node,
}, { idAttribute: "guid" });

export let trace = new schema.Entity("traces", {
    nodes: [node],
    links: [link],
}, { idAttribute: "guid" });
