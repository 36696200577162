import * as go from "gojs";

export interface ViewTemplateOptions {
  onShiftClick: {(guid: string): void};
}

let $ = go.GraphObject.make;

export let adStartNodeTemplate = (options: ViewTemplateOptions) => {
  return $(go.Node, "Auto",
    { cursor: "move", layerName: "Foreground" },
    new go.Binding("position", "position").makeTwoWay(),
    {
      click: (e, obj, prev) => {
        if (e.shift) {
          options.onShiftClick(obj.part.data.key);
        }
      },
    },
    $(go.Shape, "Circle", { fill: "#313947", width: 20 }),
  );
};

export let adEndNodeTemplate = (options: ViewTemplateOptions) => {
  return $(go.Node, "Auto",
    { cursor: "move", layerName: "Foreground" },
    {
      click: (e, obj, prev) => {
        if (e.shift) {
          options.onShiftClick(obj.part.data.key);
        }
      },
    },
    new go.Binding("position", "position").makeTwoWay(),
    $(go.Shape, "Circle", { fill: null, stroke: "#313947", width: 30 }),
    $(go.Shape, "Circle", { fill: "#313947", width: 15, margin: 2.5 }),
  );
};


// Action Node Template
export let adActionNodeTemplate = (options: ViewTemplateOptions) => {
  return $(go.Node, "Auto",
    { cursor: "move", layerName: "Foreground", resizable: true, width: 70 },
    {
      click: (e, obj, prev) => {
        if (e.shift) {
          options.onShiftClick(obj.part.data.key);
        }
      },
    },
    new go.Binding("position", "position").makeTwoWay(),
    $(go.Shape, "RoundedRectangle",
    { fill: "#e6e6e6", parameter1: 2, stroke: "black"}),
    $(go.TextBlock,
      {font: "normal 12pt sans-serif", margin: 3, textAlign: "center"},
      new go.Binding("text", "name"),
    ),
  );

};

// Decision Node Template
export let adDecisionNodeTemplate = (options: ViewTemplateOptions) => {
  return $(go.Node, "Auto",
    { cursor: "move", layerName: "Foreground" },
    {
      click: (e, obj, prev) => {
        if (e.shift) {
          options.onShiftClick(obj.part.data.key);
        }
      },
    },
    new go.Binding("position", "position").makeTwoWay(),
    $(go.Shape, "Diamond", { fill: "#fcfcfc", width: 40, height: 30, stroke: "black"}),
  );
};

// Bar Node Template
export let adBarNodeTemplate = (options: ViewTemplateOptions) => {

  return $(go.Node, "Auto",
    { cursor: "move", layerName: "Foreground" },
    new go.Binding("position", "position").makeTwoWay(),
    {
      click: (e, obj, prev) => {
        if (e.shift) {
          options.onShiftClick(obj.part.data.key);
        }
      },
    },
    $(go.Shape, "Rectangle", 
      { fill: "grey", stroke: null, height: 18 },
      new go.Binding("width", "barWidth").makeTwoWay()
    ),
    $(go.TextBlock,
      {
        font: "normal 9pt sans-serif",
        stroke: "white",
        textAlign: "center",
        margin: new go.Margin(3, 0, 3, 0),
      },
      new go.Binding("text", "name"),
    ),
  );
};