export const NODE_UPDATE_NODE = "NODE_UPDATE_NODE";

export type NodeType = "ROOT"|"COMPOSITE"|"ATOM"|"SAY"|"REPORT"|"TABLE"|"GANTT_CHART"|"BAR_CHART"|"SYSML_GROUP"|"SYSML_TABLE"|"AD_GROUP"|"AD_START"|"AD_END"|"AD_DECISION"|"AD_ACTION"|"AD_BAR"|"GRAPH_GROUP"|"GRAPH_NODE";

interface NodeNoRelations {
  guid: string;
  name: string;
  type: NodeType;
  isView?: boolean;
  isGroup?: boolean;
  group?: string|undefined;
  x: number;
  y: number;
  originalX?: number|undefined; // Used for Graphs, so nodes can go back to original position on refresh
  originalY?: number|undefined; // Same as originalX
  column: number;
  row: number;
  collapsed?: boolean;
  hasCollapsedParent?: boolean;
  hidden: boolean;
  avoidNodes?: null|boolean; // True if this node has a link that needs to re-direct around another/other node(s) to prevent overlapping
  headers?: Array<any>|undefined;
  items?: Array<any>;
  itemArray?: Array<any>|undefined; // Array of items to be placed inside the node (example: bars inside a bar_graph)
  titleArray?: Array<any>|undefined;
  width?: number|undefined;
  textBlockWidth?: number|undefined;
  height?: number|undefined;
  barWidth?: number|null; // Width of bar nodes in activity diagrams
  originalWidth?: number|null|undefined; // The width of the node on first load
  originalHeight?: number|null|undefined; // The height of the node on first load
  spansRows?: boolean|undefined; // True if this node has a link going out that spans multiple rows in the same column
}

export interface NormalizedNode extends NodeNoRelations {

}

export interface Node extends NodeNoRelations {

}

export interface NodesEntity { [guid: string]: NormalizedNode; }
