import * as React from "react";

import { runTour } from "../tour";
import { closeDialog } from "../actions/dialog";
import reduxDialog from ".";
import { connect } from "react-redux";
import { beginTour } from "../actions/ui";

export let name = "dialogHelp";

export class Export extends React.Component<any, any> {

  state = {
    runTourError: false
  };

  constructor(params) {
    super(params);
  }

  // Starts the Shepherd.js tour
  async startTour() {

    const { 
      match, 
      dispatch, 
      currentProject, 
      user
    } = this.props;

    // If user is logged in
    if (user !== null) {

      // If user is on a project page, simply run tour:
      if (match && match.params.projectUUID) {
        runTour(dispatch);
        await dispatch(closeDialog({name}));
        return;
      }

      // If there's a current project, redirect to it
      if (currentProject !== null) {
        await dispatch(beginTour());
        window.location.href = `/project/${currentProject.uuid}`;
      }

      // If there is no selected project:
      else {
        this.setState({runTourError: true});
      }

    }

    else {
      runTour(dispatch);
      await dispatch(closeDialog({name}));
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.runTourError && this.state.runTourError) {
      this.setState({runTourError: false})
    }
  }

  render() {
    let { dispatch } = this.props;
    return (
      <div>
        <div className="dialog-header">
          <h1>Help</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({name}))}/>
        </div>
        <div className="dialog-body">
          <div className="help-view-container">
            <h2>New to Monterey Phoenix?</h2> 
            <button className="button button-blue" onClick={() => this.startTour()}>
              Start Tour
            </button>
            {this.state.runTourError ? 
              <p className="error">
                You do not have a current project selected. Please select a project and then try running the tour again.
              </p> : null}
            <h2>Monterey Phoenix Language</h2>
            <h3>For help with the Monterey Phoenix language visit: <a href="https://wiki.nps.edu/display/MP" target="_blank">Monterey Phoenix website</a></h3>
            Where you can find complete documentation and tutorials.
            <hr/>
            <div className="legend-container">
              <div className="help-view-graph-legend">
                <h2>Graph Legend</h2>
                <ul className="help-view-event-legend">
                  <li><span className="legendBox" style={{backgroundColor: "#31A354", border: `0`}}/><span>Root</span>
                  </li>
                  <li><span className="legendBox"
                            style={{backgroundColor: "#FD8D3C", border: `0`}}/><span>Composite</span></li>
                  <li><span className="legendBox" style={{backgroundColor: "#3B73AF", border: `0`}}/><span>Atom</span>
                  </li>
                  <li><span className="help-view-relationship-in-icon" style={{border: `0`}}/><span>IN relationship between events</span>
                  </li>
                  <li><span className="help-view-relationship-follows-icon" style={{border: `0`}}/><span>FOLLOWS relationship between events</span>
                  </li>
                </ul>
              </div>
            </div>
            <h2>Import Files</h2>
            <p>Use the Import button and controls, upper left, to load new code examples. To Load new files:</p>
            <ol style={{ marginTop: `4px` }}>
              <li style={{ marginBottom: `2px`, marginLeft: `0px` }}>1. Click the Import button</li>
              <li style={{ marginBottom: `2px`, marginLeft: `0px` }}>2. Select an example from the dropdown menu provided, or Load a file using the “choose file” option.</li>
              <li style={{ marginBottom: `2px`, marginLeft: `0px` }}>3. After selecting import option or file, click the Run button. This will execute the code and generate new traces.</li>
              <li style={{ marginBottom: `2px`, marginLeft: `16px` }}>a. Note: Code panel or the Split view panels must be open in order to access the Run button.</li>
            </ol>
            <h2>Export Files</h2>
            <p>Use the Export button, upper left (next to Import) to export your code, graphs and images. To export:</p>
            <ol style={{ marginTop: `4px` }}>
              <li style={{ marginBottom: `2px`, marginLeft: `0px` }}>1. Click the Export button</li>
              <li style={{ marginBottom: `2px`, marginLeft: `0px` }}>2. Choose from any of the export options: </li>
              <li style={{ marginBottom: `2px`, marginLeft: `16px` }}>a. "Export as .mp" will save your code as a text (.mp) file.</li>
              <li style={{ marginBottom: `2px`, marginLeft: `16px` }}>b. "Export as .wng" will save your code and all graph data in a single text (.wng) file.</li>
              <li style={{ marginBottom: `2px`, marginLeft: `16px` }}>c. "Current Trace" will save the currently selected trace as a .png image.</li>
              <li style={{ marginBottom: `2px`, marginLeft: `16px` }}>d. "Selected Diagrams" will save all selected graph items as a .png image.</li>
              <li style={{ marginBottom: `2px`, marginLeft: `16px` }}>e. "All Traces (zip)" will save a zip containing .png images of all traces.</li>
              <li style={{ marginBottom: `2px`, marginLeft: `16px` }}>f. "All Marked Traces (zip)" will save a zip containing .png images of all marked traces.</li>
            </ol>
            <br/>
            <hr/>
            <h2>Working with Events</h2>
            <p>
              All Events have a menu icon buttons on the left. Click to display available functions/options for the event, such as Show/Hide, Expand/Collapse, etc. These menus are contextual and vary by event type. Atom events, for example, cannot be collapsed and so their menus will only contain Show/Hide options.
            </p>
            <br/>
            <p>
            After collapsing an event, a Dot will appear indicating that this node has been collapsed. Use the event’s menu to expand again as needed.
            </p>
            <br/>
            <img src="/images/help-hamburger.png"/>
            <hr/>
            <h2>View Options</h2>
            <p>The diagram view options menu is located at the top right corner of the graph window.</p>
            <br/>
            <img src="/images/options.png"/>
            <br/>
            <ul>
              <li><b>"Show Hidden"</b> is checked by default. This allows visibility of hidden or collapsed nodes. If "Show Hidden" is checked, hidden nodes will be visible but faded:
              <br/><img src="/images/hidden-nodes.png" style={{width: "200px"}}/>
              </li>
              <li><b>"Swim Lanes"</b> switches the diagram's current layout to the "Swim Lanes" layout, where Root nodes become table headers, and Composite and Atom nodes are organized into table columns:
              <br/><img src="/images/swim-lanes.png" style={{width: "300px"}}/>
              </li>
            </ul>
            <hr/>
            <h2>Sort Options</h2>
            <p>The sort options are located at the top of navigation sidebar, and control the order in which traces in the sidebar appear.</p>
            <br/>
            <img src="/images/sort.png"/>
            <br/>
            <ul>
              <li>By checking <b>"Marked"</b>, marked traces will appear at the top. Marked traces are marked with an orange circle in the left-hand corner:
                <br/><img src="/images/sort-marked.png"/>
                <br/><img src="/images/marked-trace.png"/>
              </li>
              <li>The <b>"Events"</b> option sorts traces by the number of events in each trace. "Asc" sorts the traces in order from the least number events to the most, "Desc" sorts them between the most number of events to the least. To not sort by events at all, leave this selection blank.
                <br/><img src="/images/sort-events.png"/>
              </li>
              <li>The <b>"Probability"</b> option sorts traces by their probability displayed on the top of the trace. "Lowest" displays traces with the lowest probability first, and "Highest" displays the highest first.
                <br/><img src="/images/sort-probability.png"/>
                <br/><img src="/images/probability-location.png"/>
              </li>
            </ul>
            <hr/>
            <img src="/images/help-import.png"/>
            <hr/>
            <h2>Keyboard Shortcuts</h2>
            <h3>Code Window Shortcuts</h3>
            <table>
              <thead>
              <tr>
                <th width="65%" scope="col">To Perform These Actions</th>
                <th width="35%" scope="col">Use These Windows Commands</th>
                <th width="35%" scope="col">Use These Mac Commands</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Show Hints for existing events or keywords</td>
                <td><kbd className="light">CTRL</kbd> + <kbd className="light">Spacebar</kbd></td>
                <td><kbd className="light">CMD</kbd> + <kbd className="light">Spacebar</kbd></td>
              </tr>
              <tr>
                <td>Copy text</td>
                <td><kbd className="light">CTRL</kbd> + <kbd className="light">c</kbd></td>
                <td><kbd className="light">CMD</kbd> + <kbd className="light">c</kbd></td>
              </tr>
              <tr>
                <td>Cut text (copy and delete)</td>
                <td><kbd className="light">CTRL</kbd> + <kbd className="light">x</kbd></td>
                <td><kbd className="light">CMD</kbd> + <kbd className="light">x</kbd></td>
              </tr>
              <tr>
                <td>Paste text</td>
                <td><kbd className="light">CTRL</kbd> + <kbd className="light">v</kbd></td>
                <td><kbd className="light">CMD</kbd> + <kbd className="light">v</kbd></td>
              </tr>
              <tr>
                <td>Find</td>
                <td><kbd className="light">CTRL</kbd> + <kbd className="light">f</kbd></td>
                <td><kbd className="light">CMD</kbd> + <kbd className="light">f</kbd></td>
              </tr>
              <tr>
                <td>Find Next</td>
                <td><kbd className="light">CTRL</kbd> + <kbd className="light">g</kbd></td>
                <td><kbd className="light">CMD</kbd> + <kbd className="light">g</kbd></td>
              </tr>
              <tr>
                <td>Find Previous</td>
                <td><kbd className="light">Shift</kbd> + <kbd className="light">CTRL</kbd> + <kbd className="light">g</kbd></td>
                <td><kbd className="light">Shift</kbd> + <kbd className="light">CMD</kbd> + <kbd className="light">g</kbd></td>
              </tr>
              <tr>
                <td>Replace</td>
                <td><kbd className="light">Shift</kbd> + <kbd className="light">CTRL</kbd> + <kbd className="light">f</kbd></td>
                <td><kbd className="light">Shift</kbd> + <kbd className="light">CMD</kbd> + <kbd className="light">f</kbd></td>
              </tr>
              <tr>
                <td>Replace All</td>
                <td><kbd className="light">Shift</kbd> + <kbd className="light">CTRL</kbd> + <kbd className="light">r</kbd></td>
                <td><kbd className="light">Shift</kbd> + <kbd className="light">CMD</kbd> + <kbd className="light">Option</kbd> + <kbd className="light">f</kbd></td>
              </tr>
              <tr>
                <td>Persistent Search</td>
                <td><kbd className="light">ALT</kbd> + <kbd className="light">p</kbd></td>
                <td><kbd className="light">Option</kbd> + <kbd className="light">p</kbd></td>
              </tr>
              <tr>
                <td>Jump to Line</td>
                <td><kbd className="light">ALT</kbd> + <kbd className="light">g</kbd></td>
                <td><kbd className="light">Option</kbd> + <kbd className="light">g</kbd></td>
              </tr>
              </tbody>
            </table>
            <h3>Graph Window Shortcuts</h3>
            <table>
              <thead>
              <tr>
                <th width="65%" scope="col">To Perform These Actions</th>
                <th width="35%" scope="col">Use These Windows Commands</th>
                <th width="35%" scope="col">Use These Mac Commands</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Select and Unselect Individual Events</td>
                <td><kbd className="light">CTRL</kbd> and Click</td>
                <td><kbd className="light">CMD</kbd> and Click</td>
              </tr>
              <tr>
                <td>Select Multiple Events</td>
                <td colSpan={3}><kbd className="light">Shift</kbd> and drag cursor around multiple events</td>
              </tr>
              <tr>
                <td>Select all Events</td>
                <td><kbd className="light">CTRL</kbd> + <kbd className="light">A</kbd></td>
                <td><kbd className="light">CMD</kbd> + <kbd className="light">A</kbd></td>
              </tr>
              <tr>
                <td>Unselect All Events</td>
                <td colSpan={3}>Click anywhere in whitespace in graph region</td>
              </tr>
              <tr>
                <td>Select a related group of events</td>
                <td colSpan={3}><kbd className="light">Shift</kbd> and left-click Root or Composite event</td>
              </tr>
              <tr>
                <td>Drag or Reposition Entire Graph</td>
                <td colSpan={3}>Click and Drag anywhere in whitespace in graph region</td>
              </tr>
              <tr>
                <td>Zoom In and Zoom Out</td>
                <td colSpan={3}>Use the <kbd className="light">+</kbd> and <kbd className="light">-</kbd> keys</td>
              </tr>
              <tr>
                <td>Undo / Redo move events</td>
                <td><kbd className="light">CTRL</kbd> + <kbd className="light">Z</kbd> / <kbd
                  className="light">CTRL</kbd> + <kbd className="light">Y</kbd></td>
                <td><kbd className="light">CMD</kbd> + <kbd className="light">Z</kbd> / <kbd
                className="light">CMD</kbd> + <kbd className="light">Y</kbd></td>
              </tr>
              <tr>
                <td>Open context menu for an Event</td>
                <td colSpan={2}>Hover any event and right-click</td>
              </tr>
              <tr>
                <td>Show all hidden events</td>
                <td><kbd className="light">ALT</kbd> + <kbd className="light">H</kbd></td>
                <td><kbd className="light">Option</kbd> + <kbd className="light">H</kbd></td>
              </tr>
              <tr>
                <td>Show/Hide selected event</td>
                <td colSpan={3}>Press <kbd className="light">H</kbd> on selected event.</td>
              </tr>
              <tr>
                <td>Collapse/Expand selected event</td>
                <td colSpan={3}>Press <kbd className="light">C</kbd> on selected event.</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
};

let ReduxExport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog",
  startStyle: (dialogStyleEvent) => {
    let {nodes: {container}} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let {nodes: {container}} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `76px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
})(Export);

let mapStateToProps = (state, ownProps) => {
  const { user, currentProject } = state;
  return { user, currentProject };
};

export default connect(mapStateToProps)(ReduxExport);
