import * as React from "react";

import { closeDialog } from "../actions/dialog";
import reduxDialog from ".";
import { connect } from "react-redux";

export let name = "data-warning";

export class Export extends React.Component<any, any> {

  constructor(params) {
    super(params);
  }

  render() {
    let { dispatch } = this.props;
    return (
      <div>
        <div className="dialog-header" style={{paddingBottom: "3px", lineHeight: "24px"}}>
          <h1><span style={{
            backgroundColor: "#CC3333",
            color: "white",
            borderRadius: "15px",
            paddingLeft: "10px",
            padding: "2px 10px",
            fontSize: "16px",
            marginRight: "5px",
            position: "relative",
            bottom: "2px"
            }}>!</span> Model Too Large</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({name}))}/>
        </div>
        <div className="dialog-body" style={{fontSize: "16px", padding: "20px"}}>
          <p>The output has exceeded the browser's quota, try one of the following:</p><br/>
          <ul style={{marginLeft: "25px", listStyleType: "disc"}}>
            <li>reduce the scope, or</li>
	          <li>reduce the schema complexity to generate fewer traces (reduce or remove nested iterations, optional events, alternative events, or</li>
            <li>if generating reports, tables or charts for individual traces, make sure you CLEAR them so they contain results for only that one trace, or</li>
            <li>install and run your model locally on <a href="https://gitlab.nps.edu/monterey-phoenix/user-interfaces/gryphon" target="_blank">MP-Gryphon</a></li>
          </ul>
        </div>
      </div>
    );
  }
};

let ReduxExport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog",
  startStyle: (dialogStyleEvent) => {
    let {nodes: {container}} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let {nodes: {container}} = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `76px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
})(Export);

let mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(ReduxExport);
