import * as React from "react";
import { connect } from "react-redux";
import * as CodeMirror from "codemirror";

require("codemirror/addon/search/search.js");
require("codemirror/addon/search/searchcursor.js");
require("codemirror/addon/hint/show-hint.js");
require("codemirror/addon/hint/javascript-hint.js");
require("codemirror/addon/search/jump-to-line.js");
require("codemirror/addon/dialog/dialog.js");
require("codemirror/addon/dialog/dialog.css");

import { socketConnect, socketDisconnect } from "../../../actions/socket";
import { socket } from "../../../socket/middleware";
import { updateSource } from "../../../actions/code";
import Draggable from "../../../component/draggable";
import { updateSplitScreenSizes } from "../../../actions/ui";

export class SourceText extends React.Component<any, any> {
  _codeMirror: HTMLElement;
  _codeMirrorReplica: HTMLElement;
  codeMirror: CodeMirror.Editor;
  codeMirrorReplica: CodeMirror.Editor;
  //_marker: CodeMirror.TextMarker;
  _errorMarker: HTMLElement;
  _cursorMarker: HTMLElement;
  _timeout: ReturnType<typeof setTimeout>;

  _cursorMarkers: {
    marker: HTMLElement
  } | {} = {};

  state = {
    displayParseError: false,
    cursorPos: { line: 0, ch: 0 },
    editingUsers: {},
    leftEditor: "50%",
    rightEditor: "50%"
  };

  constructor(props) {
    super(props);
    const { leftEditor, rightEditor } = this.props;
    this.state.leftEditor = leftEditor;
    this.state.rightEditor = rightEditor;

    this.addSocketEvents = this.addSocketEvents.bind(this);
    this.socketJoinRoom = this.socketJoinRoom.bind(this);
    this.handleKeyboardShortcuts = this.handleKeyboardShortcuts.bind(this);
    this.onEditorChanged = this.onEditorChanged.bind(this);
    this.onEditorBeforeChange = this.onEditorBeforeChange.bind(this);
    this.onCursorChanged = this.onCursorChanged.bind(this);
    this.createCursorMarker = this.createCursorMarker.bind(this);
    this.createErrorMarker = this.createErrorMarker.bind(this);
    this.handleMouseOverErrorMarker = this.handleMouseOverErrorMarker.bind(this);
    this.handleMouseOutErrorMarker = this.handleMouseOutErrorMarker.bind(this);
    this.syntaxHighlightRootsAndCompositesInCode = this.syntaxHighlightRootsAndCompositesInCode.bind(this);
    this.handleCodeSplitDrag = this.handleCodeSplitDrag.bind(this);
    this.handleCodeSplitDragStop = this.handleCodeSplitDragStop.bind(this);
    this.setUpEditor = this.setUpEditor.bind(this);
  }

  createCodeEditor = (element) => {
    const { currentProject, darkMode, wordWrapEnabled, source } = this.props;
    return CodeMirror(element, {
      mode: "montereyphoenix",
      lineNumbers: true,
      lineWrapping: wordWrapEnabled ? true : false,
      gutters: ["CodeMirror-lint-markers"],
      matchBrackets: true,
      lint: true,
      smartIndent: true,
      indentUnit: 4,
      search: true,
      value: currentProject ? currentProject.code : source,
      highlightSelectionMatches: { showToken: /\w/, delay: 300 },
      theme: darkMode ? "base16-dark" : "default"
    });
  };

  setUpEditor() {
    if (process.env.JS_ENV === "browser") {
      let { currentProject, user, dispatch, splitScreen } = this.props;

      require("./languages/montereyphoenix");

      this.codeMirror = this.createCodeEditor(this._codeMirror);

      // If split screen option is selected, create second editor
      if (splitScreen) {
        this.codeMirrorReplica = this.createCodeEditor(this._codeMirrorReplica);
        this.codeMirrorReplica.swapDoc(this.codeMirror.linkedDoc({
          sharedHist: true
        }));
      }

      this.codeMirror.on("change", this.onEditorChanged);
      this.codeMirror.on("beforeChange", this.onEditorBeforeChange);
      this.codeMirror.on("keyHandled", this.handleKeyboardShortcuts);

      // Add workaround for persistent search. Use Alt-P instead of CodeMirror's Alt-F
      // because Alt-P is reserved in browsers
      const codeMirrorCopy = this.codeMirror;
      this.codeMirror.setOption("extraKeys", {
        "Alt-P": function (cm) {
          codeMirrorCopy.execCommand("findPersistent");
        }
      });

      // Show hints
      this.codeMirror.setOption("extraKeys", {
        "Cmd-Space":  (codemirror) => {
          codemirror.showHint(codemirror, null, {completeSingle: true});
        },
        "Ctrl-Space":  (codemirror) => {
          codemirror.showHint(codemirror, null, {completeSingle: true});
        },
      });


      // Set "show hints" option
      this.codeMirror.setOption("hint", true);

      this.syntaxHighlightRootsAndCompositesInCode();

      // Connect to socket
      dispatch(socketConnect())
      this.socketJoinRoom();

      // If user is logged in and has edit permission for project, set cursors:
      if (user !== null && currentProject && currentProject.permission !== "viewer") {
        this.codeMirror.on("cursorActivity", this.onCursorChanged);
      }

      if (socket) {
        this.addSocketEvents();
      }

    }
  }

  componentDidMount() {
    const { parseError } = this.props;

    this.setUpEditor();

    // Add existing parse errors on load
    if (parseError.line) {
      this.addErrorMarkers(parseError);
    }
  }

  // Joins a room if the user is logged in
  socketJoinRoom() {
    const { user, currentProject } = this.props;
    if (user !== null) {
      socket.emit("room:join", {
        projectUuid: currentProject.uuid,
        projectId: currentProject.id,
      });

    }
  }

  // Handle socket listeners/events here
  addSocketEvents() {
    const { editingUsers } = this.state;
    const { user } = this.props;

    // When another user joins
    socket.on("room:joined", (output) => {
      let nextEditingUsers = Object.assign(editingUsers, {});
      
      // Do not add own user
      if (output.userId !== user.id) {
        nextEditingUsers[output.userId] = {
          userName: output.userName,
          line: 0,
          ch: 0
        };
        this.setState({ editingUsers: nextEditingUsers });
  
        // Create cursor for user
        this.createCursorMarker(output);
      }

    });

    // When another user makes an update or moves their cursor
    socket.on("room:updated", (output) => {
      let nextEditingUsers = Object.assign(editingUsers, {});

      if (output.userId !== user.id) {

        // If user not added yet, add them:
        if (!nextEditingUsers[output.userId]) {
          nextEditingUsers[output.userId] = {
            userName: output.userName,
            line: 0,
            ch: 0
          };
          this.createCursorMarker(output);
        }

        else {
          nextEditingUsers[output.userId].ch = output.ch;
          nextEditingUsers[output.userId].line = output.line;
        }

        this.setState({ editingUsers: nextEditingUsers });

        // Update cursor marker
        this.codeMirror.addWidget({
          line: output.line,
          ch: output.ch
        }, this._cursorMarkers[output.userId], false);

        // If changes made, update codemirror code
        if (output.changes) {
          this.codeMirror.replaceRange(
            output.changes.text,
            { line: output.changes.from.line, ch: output.changes.from.ch },
            { line: output.changes.to.line, ch: output.changes.to.ch },
            "ignore"
          );
        }
      }

    });

    // When enother user leaves
    socket.on("room:userLeft", (output) => {
      let nextEditingUsers = Object.assign(editingUsers, {});
      delete nextEditingUsers[output.userId];
      this.setState({ editingUsers: nextEditingUsers });

      // Remove cursor
      if (this._cursorMarkers[output.userId]) {
        this._cursorMarkers[output.userId].remove();
        delete this._cursorMarkers[output.userId];
      }
    });
  }

  updateRoom(changes?) {
    const { cursorPos } = this.state;
    const { user, currentProject } = this.props;

    socket.emit("room:update", {
      changes: changes ? {
        from: changes.from,
        to: changes.to,
        text: changes.text,
        origin: changes.origin
      } : null,
      projectUuid: currentProject.uuid,
      projectId: currentProject.id,
      line: cursorPos.line,
      ch: cursorPos.ch
    });
  }

  // Add custom code here to handle keyboard shortcuts
  handleKeyboardShortcuts(instance, name, event) {
    const names = [
      "Ctrl-F",
      "Cmd-F",
      "Shift-Ctrl-F",
      "Cmd-Option-F",
      "Ctrl-G",
      "Cmd-G",
      "Shift-Ctrl-G",
      "Shift-Cmd-G",
      "Shift-Ctrl-R",
      "Shift-Cmd-Option-F",
      "Alt-F",
      "Alt-G"
    ];

  }

  removeErrorMarkers(line) {
    // Remove !/error icon
    this.codeMirror.setGutterMarker(parseInt(line) - 1, `CodeMirror-lint-markers`, null);
  }

  addErrorMarkers(error) {
    // Add !/error Icon
    this.codeMirror.setGutterMarker(parseInt(error.line) - 1, `CodeMirror-lint-markers`, this.createErrorMarker(error));
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      darkMode,
      parseError,
      source,
      wordWrapEnabled,
      splitScreen,
      importing
    } = this.props;

    const oldError = prevProps.parseError.line;
    const newError = parseError.line;

    // Update Codemirror theme when darkMode is toggled.
    if (darkMode !== prevProps.darkMode) {
      let theme = darkMode ? "base16-dark" : "default";
      this.codeMirror.setOption("theme", theme);
    }

    // Update word-wrap setting
    if (prevProps.wordWrapEnabled !== wordWrapEnabled) {
      this.codeMirror.setOption("lineWrapping", wordWrapEnabled);
    }

    // If parse error has changed:
    if (oldError !== newError) {
      // Error gone with no new error:
      if (oldError && !newError) {
        // Remove old error markers
        this.removeErrorMarkers(oldError);
      }
      // Going from one error to the next
      else if (oldError && newError && (oldError !== newError)) {
        // Remove old error
        this.removeErrorMarkers(oldError);
        // Add new error marker
        this.addErrorMarkers(parseError);
      }
      // New error with no error before it
      else if (!oldError && newError) {
        // Add error marker
        this.addErrorMarkers(parseError);
      }
    }

    // After importing, load new code into editor
    if (prevProps.importing && !importing) {
      let cursor = this.codeMirror.getCursor();
      let scrollInfo = this.codeMirror.getScrollInfo();
      this.codeMirror.setValue(source);
      this.codeMirror.setCursor(cursor);
      this.codeMirror.scrollTo(scrollInfo.left, scrollInfo.top);
      if (parseError.line) {
        let error = parseError;
        this.addErrorMarkers(error);
      }
    }

    if (splitScreen && splitScreen !== prevProps.splitScreen) {
      let cm = document.querySelector(".CodeMirror");
      cm.parentElement.removeChild(cm);
      this.setUpEditor();
    } else if (!splitScreen && prevProps.splitScreen) {
      this.setUpEditor();
    }

  }

  createErrorMarker(error) {
    if (this._errorMarker) {
      this._errorMarker.removeEventListener("mouseover", this.handleMouseOverErrorMarker);
      this._errorMarker.removeEventListener("mouseout", this.handleMouseOutErrorMarker);
    }
    this._errorMarker = document.createElement("span");
    this._errorMarker.className = "CodeMirror-lint-marker-error";
    this._errorMarker.attributes["data-message"] = error.message;
    this._errorMarker.addEventListener("mouseover", this.handleMouseOverErrorMarker);
    this._errorMarker.addEventListener("mouseout", this.handleMouseOutErrorMarker);
    return this._errorMarker;
  }

  // Creates a new element to display at a user's cursor
  createCursorMarker(user) {
    // Remove marker if it already exists
    if (this._cursorMarkers[user.userId]) {
      this._cursorMarkers[user.userId].removeChild(this._cursorMarkers[user.userId]);
    }
    this._cursorMarkers[user.userId] = document.createElement("div");
    const textNode = document.createElement("span");
    textNode.innerHTML = user.userName;
    this._cursorMarkers[user.userId].appendChild(textNode);
    this._cursorMarkers[user.userId].className = "CodeMirror-cursor-marker";
    this.codeMirror.addWidget({
      line: 0,
      ch: 0
    }, this._cursorMarkers[user.userId], false);
  }

  handleMouseOverErrorMarker() {
    this.setState({
      displayParseError: true,
    });
  }

  handleMouseOutErrorMarker() {
    this.setState({
      displayParseError: false,
    });
  }

  componentWillUnmount() {
    const { dispatch, user } = this.props;
    // Remove error markers
    if (this._errorMarker) {
      this._errorMarker.removeEventListener("mouseover", this.handleMouseOverErrorMarker);
      this._errorMarker.removeEventListener("mouseout", this.handleMouseOutErrorMarker);
    }

    dispatch(socketDisconnect());
  }

  // Runs everytime a change was made in the codemirror editor
  async onEditorChanged(obj, changes) {
    // changes where origin === "ignore" come from syncing the doc with sockets
    if (changes.origin !== "ignore") {
      const { dispatch, user, compiling, source, currentProject } = this.props;
      const nextSource = this.codeMirror.getValue();

      if (user !== null) {
        this.updateRoom(changes);
      }

      // Clear and set timeout to save changes after 2 seconds of no typing
      clearTimeout(this._timeout);
      this._timeout = setTimeout(async () => {
        let projectSource = source;
        if (currentProject) {
          projectSource = currentProject.code;
        }
        if (!compiling && projectSource !== nextSource) {
          dispatch(updateSource({ source: nextSource }));
        }
        if (!changes) {
          this.syntaxHighlightRootsAndCompositesInCode();
        }
      }, currentProject ? 1200 : 0);

    }

  }

  // Prevent user from making changes on a line that is being edited by another user
  onEditorBeforeChange(codeMirror, changes) {
    if (changes.origin !== "ignore") {
      const { editingUsers } = this.state;
      Object.keys(editingUsers).forEach(userId => {
        const user = editingUsers[userId];
        if (changes.from.line <= user.line && changes.to.line >= user.line) {
          changes.cancel();
        }
      });
    }
  }

  // Handles what happens when the user's cursor has changed selection
  async onCursorChanged(obj, changes) {
    const cursorPosition = obj.getCursor();
    await this.setState({ cursorPos: { line: cursorPosition.line, ch: cursorPosition.ch } })
    this.updateRoom();
  }

  handleCodeSplitDrag(e: MouseEvent) {
    const { leftEditor, rightEditor } = this.state;
    e.preventDefault();
    e.stopPropagation();

    if (e.movementX > 0) {
      const leftWidth = parseFloat(leftEditor);
      const rightWidth = parseFloat(rightEditor);
      const newLeftWidth = leftWidth + (e.movementX * 0.05);
      const newRightWidth = rightWidth - (e.movementX * 0.05);
      this.setState({ leftEditor: `${newLeftWidth}%`, rightEditor: `${newRightWidth}%` });
    } else {
      const leftWidth = parseFloat(leftEditor);
      const rightWidth = parseFloat(rightEditor);
      const newLeftWidth = leftWidth + (e.movementX * 0.05);
      const newRightWidth = rightWidth + (e.movementX * -0.05);
      this.setState({ leftEditor: `${newLeftWidth}%`, rightEditor: `${newRightWidth}%` });
    }
  }

  handleCodeSplitDragStop(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    const { dispatch } = this.props;
    const { leftEditor, rightEditor } = this.state;
    dispatch(updateSplitScreenSizes({ leftEditor, rightEditor }));
  }

  render() {
    let { displayParseError } = this.state;
    let { parseError, splitScreen } = this.props;
    const { leftEditor, rightEditor } = this.state;
    let displayParseErrorStyle = { left: `0px`, top: `0px` };

    if (this._errorMarker) {
      let { left, top } = this._errorMarker.getBoundingClientRect();
      displayParseErrorStyle = { left: `${left + this._errorMarker.offsetWidth}px`, top: `${top + this._errorMarker.offsetHeight}px` };
    }

    return (
      <span>
        {!displayParseError ? null : <div className="CodeMirror-lint-tooltip" style={displayParseErrorStyle}><span className="CodeMirror-lint-message-error"></span>{parseError.message}</div>}
        {
          splitScreen ?
            <div className="source-text" style={Object.assign({}, this.props.style, { display: "flex", flexDirection: "row", width: "100%" })}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "inherit" }}>
                <div style={{ width: leftEditor }} ref={(node) => { if (node !== null) this._codeMirror = node; }} />
                <div>
                  <Draggable onDragEvent={this.handleCodeSplitDrag} onStopEvent={this.handleCodeSplitDragStop}>
                    <span className="split-view-drag-area test" style={Object.assign({}, this.props.style, { right: "auto", width: "5px" })} />
                  </Draggable>
                </div>
                <div style={{ width: rightEditor }} ref={(node) => { if (node !== null) this._codeMirrorReplica = node; }} />
              </div>
            </div>
            :
            <div className="source-text" id="code-mirror" ref={(node) => { if (node !== null) this._codeMirror = node; }} style={this.props.style} />
        }
      </span>
    );
  }

  async syntaxHighlightRootsAndCompositesInCode() {
    let roots = {};
    let composites = {};

    Array.from(this._codeMirror.querySelectorAll(".cm-mp-root")).forEach(n => roots[n.innerHTML] = n);
    Array.from(this._codeMirror.querySelectorAll(".cm-mp-composite")).forEach(n => composites[n.innerHTML] = n);

    const atoms = Array.from(this._codeMirror.querySelectorAll(".cm-mp-atom"));

    atoms.forEach(atom => {
      if (roots[atom.innerHTML]) {
        atom.classList.remove(`cm-mp-atom`);
        atom.classList.add(`cm-mp-root`);
      } else if (composites[atom.innerHTML]) {
        atom.classList.remove(`cm-mp-atom`);
        atom.classList.add(`cm-mp-composite`);
      }
    });
  }

}


let mapStateToProps = (state, ownProps) => {
  let {
    code: { source },
    ui: { wordWrapEnabled, darkMode, splitScreen, leftEditor, rightEditor, importing },
    compiler: { parsing, compiling, output, compileError, parseError, status },
    currentProject,
    user
  } = state;
  return {
    darkMode,
    source,
    parsing,
    compiling,
    output,
    compileError,
    parseError,
    status,
    currentProject,
    user,
    wordWrapEnabled,
    splitScreen,
    leftEditor,
    rightEditor,
    importing
  };
};

export default connect(mapStateToProps)(SourceText);
