import { merge } from "lodash";

import * as NODE from "./node.types";

export let updateNode = (options: {
  node: Partial<NODE.NormalizedNode> & {
    guid: string,
}}) => {
  return async (dispatch, getState) => {
    dispatch(merge({}, options, {
      type: NODE.NODE_UPDATE_NODE,
    }));
  };
};
