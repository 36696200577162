import * as React from "react";


export class ProjectListHeader extends React.Component<any, any> {

  render() {
    return (
      <div className="small-list-header" >
        <div className="flex-1">
          <h3>Project Title</h3>
        </div>
        <div className="flex-1">
          <h3>Last Updated</h3>
        </div>
        <div className="flex-1">
          <h3>Role</h3>
        </div>
        <div className="flex-1">
          <h3></h3>
        </div>
      </div>
    );
  }

};
