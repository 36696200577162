import "regenerator-runtime/runtime";
import "event-source-polyfill";
import "core-js";

// import * as ES6Promise from "es6-promise";
// import * as smoothscroll from "smoothscroll-polyfill";

// smoothscroll.polyfill();
// ES6Promise.polyfill();

import * as gojs from "gojs";

(gojs.licenseKey as any) = process.env.GOJS_KEY;

import * as WebFont from "webfontloader";

if (process.env.JS_ENV === "browser") {
  WebFont.load({
    google: {
      families: ["Roboto"],
    },
  });
}

import * as React from "react";
import * as ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import SnackbarProvider from "react-simple-snackbar";

import { AppContainer } from "react-hot-loader";

import App from "./app";
import { initStore, loadStateFromLocalStorage } from "./store";
import examples from "../data/examples";

declare let window: any;
declare let module: any;

let { initialState } = window as any;
let savedState = loadStateFromLocalStorage();

const loadSavedState = () => {
  // Set user from initialState
  savedState.user = initialState.user;
  initialState = savedState;

  // If savedState is outdated (missing a newly added objects from redux):
  // update it to prevent bugs
  if (initialState.ui.showTooltips === undefined) {
    initialState.ui.showTooltips = true;
  }
  if (initialState.currentProject === undefined) {
    initialState.currentProject = null;
  }
  if (initialState.projects === undefined) {
    initialState.projects = null;
  }
  if (initialState.ui.wordWrapEnabled === undefined) {
    initialState.ui.wordWrapEnabled = false;
  }
  if (initialState.ui.darkMode === undefined) {
    initialState.ui.darkMode = false;
  }
  if (initialState.ui.grayscaleGraphs === undefined) {
    initialState.ui.grayscaleGraphs = false;
  }
}

// If not loading the default example
if (initialState.usingSeabirdCode) {

  const loadSeabirdCode = () => {
    initialState.code.source = initialState.seabird.source;
    initialState.code.title = initialState.seabird.title;
    initialState.compiler.output = "";
    initialState.compiler.completed = false;
    initialState.graph.hidingGraph = true;  
    initialState.ui.tourComplete = true;
    delete initialState.seabird;
  }

  // Send warning to user before loading seabird code
  if (savedState) {
    if (confirm("Importing Seabird code into the code editor will overwrite what is currently there. Do you want to proceed?")) {
      loadSeabirdCode();
    } else {
      // Load saved state
      loadSavedState();
    }
  }

  else {
    loadSeabirdCode();
  }

  // Remove querystring from URL
  const url = window.location.href.split("?")[0];
  window.history.pushState('', 'Monterey Phoenix', url);

}

else if (savedState) {
  loadSavedState();
}

let store = initStore(initialState);

let render = (App) => {
  let app =
    <Router>
      <Provider store={store}>
        <SnackbarProvider>
          <App/>
        </SnackbarProvider>
      </Provider>
    </Router>;

  if (process.env.NODE_ENV === "development") {
    app =
      <AppContainer key={Math.random()}>
        {app}
      </AppContainer>
  }
  ReactDOM.render(app, document.getElementById("content"));
};

require("shepherd.js/dist/css/shepherd.css");
require("../../css/src/app.scss");
require("@fortawesome/fontawesome-free/css/all.min.css");

if (process.env.NODE_ENV === "development") {
  // give stylesheet time to apply to document
  window.addEventListener("load", () => render(App));
} else {
  render(App);
}

if (module.hot) {
  module.hot.accept("./app", () => {
    let App = require("./app").default;
    render(App);
  });
}
