import * as uuid from "uuid";
import { NormalizedNode, NodeType } from "../node.types";
import { NormalizedLink } from "../link.types";
import { sortBy } from "lodash";

import { activityDiagram } from "./views/activityDiagram";
import { barChart } from "./views/barChart";
import { graph } from "./views/graph";
import { table } from "./views/table";

// Takes compiler view data (t[t.length - 1].views) and maps the arrays into objects that
// are compatible with the redux state.
// Returns object of view nodes by guid (nodes) and object of view nodes by compiler ID (nodesByCompileId)
export let mapViewsFromCompiler = (compilerViewData) => {
  let viewNodes: object = {};
  let viewLinks = [];
  // Keeps track and assigns row/columns to views depending on view type
  // (each view type gets it's own row)
  let rowColumn: { [key: string]: { row: number, column: number } } = {};
  // Keeps track of which view type will be on which row
  let rowByType: { [key: string]: number } = {};
  let currentRow: number = 0;

  // Place Activity Diagrams (AD) at the end of array
  compilerViewData = sortBy(compilerViewData, (o) => { return Object.keys(o)[0] === "AD"; });

  compilerViewData.forEach((v, i) => {

    let type: NodeType;
    let viewName = Object.keys(v)[0];

    switch (viewName) {
      case "REPORT": type = "REPORT"; break;
      case "TABLE": type = "TABLE"; break;
      case "BAR_CHART": type = "BAR_CHART"; break;
      case "GANTT_CHART": type = "GANTT_CHART"; break;
      case "AD": type = "AD_GROUP"; break;
      case "GRAPH": type = "GRAPH_GROUP"; break;
      default:
        type = "BAR_CHART";
    }

    // Assign this view type a row number
    if (!rowByType[viewName] && rowByType[viewName] !== 0) {
      rowByType[viewName] = currentRow;
      currentRow++;
    }

    // If this view type has no row yet:
    if (!rowColumn[viewName]) {
      rowColumn[viewName] = { row: 0, column: 0 };
    }

    else if (rowColumn[viewName]) {
      rowColumn[viewName].column = rowColumn[viewName].column + 1;
    }

    // view: [TBD]
    let viewNodeEntity: NormalizedNode = {
      guid: uuid.v4(),
      name: v[viewName][0],
      type,
      x: 0,
      y: 0,
      column: rowColumn[viewName].column,
      row: rowByType[viewName],
      collapsed: false,
      hidden: false,
      isView: true,
      isGroup: type.includes("GROUP") ? true : false,
      itemArray: viewName === "REPORT" ? v[viewName].slice(1) : [],
      width: 0,
      barWidth: null,
      spansRows: false
    };
    viewNodes[viewNodeEntity.guid] = viewNodeEntity;

    let res;
    switch (viewName) {
      case "AD":
        res = activityDiagram(viewNodes, viewLinks, v, viewNodeEntity);
        viewNodes = res.viewNodes;
        viewLinks = res.viewLinks;
        break;
      case "TABLE":
        res = table(viewNodes, v, viewNodeEntity);
        viewNodes = res.viewNodes;
        break;
      case "BAR_CHART":
        res = barChart(viewNodes, v, viewNodeEntity);
        viewNodes = res.viewNodes;
        break;
      case "GRAPH":
        res = graph(viewNodes, viewLinks, v, viewNodeEntity);
        viewNodes = res.viewNodes;
        viewLinks = res.viewLinks;
        break;
    }


  });

  return { viewNodes, viewLinks };

};
