import { DataType } from "sequelize-typescript";
import { 
  SET_CURRENT_PROJECT,
  UPDATE_CURRENT_PROJECT,
  RESET_CURRENT_PROJECT,
  UPDATE_CURRENT_PROJECT_SOURCE,
  SAVE_CURRENT_PROJECT_START,
  SAVE_CURRENT_PROJECT_END,
  SAVE_CURRENT_PROJECT_ERROR
} from "../../actions/currentProject.types";

interface Project {
  saving: boolean;
  saveError: string | null;
  code: string,
  createdAt: string,
  description: string,
  id: number
  name: string
  public: false
  updatedAt: string
  user: {username: string}
  userId: number
  uuid: DataType.UUID,
  permission: "collaborator" | "viewer" | "owner"
};

export let reducer = (state: Project | null = null, action) => {
  switch (action.type) {
    case SET_CURRENT_PROJECT: {
      return {
        ...action.project, 
        permission: action.permission,
        saving: false, 
        saveError: null
      };
    }
    case UPDATE_CURRENT_PROJECT: {
      let nextState = Object.assign(state, {});
      nextState = action.project;
      nextState.saving = false;
      nextState.saveError = null;
      nextState.permission = action.permission;
      return nextState;
    }
    case RESET_CURRENT_PROJECT: {
      return null;
    }
    case UPDATE_CURRENT_PROJECT_SOURCE: {
      let nextState = Object.assign({}, state);
      nextState.code = action.source;
      return nextState;
    }
    case SAVE_CURRENT_PROJECT_START: {
      let nextState = Object.assign({}, state);
      nextState.saving = true;
      nextState.saveError = null;
      return nextState;
    }
    case SAVE_CURRENT_PROJECT_END: {
      let nextState = Object.assign({}, state);
      nextState.saving = false;
      nextState.saveError = null;
      return nextState;
    }
    case SAVE_CURRENT_PROJECT_ERROR: {
      let nextState = Object.assign({}, state);
      nextState.saving = false;
      nextState.saveError = action.error;
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
