let { routerReducer } = require("react-router-redux");
import { combineReducers } from "redux";

let routing = routerReducer;

import dialogs from "./dialogs";
import entities from "./entities";
import ui from "./ui";
import currentTraceGuid from "./currentTraceGuid";
import code from "./code";
import compiler from "./compiler";
import graph from "./graph";
import sort from "./sort";
import batchExport from "./batchExport";
import pagination from "./pagination";
import version from "./version";
import currentProject from "./currentProject";
import projects from "./projects";
import user from "./user";

import * as IMPORT from "../actions/import.types";

let reducer = (state: any, action: any) => {
  let nextState = combineReducers({
    entities,
    routing,
    dialogs,
    ui,
    currentTraceGuid,
    code,
    compiler,
    graph,
    sort,
    batchExport,
    pagination,
    version,
    currentProject,
    projects,
    user
  })(state, action);

  // apply root level transformations here
  switch (action.type) {
    case IMPORT.IMPORT_WNG: {
      nextState = { ...state, ...action.state };
      break;
    }
  }

  return nextState;
};

export default reducer;
