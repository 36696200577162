export const GRAPH_SYNC_TO_REDUX = "GRAPH_SYNC_TO_REDUX";
export const GRAPH_ADD_TRACE = "GRAPH_ADD_TRACE";
export const GRAPH_ALL_TRACES_ADDED = "GRAPH_ALL_TRACES_ADDED";
export const GRAPH_SET_ALL_GOJS_DIAGRAMS = "GRAPH_SET_ALL_GOJS_DIAGRAMS";
export const GRAPH_SET_GOJS_DIAGRAM = "GRAPH_SET_GOJS_DIAGRAM";
export const GRAPH_INITIALIZE_GOJS_MODEL = "GRAPH_INITIALIZE_GOJS_MODEL";
export const HIDE_GRAPH = "HIDE_GRAPH";
export const SHOW_GRAPH = "SHOW_GRAPH";
export const CLEAR_GRAPHS = "CLEAR_GRAPHS";

export interface CompilerResponse {
    traces: [{}];
    GLOBAL: [{}];
}