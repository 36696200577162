import * as go from "gojs";

export const linkFollowsGraph = () => {
  let $ = go.GraphObject.make;
  let fill = "black";

  return $(go.Link, {
    reshapable: true,
    relinkableFrom: false,
    relinkableTo: false,
   // toShortLength: -2,
   // fromShortLength: -2,
    //curve: go.Link.Bezier,
    //routing: go.Link.Normal
  },
  //new go.Binding("points").makeTwoWay(),
    // new go.Binding("curviness", "", (data, node) => {
    //   const { curviness, points, text } = data;
    //   console.log(data);
    //   return 18;
    // }),
    $(go.Shape, { strokeWidth: 1.5, stroke: fill }),
    $(go.Shape, { toArrow: "standard", stroke: null, fill }),
    [
      $(go.Panel, "Auto",
        // $(go.Shape, { fill: "white", stroke: null}),
        $(go.TextBlock, "transition",  // the label text
          {
            background: "white",
            textAlign: "center",
            font: "14pt helvetica, arial, sans-serif",
            margin: 2,
            wrap: go.TextBlock.WrapFit
          },
          // editing the text automatically updates the model data
          new go.Binding("text"))
      )],
  );
};