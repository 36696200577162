import * as React from "react";
import { closeDialog } from "../../../actions/dialog";
import reduxDialog from "../../index";
import { connect } from "react-redux";
import SettingsExport from "./export";
import { withSnackbar } from "react-simple-snackbar";

export let name = "dialogCollaboratorSettings";

export class Index extends React.Component<any, any> {

  constructor(props) {
    super(props);

    const { project, editingName, editingDescription } = props;
    this.state = {
      project: project,
    };

    this.copyLink = this.copyLink.bind(this);
  }

  async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.project !== prevProps.project) {
      const { project } = this.props;
      this.setState({ project });
    }
  }

  async copyLink(e) {
    e.preventDefault();
    const { project } = this.state;
    const { openSnackbar } = this.props;
    const textToCopy = `${window.origin}/project/${project.uuid}`;
    await navigator.clipboard.writeText(textToCopy);
    openSnackbar(`Link copied!`, 3000);
  }

  render() {
    let { dispatch, project } = this.props;

    return (
      <div>
        <div className="dialog-header">
          <h1>{ project ? project.name : null }</h1>
          <span className="close_icon" onClick={() => dispatch(closeDialog({ name }))}></span>
        </div>
        <div className="dialog-body">
          <div className="settings-group">
            <div>
              <h2>Get a shareable link</h2>
              <div className="copy-link-container">
                {
                  project && project.public ?
                    <span className="copy-link-text">
                      <span className="copy-link-setting"><span className="copy-link-descriptor">Public:</span> Anyone with a link can view</span>
                    </span>
                    :
                    <span className="copy-link-text">
                    <span className="copy-link-setting">
                      <span className="copy-link-descriptor">Restricted:</span> Only added users can open the project with this link.
                    </span>
                  </span>
                }
                <span>
                <button className="create_project_button add-permission-button" onClick={this.copyLink}>Copy link</button>
              </span>
              </div>
            </div>
          </div>
          <div className="settings-group">
            <SettingsExport className="settings-group" project={project}></SettingsExport>
          </div>
        </div>
      </div>
    );
  }
}

let ReduxExport = reduxDialog({
  name,
  dialogClassNames: "optionsDialog settings-dialog",
  startStyle: (dialogStyleEvent) => {
    let { nodes: { container } } = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `${0 - container.offsetHeight - 10}px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  },
  endStyle: (dialogStyleEvent) => {
    let { nodes: { container } } = dialogStyleEvent;
    return {
      left: `${window.innerWidth / 2 - container.offsetWidth / 2}px`,
      top: `76px`,
      transition: `left 400ms, top 400ms`,
      transitionTimingFunction: `ease`,
    };
  }
})(Index);

export default withSnackbar(connect()(ReduxExport));
