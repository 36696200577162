import * as React from "react";
import { connect } from "react-redux";
import * as go from "gojs";
import * as classNames from "classnames";
import * as GoJSComponent from "../../../../../component/graph/gojs/index.browser";

import { selectTraceByGuid } from "../../../../../actions/trace";

export class GoJSSidebarComponent extends GoJSComponent.Component {

  _traceDetails;

  constructor(props) {
    super(props);
    this.postGenerateDiagram = this.postGenerateDiagram.bind(this);
  }

  diagramModelChangedListener(modelChangedEvent) {
    // do nothing
  }

  postGenerateDiagram() {
    this.diagram.autoScale = go.Diagram.Uniform;
    this.diagram.isEnabled = false;
    this.diagram.contentAlignment = go.Spot.Center;
  }

  render() {
    const {
      traceGuid,
      currentTraceGuid,
      dispatch,
      normalizedTrace,
      splitSizes: { right: rightWidth }
    } = this.props;
    let tracePreviewRatio = .9;

    return (
      <li
        style={{height: `${ (rightWidth / 100 * window.innerWidth) * tracePreviewRatio }px`}}
        className={`trace ` + classNames({
          "trace-selected": traceGuid === currentTraceGuid,
          "marked": normalizedTrace ? normalizedTrace.marked : false,
        })}
        onClick={(e) => dispatch(selectTraceByGuid({ guid: traceGuid }))}
        >

        <div className={`trace-details`} ref={(n) => this._traceDetails = n}>
          { normalizedTrace.order ?
            <span className={`trace-title`}>{normalizedTrace.order}</span>
            : null}
          <span className={`marked-trace`}>
            <span className={`marked-trace-dot`}></span>
          </span>
          <span className={`trace-probability`}>{ normalizedTrace.probability ? `p=${normalizedTrace.probability}` : undefined }</span>
        </div>

        <div
        className={`trace-preview `}
        style={{ width: "100%", height: "100%", paddingTop: `${this._traceDetails ? this._traceDetails.offsetHeight : 0}px` }}
        >
          <div className="trace-preview-contents" style={{ width: "100%", height: "100%" }} ref={(e) => { if (e !== null) { this._goJS = e; }}}/>
        </div>
      </li>
    );
  }
}

export const Component = GoJSComponent;

const mapStateToProps = (state, ownProps) => {
  return {
    ...GoJSComponent.mapStateToProps(state, ownProps),
  };
};

export default connect(mapStateToProps, null, null, { withRef: true })(GoJSSidebarComponent);
