import * as uuid from "uuid";
import { NormalizedLink } from "../link.types";

// Takes compiler link data (t[3]), and object list of nodes by id, and maps the 
// arrays into objects that are compatible with the redux state
// Returns array of link objects (links)
export let mapLinksFromCompiler = (compilerData, nodesByCompileId) => {
  let links: NormalizedLink[] = compilerData[3].map(l => {
    return {
      guid: uuid.v4(),
      type: "IN",
      source: nodesByCompileId[l[1]].guid,
      target: nodesByCompileId[l[0]].guid,
    };
  }) || [];

  links = links.concat(compilerData[4].map(l => {
    return {
      guid: uuid.v4(),
      type: "FOLLOWS",
      source: nodesByCompileId[l[1]].guid,
      target: nodesByCompileId[l[0]].guid,
    };
  }));

  // named links
  let hasNamedLinks = compilerData.length > 5 && Array.isArray(compilerData[5]);
  if (hasNamedLinks) {
    // If views array is the last index, remove it
    const namedLinksArray = compilerData[compilerData.length - 1].VIEWS ? compilerData.slice(5, compilerData.length - 1) : compilerData.slice(5);
    links = links.concat(namedLinksArray.map(namedLink => {
      let name = namedLink[0];
      return namedLink.slice(1).map(l => {
        return {
          guid: uuid.v4(),
          type: "NAMED",
          text: name,
          source: nodesByCompileId[l[0]].guid,
          target: nodesByCompileId[l[1]].guid,
        };
      });
    }).reduce((a, b) => a.concat(b), []));
  }

  return links;
}