import * as React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { loadProject } from "./actions/currentProject";
import { runTour } from "./tour";
import Project from "./page/project";
import DashboardNav from "./page/dashboard/dashboardNav";
import Header from "./page/project/header";

class ProjectByGUID extends React.Component<any, any> {

  state = {
    loadSuccess: false
  }

  componentDidMount() {
    const { dispatch, tourRunning } = this.props;
    this.getProject();
    
    // Start tour
    if (tourRunning) {
      runTour(dispatch);
    }
  }

  async getProject() {
    const uuid = this.props.match.params.projectUUID;
    const { dispatch } = this.props;

    // Get project from server:
    return await axios({
      method: "GET",
      url: `/api/project/uuid?projectUuid=${uuid}`
    }).then( async (res) => {
      let { project, permission, user } = res.data;

      // Set project:
      if (project) {
        dispatch(loadProject({project, permission}));
        this.setState({loadSuccess: true});
        return;
      }

    }).catch((err) => {
      if (err.response.status === 404) {
        alert("Project was not found.");
        window.location.href = process.env.BASE_URL;
        return;
      }
      if (err.response.status === 401) {
        alert("User not authorized to view this project.");
        window.location.href = process.env.BASE_URL;
        return;
      }
      else {
        console.log(err);
        window.location.href = process.env.BASE_URL;
        return;
      }
    });
  }

  render() {
    const { currentProject } = this.props;
    const { loadSuccess } = this.state;
    return (
      <div>
        {this.props.user ? <DashboardNav/> : null}
        <Header/>
        {currentProject && loadSuccess ? <Project/> : null}
      </div>  
    );
   }
}

const mapStateToProps = (state, ownProps) => {
  const { user, currentProject, ui: { tourRunning } } = state;
  return { user, currentProject, tourRunning };
};

export default withRouter(connect(mapStateToProps)(ProjectByGUID));