import * as go from "gojs";
import resizeAdornmentTemplate from "./resizeAdornmentTemplate";

export const barChartTooltip = () => {
  let $ = go.GraphObject.make;
  return $(go.Adornment, "Auto",
    { isShadowed: true, shadowColor: "rgba(0, 0, 0, 0.3)", shadowOffset: new go.Point(3, 3) },
    $(go.Shape, "Rectangle", {stroke: "gray"},
      new go.Binding("fill"),
    ),
    $(go.TextBlock,
      {
        margin: new go.Margin(6, 12),
        font: "normal 10pt sans-serif"
      },
      new go.Binding("text")
    ),
    new go.Binding("visible", "", (data) => {
      return data.showTooltips;
    }),
    // Only show tooltip is height is too small to show text
   // new go.Binding("visible", "height", (h) =>  h < 20 ? true : false),
  );
};

let generateNodeTemplate = () => {
  let $ = go.GraphObject.make;

  let createBarsTemplate = (grayscaleGraphs) => $(go.Panel, "Auto",
    new go.Binding("visible", "height", (h) => !!(h >= 0)),

    $(go.Shape,
      {
        name: "BAR",
        stroke: "black",
        width: 35
      },
      new go.Binding("height"),
      new go.Binding("fill", "", (data) => {
        if (grayscaleGraphs) {
          return data.accessibleFill;
        }
        else return data.fill;
      }),
      new go.Binding("strokeWidth"),
      new go.Binding("margin", "", (m) => {
        if (m.labelMargin) {
          return new go.Margin(0, m.labelMargin, 0, 0);
        }
        else return new go.Margin(0, 0, 0, 0);
      }),
      new go.Binding("visible", "height", (h) => !!(h >= 0)),
    ),
    $(go.TextBlock,
      {
        font: "normal 10pt sans-serif",
        width: 40,
        isMultiline: true,
        maxLines: 3,
        wrap: go.TextBlock.None
      },
      new go.Binding("text", "text", (t) => {
        if (t.toString().length > 3) {
          return t.toString().substring(0, 3) + "..";
        }
        else {
          return t;
        }
      }),
      new go.Binding("column", "col"),
      new go.Binding("textAlign"),
      new go.Binding("stroke", "", (data) => {
        // If using accessibility mode, make text white for the top 2 darkest colors:
        if (grayscaleGraphs && (data.accessibleFill === "#404040" || data.accessibleFill === "#808080")) {
          return "white";
        }
        else return "black";
      }),
      new go.Binding("visible", "height", (h) => {
        // Only show text if bar height is > 30
        return h >= 25;
      }),
    ),
    { toolTip: barChartTooltip() }
  );

  const makeHeadersTemplate = (darkMode, grayscaleGraphs) => $(go.Panel, "Horizontal",
    { margin: new go.Margin(10, 0, 0, 10), alignment: go.Spot.Right },
    new go.Binding("height", "height", (h) => { return h === 0 ? "auto" : Math.floor(h); }),
    $(go.TextBlock,
      {
        font: "normal 10pt sans-serif",
        alignment: go.Spot.Left,
        margin: new go.Margin(0, 5, 0, 0)
      },
      new go.Binding("height", "height", (h) => { return h === 0 ? "auto" : Math.floor(h); }),

      new go.Binding("text", "title"),
      new go.Binding("stroke", "", (data) => darkMode ? "#cccccc" : "black"),
    ),
    $(go.Shape,
      {
        stroke: "black",
        alignment: go.Spot.Right
      },
      new go.Binding("fill", "", (data) => {
        return grayscaleGraphs ? data.accessibleMarkerFill : data.markerFill;
      }),
      new go.Binding("height", "height", (h) => { return h === 0 ? "auto" : Math.floor(h); }),

      new go.Binding("desiredSize", "markerSize", (s) => new go.Size(s, s))
    )
  );

  let createColumnTemplate = (grayscaleGraphs) => 
    $(go.Panel, "Vertical", {alignment: go.Spot.Bottom},
      new go.Binding("itemTemplate", "", (data) => {
        return createBarsTemplate(grayscaleGraphs);
      }),
      new go.Binding("itemArray", "")
    );

  let createBarTitleTemplate = (darkMode) => $(go.Panel, "Auto",
    {margin: new go.Margin(5, 0, 3, 0), angle: -90, alignment: go.Spot.Top  },
    $(go.Shape, { height: 35 },
      new go.Binding("fill", "", () => darkMode ? "#121212" : "white"),
      new go.Binding("stroke", "", () => darkMode ? "#121212" : "white")
    ),
    $(go.TextBlock,
      {
        font: "normal 10pt sans-serif",
        textAlign: "left",
        verticalAlignment: go.Spot.Center,
        height: 35,
      },
      new go.Binding("text"),
      new go.Binding("stroke", "", () => darkMode ? "#cccccc" : "black")
    ),
  );

  let ganttChart = $(go.Panel, "Table",
    { 
      columnSizing: go.RowColumnDefinition.None, 
      margin: new go.Margin(35, 10, 10, 10),
      column: 2
    },

    $(go.Panel, "Horizontal", { row: 1 },

      // Bars
      $(go.Panel, {row: 0},

        $(go.Panel, "Horizontal",
          {row: 1, stretch: go.GraphObject.Horizontal },
          new go.Binding("itemTemplate", "", (data) => {
            return createColumnTemplate(data.grayscaleGraphs);
          }),
          new go.Binding("itemArray"),
        )
         )    
      ),

    // Titles below bars
    $(go.Panel, "Horizontal", 
      {
        row: 2, 
        alignment: go.Spot.Center,
      },
      new go.Binding("itemTemplate", "", (data) => createBarTitleTemplate(data.darkMode)),
      new go.Binding("itemArray", "titleArray")
    ),
 
  );

  let barGraphTemplate =
    $(go.Node, go.Panel.Viewbox,
      {
        resizable: true,
        resizeAdornmentTemplate,
        cursor: "pointer",
      },
      new go.Binding("position").makeTwoWay(),
      $(go.Panel, "Vertical",
        $(go.TextBlock,
          {
            textAlign: "center",
            font: "bold 18px arial, sans-serif",
            verticalAlignment: go.Spot.Top,
            width: 250,
            isMultiline: true,
            wrap: go.TextBlock.WrapDesiredSize,
          },
          new go.Binding("stroke", "", (data) => data.darkMode ? "#cccccc" : "black"),
          new go.Binding("text", "name"),
        ),
        $(go.Panel, "Auto", 
          $(go.Shape, "RoundedRectangle", { cursor: "pointer", name: "BARGRAPH_CONTAINER" },
            new go.Binding("stroke", "", (data) => data.darkMode ? null : "gray"),
            new go.Binding("fill", "", (data) => data.darkMode ? "#121212" : "white"),
          ),
          $(go.Panel, "Table",
     
            // Headers with color legends
            $(go.Panel, "Vertical",
              {
                column: 1,
                row: 0,
                margin: new go.Margin(0, 0, 10, 0),
              },
              new go.Binding("itemTemplate", "", (data) => {
                return makeHeadersTemplate(data.darkMode, data.grayscaleGraphs);
              }),
              new go.Binding("itemArray", "headers"),
            ),
            ganttChart
          )        
        )
      )
  );

  return barGraphTemplate;

};

export let nodeBarChartTemplate = () => generateNodeTemplate();
