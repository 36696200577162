import * as React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import CodeEditor from "./codeEditor";
import Graph from "./graph";
import Navigation from "./navigation";

import { updateSplitSizes, toggleExpandNavigation } from "../../actions/ui";
import { saveProjectSnapshot } from "../../actions/currentProject";

export let MIN_MIDDLE_WIDTH = 20;

export class Project extends React.Component<any, any> {

  defaultLeft;
  defaultMiddle;
  defaultRight;
  headerHeight = 0;
  userDashNavHeight = 0;

  state = {
    left: 30,
    middle: 50,
    right: 20,
    displayPlaceholder: false,
    hitMinWidth: false,
  };

  async componentDidMount() {
    const { splitSizes, user, currentProject, match } = this.props;
    const browserWidth = window.innerWidth;

    // Navigation/rightmost div should default at 187px, so we get other div percentages based on that
    this.defaultRight = (187 / browserWidth) * 100;
    const remainingPercentage = 100 - this.defaultRight;
    this.defaultLeft = remainingPercentage * .422;
    this.defaultMiddle = remainingPercentage - this.defaultLeft;
    // Get height of #dashboard-nav to help reposition elements below it
    if (user) {
      this.userDashNavHeight = document.getElementById("dashboard-nav").clientHeight;
    }
    
    this.headerHeight = document.getElementById("header").clientHeight;

    // If splitSizes is not an empty object (sizes are saved there), then set the div widths to those saved widths
    if (!isEmpty(splitSizes)) {
      this.setState({...splitSizes});
    }
    // If none is saved, set default widths
    else {
      this.setState({left: this.defaultLeft, middle: this.defaultMiddle, right: this.defaultRight});
    }

    // Make react tooltips work for this component
    setTimeout(ReactTooltip.rebuild, 0);
  }

  // Is called while the user is dragging the resize bar
  handleResize = (div, width) => {
    const { left, right } = this.state;
    const { navigationExpanded } = this.props;
    const newMiddle = div === "left" ? 100 - width - right : 100 - width - left;
    // const browserWidth = window.innerWidth;
    // const middleWidthInPx = browserWidth * (newMiddle / 100);

    if (!this.state.hitMinWidth && newMiddle <= MIN_MIDDLE_WIDTH) {
      this.setState({hitMinWidth: true});
    }

    // Allow resizing only if the middle div is greater than 20%
    if (newMiddle > MIN_MIDDLE_WIDTH) {

      if (this.state.hitMinWidth) {
        this.setState({hitMinWidth: false});
      }

      if (div === "left") {
        this.setState({left: width, middle: 100 - width - right});
      }
      // If we're adjusting the right navbar and it's expanded:
      if (div === "right" && navigationExpanded) {
        this.setState({right: width, middle: 100 - left - width});
        // If the user drags it less than 5%, display sidebar placeholder and expand tab
        if (width <= 5) {
          this.setState({displayPlaceholder: true});
        }
      }
    }
  }

  // Is called when the user stops dragging the resize bar
  handleResizeStop = () => {
    const { left, middle, right } = this.state;
    // If the user let go of nav dragbar while it was in its placeholder state, minimize it
    if (right <= 5) {
      const newMiddle = 100 - left;
      this.setState({right: 0, middle: newMiddle});
      // If navigation is expanded, close it
      if (this.props.navigationExpanded) {
        this.props.dispatch(toggleExpandNavigation({}));
      }
      this.props.dispatch(updateSplitSizes({splitSizes: {left, middle: newMiddle, right: 0}}));
    }
    else {
      this.props.dispatch(updateSplitSizes({splitSizes: {left, middle, right}}));
    }
  }

  handleExpandNavigation() {
    const { left, middle } = this.state;
    // Set navigation back to original width at 187px
    let newMiddle = 100 - left - this.defaultRight;
    let newLeft = left;

    // If navigation expands causing the middle to be less than MIN_MIDDLE_WIDTH
    // subtract the difference from the left panel
    if (newMiddle <= MIN_MIDDLE_WIDTH) {
      let offset = MIN_MIDDLE_WIDTH - newMiddle;
      newLeft = newLeft - offset;
    }

    this.props.dispatch(toggleExpandNavigation({}));
    this.setState({left: newLeft, right: this.defaultRight, displayPlaceholder: false});
    // Save to global state on expand:
    this.props.dispatch(updateSplitSizes({splitSizes: {left: newLeft, right: this.defaultRight}}));
  }

  componentDidUpdate(prevProps, prevState) {
    // If nav was dragged out of its placeholder state
    if (prevState.right <= 5 && this.state.right > 5) {
      this.setState({displayPlaceholder: false});
    }
  }

  componentWillUnmount() {
    const { currentProject, dispatch } = this.props;
    // If working on a project, save snapshot of selected graph before leaving
    if (currentProject !== null && currentProject.permission === "owner") {
      dispatch(saveProjectSnapshot());
    }
  }

  render() {
    const { navigationExpanded, viewMode, showTooltips } = this.props;
    const { displayPlaceholder } = this.state;
    const offsetHeight = this.headerHeight + this.userDashNavHeight;

    return (
      <div className="page home">
        <div className="montereyPhoenixAnalyzer show-split-view-fullscreen">

          <CodeEditor
            widthPercentage={this.state.left}
            onResize={this.handleResize}
            onResizeStop={this.handleResizeStop}
            offsetHeight={offsetHeight}
          />

          <Graph
            widthPercentage={this.state.middle} 
            leftWidthPercentage={this.state.left} 
            rightWidthPercentage={this.state.right}
            offsetHeight={offsetHeight}
          />

          <Navigation
            widthPercentage={this.state.right}
            hitMinWidth={this.state.hitMinWidth}
            onResize={this.handleResize}
            onResizeStop={this.handleResizeStop}
            displayPlaceholder={displayPlaceholder}
            offsetHeight={offsetHeight}
          />
          
          {(displayPlaceholder || !navigationExpanded) && viewMode !== "CODE" ?
            <div
              className="expand-navigation-tab" 
              style={{
                right: navigationExpanded ? `calc(${this.state.right}% - 5px)` : "-7px",
                opacity: navigationExpanded ? .5 : 1,
              }}
              onClick={() => this.handleExpandNavigation()}>
              Expand Navigation
              <span className="navigation-trace-prev"></span>
              </div>
          : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { 
    ui: { splitSizes, navigationExpanded, viewMode, showTooltips },
    user,
    currentProject
  } = state;
  return { 
    splitSizes, 
    navigationExpanded, 
    viewMode, 
    user,
    currentProject,
    showTooltips
  };
};

export default withRouter(connect(mapStateToProps)(Project));
