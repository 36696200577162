import axios from "axios";

import { SET_PROJECTS, UPDATE_PROJECTS } from "./projects.types";
import { resetCurrentProject } from "./currentProject";
import { saveStateToLocalStorage } from "../store";

// Retrieves a list of all user's projects from the database
export let getAllProjects = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { projects, currentProject } = state;

    await axios({
      method: "GET",
      url: `/api/project/all/`
    }).then((response) => {
      let projectObj = {};


      // Convert to object that contains project data and permission name
      response.data.projects.forEach(obj => {
        projectObj[obj.id] = obj;
        projectObj[obj.id].permission = obj.permission;
      });


      // Check to see if the current project was removed
      if (currentProject !== null && !projectObj[currentProject.id]) {
        // If it was, reset the current project
        dispatch(resetCurrentProject());
      }

      // If it's the first time getting projects:
      if (projects == null) {
        dispatch({ type: SET_PROJECTS, projects: projectObj });
      }
      // All other times:
      else {
        dispatch({ type: UPDATE_PROJECTS, projects: projectObj });
      }

      saveStateToLocalStorage(state);

    }).catch((error) => {
      console.log("error", error);
      alert("There was an error getting projects.");
    });

  };
};

export let resetProjects = () => {
  return async (dispatch, getState) => {

    const state = getState();
    const { projects } = state;
    let projectObj = {};

    await dispatch({ type: SET_PROJECTS, projects: projectObj });

  };
};