import { TraceLayout } from "../../../../../actions/trace.types"
import { generateLayoutSequenceTemplate } from "./sequence";
import { generateLayoutHorizontalTemplate } from "./horizontal";
import { generateLayoutSysmlTemplate } from "./sysml";

const defaultParams = { traceLayout: "SEQUENCE" };
interface Params { traceLayout: TraceLayout };

export let generateLayoutTemplate = ({ traceLayout }: Params = defaultParams) => {
  let layout = null;
  switch (traceLayout) {
    case "SEQUENCE": { layout = generateLayoutSequenceTemplate(); break; }
    case "HORIZONTAL": { layout = generateLayoutHorizontalTemplate(); break; }
    case "SYSML": { layout = generateLayoutSysmlTemplate(); break; }
  }
  return layout;
};
