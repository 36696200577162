import * as React from "react";
import {toggleDialog} from "../../../actions/dialog";
import {connect} from "react-redux";

export class CreateProjectButton extends React.Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      show: false
    };
  }

  render() {
    const { dispatch, user } = this.props;

    return (
        <div className="page-options">
          <button className="create_project_button"  onClick={(e) => dispatch(toggleDialog({name: "dialogCreateProject", props: {user: user, target: e.target}}))}>
           Create new project
          </button>
        </div>
    );
  }
}


let mapStateToProps = (state, ownProps) => {
  const { projects, user } = state;
  return {
    projects,
    user
  };
};

export default connect(mapStateToProps)(CreateProjectButton);
