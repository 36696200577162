import { merge } from "lodash";

import * as CODE from "../../actions/code.types";
import * as IMPORT from "../../actions/import.types";

export let reducer = (state = { source: "", title: "MontereyPhoenixSchema" }, action) => {
  switch (action.type) {
    case CODE.CODE_UPDATE_SOURCE: {
      let nextState = merge({}, state);
      nextState.source = action.source;
      return nextState;
    }
    case IMPORT.IMPORT_MP: {
      let nextState = merge({}, state);
      nextState.source = action.source;
      nextState.title = action.title;
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
