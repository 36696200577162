import { merge, find } from "lodash";

import * as SORT from "./sort.types";
import { saveStateToLocalStorage } from "../store";
import { sortTraces } from "./helpers/sort";
import { setPages } from "./pagination";

export let updateSortFiter = (options: {
  filter: string,
  choice: number | boolean
}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, options, {
      type: SORT.SORT_UPDATE_SORT_FILTERS,
    }));
    saveStateToLocalStorage(getState());
    await dispatch(updateSortedTraces());
  };
};

export let updateSortedTraces = () => {
  return async (dispatch, getState) => {

    await dispatch(merge({}, {
      type: SORT.BEGIN_SORTING
    }));

    const state = getState();
    const { entities: { traces }, sort, pagination: { pages } } = state;

    let newSortedTraces = null;

    // Only sort if a filter is set
    if (find(sort, "current")) {
      newSortedTraces = await sortTraces(traces, sort);
    }

    // Send sorted trace guids to pagination
    await dispatch(setPages({traceGuids: newSortedTraces ? newSortedTraces : Object.keys(traces)}));

    await dispatch(merge({}, {
      type: SORT.SORT_UPDATE_SORTED_TRACES,
    }));

    saveStateToLocalStorage(getState());
  };
};

export let clearSortFilters = () => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: SORT.CLEAR_SORT_FILTERS,
    }));
  };
};