import * as React from "react";
import { connect } from "react-redux";

import { changeCurrentPage } from "../../../actions/pagination";

export class Pagination extends React.Component<any, any> {

  handleChangePage = (e, type) => {
    const { currentPage, totalPages, dispatch, hidingGraph } = this.props;

    // Make sure next page is in range
    if (type === "prev" && (currentPage - 1 > 0) && !hidingGraph) {
      dispatch(changeCurrentPage({nextPage: currentPage - 1}));
    }
    if (type === "next" && (currentPage + 1 <= totalPages) && !hidingGraph) {
      dispatch(changeCurrentPage({nextPage: currentPage + 1}));
    }
    else {
      e.preventDefault();
      return;
    }
  }

  render() {
    let {
      dispatch,
      hidingGraph,
      currentPage,
      totalPages
    } = this.props;

    return (
      <div style={{alignItems: "center"}} className="pagination">
        <div className="prev-next-container">
          <i
            data-place="bottom"
            data-tip="previous page"
            className={`icon fas fa-chevron-left prev-next-icon ${hidingGraph || currentPage === 1 ? "disabled" : ""}`}
            onClick={(e) => {
              this.handleChangePage(e, "prev");
            }}/>
          <i
            data-place="bottom"
            data-tip="next page"
            className={`icon fas fa-chevron-right prev-next-icon ${hidingGraph || currentPage === totalPages ? "disabled" : ""}`}
            onClick={(e) => {
              this.handleChangePage(e, "next");
            }}/>
        </div>

        Page <input
              className="navigation-input"
              value={hidingGraph ? 0 : currentPage}
              onChange={(e) => {
                const nextPage = parseInt(e.target.value);
                if (nextPage > 0 && nextPage <= totalPages) {
                  dispatch(changeCurrentPage({nextPage: parseInt(e.target.value)}));
                }
              }}
              disabled={hidingGraph}
            /> of {hidingGraph ? 0 : totalPages}
      </div>
    );
  }
};


let mapStateToProps = (state, ownProps) => {
  const {
    graph: { hidingGraph },
    pagination: { currentPage, totalPages }
  } = state;
  return {
    hidingGraph,
    currentPage,
    totalPages,
  };
};

export default connect(mapStateToProps)(Pagination);
