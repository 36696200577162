import { createStore, applyMiddleware, compose } from "redux";
let thunk = require("redux-thunk").default;
import promiseMiddleware from "redux-promise";
import socketMiddleware from "../socket/middleware";

import rootReducer from "../reducers";

declare let window: any;

let configureStore = (initialState) => {
  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk, promiseMiddleware, socketMiddleware),
      typeof window === "object" && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined" ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    ) as () => any
  );
};

export default configureStore;
