import * as go from "gojs";

export const COLOR_FOLDED_CORNER = "#A9A9AA";
export const COLOR_FILL = "#FDF5C5";

export const NODE_WIDTH = 250;
export const STROKE_WIDTH = 1.5;

let generateNodeTemplate = (options: { cornerSize }) => {
  let $ = go.GraphObject.make;
  let { cornerSize } = options;
  return $(go.Node, go.Panel.Auto, {
      width: NODE_WIDTH,
    },
    new go.Binding("position", "position").makeTwoWay(),
    $(go.Shape, "TextBox", {
      parameter1: 2.5,
      parameter2: cornerSize,
      fill: COLOR_FILL,
      stroke: COLOR_FOLDED_CORNER,
      strokeWidth: STROKE_WIDTH,
      cursor: "move",
    }),
    $(go.TextBlock, {
      font: "24px arial",
      margin: 2,
      stroke: "black",
      cursor: "move",
      textAlign: "center",
      width: 200,
    }, new go.Binding("text", "name")),
    // filled folded corner
    $(go.Shape, {
      alignment: go.Spot.TopRight,
      geometryString: `F M0 0 L${cornerSize} ${cornerSize} 0 ${cornerSize}z`,
      fill: COLOR_FOLDED_CORNER,
      stroke: COLOR_FOLDED_CORNER,
      strokeWidth: STROKE_WIDTH,
    }),
  );
};


export let nodeSayTemplate = options => generateNodeTemplate({ cornerSize: 20, ...options });
