import * as React from "react";
import {toggleDialog} from "../../../actions/dialog";
import {connect} from "react-redux";

export class ImportButton extends React.Component<any, any> {

  constructor(props) {
    super(props);
    // TODO: load userId into state.
    this.state = {
      userId: 1,
      show: false
    };
  }

  render() {
    const { dispatch } = this.props;
    return (
        <div className="page-options import-button">
          <button 
          className="create_project_button"  
          onClick={(e) => dispatch(toggleDialog({
            name: "dialogDashboardImport", props: {target: e.target}
          }))}>
           Import project
          </button>
        </div>
    );
  }
}


let mapStateToProps = (state, ownProps) => {
  return {

  };
};

export default connect()(ImportButton);
