import { merge } from "lodash";
import * as CP from "../../actions/compiler.types";
import * as G from "../../actions/graph.types";

interface Error {
  line: number | null,
  col: number | null,
  message: string,
}

interface Compiler {
  aborted: boolean,
  parsing: boolean,
  compiling: boolean,
  graphing: boolean,
  graphedTraceCount: number,
  totalTraceCount: number,
  completed: boolean,
  output: string,
  status: string,
  compileError: Error,
  parseError: Error,
  scope: number
}

let defaultError = {
  line: null,
  col: null,
  message: "",
};

let defaultState = {
  aborted: false,
  parsing: false,
  compiling: false,
  graphing: false,
  graphedTraceCount: 0,
  totalTraceCount: 0,
  completed: false,
  output: "",
  status: "",
  compileError: defaultError,
  parseError: defaultError,
  scope: 1
};

let stop = {
  aborted: true,
  parsing: false,
  compiling: false,
  graphing: false,
  completed: false,
  message: "message",
  status: "status"
};

export let reducer = (state: Compiler = defaultState, action) => {
  switch (action.type) {
    case CP.CP_UPDATE_SCOPE: {
      let nextState = merge({}, state);
      nextState.scope = action.scope;
      return nextState;
    }
    case CP.CP_PARSE_BEGIN: {
      let nextState = merge({}, state);
      nextState.parsing = true;
      return nextState;
    }
    case CP.CP_PARSE_ERROR: {
      let nextState = merge({}, state);
      nextState.parsing = false;
      nextState.parseError = merge({}, action.error);
      return nextState;
    }
    case CP.CP_PARSE_END: {
      let nextState = merge({}, state);
      nextState.parsing = false;
      nextState.parseError = merge({}, defaultError);
      return nextState;
    }
    case CP.CP_COMPILE_RESET: {
      let nextState = merge({}, state, defaultState);
      // Reset the scope back to 1
      nextState.scope = 1;
      return nextState;
    }
    case CP.CP_COMPILE_BEGIN: {
      let nextState = merge({}, defaultState);
      nextState.scope = state.scope;
      nextState.compiling = true;
      nextState.compileError = merge({}, defaultError);
      return nextState;
    }
    case CP.CP_COMPILE_OUTPUT: {
      let nextState = merge({}, state);
      nextState.output += action.output;
      return nextState;
    }
    case CP.CP_COMPILE_ERROR: {
      let nextState = merge({}, state);
      nextState.compiling = false;
      nextState.compileError = merge({}, action.error);
      nextState.parseError = merge({}, action.error);
      return nextState;
    }
    case CP.CP_COMPILE_END: {
      let nextState = merge({}, state);
      nextState.compiling = false;
      nextState.graphing = true;
      nextState.completed = false;
      nextState.compileError = merge({}, defaultError);
      nextState.parseError = merge({}, defaultError);
      nextState.totalTraceCount = action.totalTraceCount;
      return nextState;
    }
    case CP.CP_COMPILE_GRAPH_STOP: {
      let nextState = merge({}, state, stop);
      return nextState;
    }
    case G.GRAPH_ALL_TRACES_ADDED: {
      let nextState = merge({}, state);
      nextState.graphing = false;
      nextState.completed = true;
      return nextState;
    }
    case G.GRAPH_ADD_TRACE: {
      let nextState = merge({}, state);
      nextState.graphedTraceCount = nextState.graphedTraceCount + 1;
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
