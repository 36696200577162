import { DataType } from "sequelize-typescript";
import { SET_PROJECTS, UPDATE_PROJECTS } from "../../actions/projects.types";

interface Projects { [id: string]: {
    code: string,
    createdAt: string,
    description: string,
    id: number
    name: string
    public: false
    updatedAt: string
    user: {username: string}
    userId: number
    uuid: DataType.UUID,
    permission: "collaborator" | "viewer" | "owner"
  }
}

export let reducer = (state: Projects | null = null, action) => {
  switch (action.type) {
    case SET_PROJECTS: {
      return action.projects;
    }
    case UPDATE_PROJECTS: {
      let nextState = Object.assign({}, state);
      nextState = action.projects;
      return nextState;
    } 
    default:
      return state;
  }
};

export default reducer;
