import * as React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { merge } from "lodash";

import Autocomplete from "react-autocomplete";
import MaterialIcon from "material-icons-react";
import { withSnackbar } from "react-simple-snackbar";

export let name = "dialogShareProject";

let NPS_EMAIL_ORG = "nps.edu";

export class ShareProject extends React.Component<any, any> {

  constructor(props) {
    super(props);
    const { project } = this.props;

    this.state = {
      email: "",
      user: this.props.user,
      selectedPermission: "Viewer",
      project: project,
      permissions: {},
      updatedPermissions: {},
      error: null,
      inputValue: "",
      searchResult: [],
      selectedUser: null,
      emailInProgress: false
    };

    this.loadPermissions = this.loadPermissions.bind(this);
    this.addPermission = this.addPermission.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectPermission = this.handleSelectPermission.bind(this);
    this.permissionChanged = this.permissionChanged.bind(this);
    this.handlePermissionUpdate = this.handlePermissionUpdate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLinkPermissionChange = this.handleLinkPermissionChange.bind(this);
    this.copyLink = this.copyLink.bind(this);
    this.sendEmailInvite = this.sendEmailInvite.bind(this);
  }

  async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): Promise<void> {
    if (this.props.project !== prevProps.project) {
      const { project } = this.props;
      await this.loadPermissions(project);
      this.setState({ project });
    }
  }

  handleSelectPermission(event) {
    this.setState({selectedPermission: event.target.value});
  }

  // Change permission from dropdown
  permissionChanged(id, permissionType) {
    const { updatedPermissions } = this.state;
    const newUpdatedPermissions = merge({}, updatedPermissions);
    newUpdatedPermissions[id].permissionType.name = permissionType;
    this.setState({ updatedPermissions: newUpdatedPermissions });
  }

  async loadPermissions(project) {
    if (project) {
      axios({
        method: `GET`,
        url: `/api/permission/?projectId=${project.id}`
      }).then((response) => {

        if (response.data.permissions.length >= 0) {
          let permissions = {};

          // Create object of permissions
          response.data.permissions.forEach((p, i) => {
            permissions[p.id] = p;
          });

          this.setState({ updatedPermissions: permissions, permissions });

        } else {
          return null;
        }
      }).catch((error) => {
        console.log("error", error.message);
      });
    } else {
      return null;
    }
  }

  async addPermission() {
    const { selectedUser, selectedPermission, project, user } = this.state;
    const permission = selectedPermission.toLowerCase();

    axios({
      method: `POST`,
      url: `/api/permission/create`,
      data: {
        permissionType: permission,
        projectId: project.id,
        email: selectedUser,
        userId: user.id
      }
    }).then((response) => {
      this.setState({ selectedPermission: "Viewer", selectedUser: null, inputValue: ""});
      this.loadPermissions(project);
    }).catch((error) => {
      // Show error message
      const { message } = error.response.data;
      this.setState({error: message});
    });
  }

  // Updates permissions in database
  async handlePermissionUpdate(e) {
    e.preventDefault();
    const updateRequests = [];
    const { permissions, updatedPermissions, project } = this.state;

    // Find updated permissions and create a request to update
    await Object.keys(updatedPermissions).map(async (id, i) => {
      const newPermission = updatedPermissions[id];

      if (newPermission !== permissions[i]) {

        // Create delete request for permission
        if (newPermission.permissionType.name === "remove") {
          const { user } = this.state;
          updateRequests.push(axios({
            method: `POST`,
            url: `/api/permission/delete`,
            data: {
              projectId: newPermission.projectId,
              userId: user.id,
              permissionUserId: newPermission.user.id,
              permissionId: id
            }
          }));
        } else {
          const { user } = this.state;
          updateRequests.push(axios({
            method: `POST`,
            url: `/api/permission/update`,
            data: {
              newPermissionType: newPermission.permissionType.name,
              projectId: newPermission.projectId,
              userId: user.id,
              permissionUserId: newPermission.user.id,
              permissionId: id
            }
          }));
        }
      } else {
        return null;
      }
    });

    // Execute all permission update requests
    axios.all(updateRequests).then(async (response) => {
      await this.loadPermissions(project);
    });
  }

  renderPermissions() {
    const { permissions, updatedPermissions } = this.state;
    const { user } = this.props;

    const permissionIds = Object.keys(permissions);
    const numOwners = Object.keys(permissions).filter(pId => permissions[pId].permissionType.id === 1).length;

    if (permissionIds.length > 0) {
      return (
        <div className="permission-container">
          <div className="permission-list">
            <h4>Project members:</h4>
            {permissionIds.map((id, i) => { 
              const permission = permissions[id];
              const updatedPermission = updatedPermissions[id];

              // If this is the last owner, prevent deleting/changing role
              const isLastOwner = numOwners === 1 && updatedPermission.permissionType.id === 1;

              return (
                <div key={i} className={`permission ${isLastOwner ? "disabled" : ""}`}>
                  <div className={`${permission.user.id === user.id ? "current-user" : ""}`}>
                    {permission.user.username}
                  </div>
                  <div className="permission-select">
                    <select 
                      value={updatedPermission.permissionType.name} 
                      onChange={async (e) => {
                        if (isLastOwner) {
                          e.preventDefault();
                          return;
                        }
                        // Only allow changing the permission if this isn't the last owner
                        this.permissionChanged(id, e.target.value)
                      }}
                      name="permissionType">
                      <option value="viewer">Viewer</option>
                      <option value="collaborator">Collaborator</option>
                      <option value="owner">Owner</option>
                      <option value="remove">Remove</option>
                    </select>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="update-btn-container">
            {permissions === updatedPermissions ? null : 
            <span 
              className="create_project_button add-permission-button" 
              onClick={(e) => this.handlePermissionUpdate(e)}>
                Update
            </span>}
          </div>
        </div>
      );
    }
    return null;
  }

  handleInputChange(e) {
    e.preventDefault();
    const { project } = this.state;
    const { value } = e.target;
    this.setState({ inputValue: value, error: null });

    if (value === "") {
      // clear results on empty string
      this.setState({ searchResult: []});
    } else {
      axios({
        method: `GET`,
        url: `/api/user/search?searchTerm=${value}&projectId=${project.id}`
      }).then((response) => {
        const { users } = response.data;
        this.setState({ searchResult: users, selectedUser: null });
      }).catch((error) => {
        console.log("error", error.message);
      });
    }
  }

  async handleSelect(user) {
    await this.setState({inputValue: user, selectedUser: user, searchResult: []});
  }

  async handleLinkPermissionChange(e) {
    e.preventDefault();
    const { project } = this.state;
    const { user } = this.props;

    await axios({
      method: `POST`,
      url: `/api/project/update/link`,
      data: {
        userId: user.id,
        projectId: project.id,
        publicSetting: !project.public
      }
    }).then(async (response) => {
      const { project } = response.data;
      await this.setState({ project });
    });
  }

  async copyLink(e) {
    e.preventDefault();
    const { project } = this.state;
    const { openSnackbar } = this.props;
    const textToCopy = `${window.origin}/project/${project.uuid}`;
    await navigator.clipboard.writeText(textToCopy);
    openSnackbar(`Link copied!`, 3000);
  }

  async sendEmailInvite() {
    const { inputValue, project, user } = this.state;
    const { openSnackbar } = this.props;
    const arr = inputValue.split("@");
    const emailOrg = arr[arr.length - 1];

    if (emailOrg !== NPS_EMAIL_ORG) {
      await this.setState({
        error: `Only NPS users can be added as a project member. To allow non-NPS users to view the project, set the project to "public" (below) and copy the link to share.`
      });
      return;
    }

    await this.setState({ emailInProgress: true});
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(inputValue)) {
      await this.setState({ error: `Please enter a valid email.`, emailInProgress: false })
    }

    await axios({
      method: "POST",
      url: `/api/email/invite`,
      data: {
        email: inputValue,
        projectId: project.id,
        userId: user.id
      }
    }).then(async (response) => {
      openSnackbar(`Email invite sent to ${inputValue}.`, 3000);
      await this.setState({ inputValue: "", emailInProgress: false });
    }).catch(async (error) => {
      console.log(error);
      await this.setState({ error: `Something went wrong. Please try again.`, emailInProgress: false });
    });
  }

  render() {
    let { error, inputValue, searchResult, selectedUser, project, emailInProgress } = this.state;

    return (
      <div>
        <h2>Share with people</h2>
        <div className="permissions">
          {
            selectedUser ?
              <div className="selected-user-container">
                <div className="user-card">
                  <div className="selected-username">
                    <span>{ selectedUser }</span>
                    <span className="permission-select">
                      <select value={this.state.selectedPermission} onChange={this.handleSelectPermission} name="permissionType" >
                        <option value="viewer">Viewer</option>
                        <option value="collaborator">Collaborator</option>
                        <option value="owner">Owner</option>
                      </select>
                    </span>
                  </div>
                  <div className="clear-user-button">
                    <MaterialIcon onClick={(e) => { this.setState({ selectedUser: null, inputValue: ""}); }} icon="clear"></MaterialIcon>
                  </div>
                </div>
                <div className="add-user-btn-container">
                  <button className="create_project_button add-permission-button" onClick={this.addPermission}>Add</button>
                </div>
              </div>
              :
              <div>
                <div>Enter the users email to share</div>
                <div className="permission-options">
                  <Autocomplete
                    disabled={emailInProgress}
                    className="auto-complete-input"
                    getItemValue={(item) => item.username}
                    items={searchResult}
                    renderItem={(item, isHighlighted) =>
                      <div className="user-list-item" key={item.id} style={{ background: isHighlighted ? "lightgray" : "white" }}>
                        {item.username}
                      </div>
                    }
                    value={inputValue}
                    onChange={this.handleInputChange}
                    onSelect={this.handleSelect}
                  />
                  <button disabled={emailInProgress} className="create_project_button add-permission-button"
                          onClick={() => selectedUser ? this.addPermission() : this.sendEmailInvite()}>
                    Invite
                  </button>
                </div>

                {emailInProgress ? 
                  <div className="loading">
                    Sending Email <span className="icon"/>
                  </div> : null 
                }

              </div>

          }
          {error ? (<div className="error">{error}</div>) : null}
          <div>
            { this.renderPermissions() }
          </div>
          <div>
            <h2>Get a shareable link</h2>
            <div className="copy-link-container">
              {
                project && project.public ?
                  <span className="copy-link-text">
                    <span className="copy-link-setting"><span className="copy-link-descriptor">Public:</span> Anyone with a link can view</span>
                    <a className="change-link-type" onClick={this.handleLinkPermissionChange}>Restrict access to added users</a>
                  </span>
                  :
                  <span className="copy-link-text">
                    <span className="copy-link-setting">
                      <span className="copy-link-descriptor">Restricted:</span> Only added users can open the project with this link.</span>
                    <a className="change-link-type" onClick={this.handleLinkPermissionChange}>Change to anyone with a link can view</a>
                  </span>
              }
              <span>
                <button className="create_project_button add-permission-button" onClick={this.copyLink}>Copy link</button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const options = {
  position: "top-center",
  style: {
    fontFamily: "Roboto, sans-serif",
    textAlign: "center",
    zIndex: "10000",
    backgroundColor: "#333",
  }
};

let mapStateToProps = (state, ownProps) => {
  const { projects, user } = state;
  return {
    projects,
    user
  };
};

export default withSnackbar(connect(mapStateToProps)(ShareProject), options);
